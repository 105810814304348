import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import * as XLSX from "xlsx";
import axios from "axios";
import { ExportExcel } from "../../helper/ExportDataToExcel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import "filepond/dist/filepond.min.css";
import "../../style/modal.css";

registerPlugin(FilePondPluginFileEncode);

export default class UploadStatusFile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            files: [],
            fileData: [],
            canSubmit: false,
        };
    }
    handleFilePond = (err, file) => {
        if (err) {
            console.log(err);
        }
        console.log(file);
        const wb = XLSX.read(file.getFileEncodeBase64String(), { type: "base64" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        let check = this.fileDataCheck(data);
        if (check === "") {
            this.setState({ fileData: data });
            axios
                .post(
                    `${process.env.REACT_APP_ADMIN_URL}/conversions/status-upload/data/check`,
                    {
                        conversions: data,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "JWT " + localStorage.getItem("token_admin"),
                        },
                    }
                )
                .then((response) => {
                    let data = response.data;
                    alert(data.message);
                    if (data.status === "ok") {
                        this.setState({ canSubmit: true });
                    } else {
                        this.setState({ files: [], fileData: [], canSubmit: false });
                    }
                })
                .catch((error) => {
                    alert("error!");
                    this.setState({ invalidMsg: error.response.data.message });
                    //this.props.history.push("/error?message=" + error.message);
                });
        } else {
            alert(check);
            this.setState({ files: [], fileData: [], canSubmit: false });
        }
    };
    fileDataCheck = (data) => {
        var checkMessage = "";
        if (data.length === 0) {
            checkMessage = "File is Empty!";
        }
        data.forEach((v) => {
            let objKeys = Object.keys(v);
            if (objKeys.indexOf("order_id") === -1 || objKeys.indexOf("status") === -1) {
                checkMessage = "All Conversions must have order id and status!";
            }
            if (v.order_id === "" || v.status === "") {
                checkMessage = "Some conversions have empty order id or status!";
            }
        });
        return checkMessage;
    };
    handleSubmit = () => {
        alert("submitting");
        axios
            .post(
                `${process.env.REACT_APP_ADMIN_URL}/conversions/status-upload/data/`,
                {
                    conversions: this.state.fileData,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "JWT " + localStorage.getItem("token_admin"),
                    },
                }
            )
            .then((response) => {
                alert(response.data.message);
            })
            .catch((error) => {
                alert("error!");
                this.setState({ invalidMsg: error.response.data.message });
                //this.props.history.push("/error?message=" + error.message);
            });
    };
    handleModalClose = () => {
        this.setState({ files: [], fileData: [], canSubmit: false });
        this.props.handleModalClose();
    };
    render() {
        return (
            <Modal
                show={this.props.showModal}
                onHide={this.handleModalClose}
                className={`${localStorage.getItem("theme") === "light" ? "light-theme" : "dark-theme"} myModal`}
                centered
            >
                <Modal.Header closeButton>
                    <h2 className="menu-h2-title modal-title">Update Conversion Status File Upload</h2>
                </Modal.Header>
                <Modal.Body>
                    <ul className="table-ul ml-1 mb-2 medium-text">
                        <li>Each conversion must have 3 properties: order_id, status, and sale_message.</li>
                        <li>Rejected conversion must have reject reasons.</li>
                        <li>Combo_alias must match the combo_alias of the offer.</li>
                        <li>You can only upload XLSX (Excel) file.</li>
                        <li>Please consult the example excel file before attempting to upload your file.</li>
                    </ul>
                    <div className="mb-2">
                        Download Example Files:{" "}
                        <FontAwesomeIcon
                            icon={faFileDownload}
                            size="lg"
                            className="hover-primary"
                            onClick={() =>
                                ExportExcel(
                                    [
                                        {
                                            order_id: "",
                                            status: "",
                                            combo_alias: "",
                                            sale_message: "",
                                            reject_reason: "",
                                        },
                                    ],
                                    "example"
                                )
                            }
                        />
                    </div>
                    <FilePond
                        ref={(ref) => (this.pond = ref)}
                        files={this.state.files}
                        onaddfile={this.handleFilePond}
                        beforeAddFile={(file) => {
                            if (file.fileExtension.includes("xlsx")) {
                                return true;
                            } else {
                                alert("You can only upload Excel Files!!");
                                return false;
                            }
                        }}
                        allowMultiple={false}
                        allowFileEncode={true}
                        oninit={() => console.log("FilePond instance has initialised", this.pond)}
                        onremovefile={(err, file) => {
                            this.setState({ files: [], fileData: [], canSubmit: false });
                        }}
                        onupdatefiles={(fileItems) => {
                            // Set currently active file objects to this.state
                            this.setState({
                                files: fileItems,
                            });
                        }}
                    ></FilePond>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn button-devas-gray" onClick={this.handleModalClose}>
                        Cancel
                    </button>
                    {this.state.canSubmit ? (
                        <button type="button" className="btn button-devas" onClick={this.handleSubmit}>
                            Submit
                        </button>
                    ) : (
                        ""
                    )}
                </Modal.Footer>
            </Modal>
        );
    }
}
