import Axios from "axios";
import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { customStyles } from "../../../style/table";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import { selectTheme } from "../../../style/reactselect";
import "react-dropdown/style.css";
import "../../../style/dropdown.css";

const columns = [
    {
        name: "Publisher",
        selector: "publisher_username",
        sortable: true,
        wrap: true,
    },
    {
        name: "Offer",
        selector: "offer_alias",
        sortable: true,
        wrap: true,
    },
    {
        name: "Action",
        selector: "create",
        sortable: true,
        wrap: true,
    },
];

export default class MissingKpi extends Component {
    state = {
        missingKpi: [],
        missingKpiLoading: true,
        showModal1: false,
        bizdevOffer: { label: "Select Bizdev Offer...", value: "" },
        bizdevPublisher: { label: "Select Bizdev Publisher...", value: "" },
        offer: { label: "Select Offer...", value: "" },
        publisher: { label: "Select Publisher...", value: "" },
        offerPercentage: "50",
        publisherPercentage: "50",
    };
    componentDidMount() {
        this.signal = Axios.CancelToken.source();
        this.getMissingKpi();
    }
    componentWillUnmount() {
        this.signal.cancel("Api is being canceled");
    }
    getMissingKpi = () => {
        Axios.get(`${process.env.REACT_APP_ADMIN_URL}/kpi/missing?from_date=${Date.now() - 2592000000}&to_date=${Date.now()}`, {
            cancelToken: this.signal.token,
            headers: {
                "Content-Type": "application/json",
                Authorization: "JWT " + localStorage.getItem("token_admin"),
            },
        })
            .then((response) => {
                let missingKpi = response.data.reverse().map((v) => {
                    v.create = (
                        <button
                            className="btn button-devas"
                            onClick={() => {
                                this.setState({
                                    showModal1: true,
                                    offer: { label: v.offer_alias, value: v.offer_id },
                                    publisher: { label: v.publisher_username, value: v.publisher_id },
                                });
                            }}
                        >
                            Create Deal
                        </button>
                    );
                    return v;
                });
                this.setState({ missingKpi, missingKpiLoading: false });
            })
            .catch((error) => {
                //this.props.history.push("/error?message=" + error.message);
            });
    };
    handleModal1Open = () => {
        this.setState({ showModal1: true });
    };
    handleModal1Close = () => {
        this.setState({
            showModal1: false,
            bizdevOffer: { label: "Select Bizdev Offer...", value: "" },
            bizdevPublisher: { label: "Select Bizdev Publisher...", value: "" },
            offer: { label: "Select Offer...", value: "" },
            publisher: { label: "Select Publisher...", value: "" },
            offerPercentage: "50",
            publisherPercentage: "50",
        });
    };
    handleSelectPublisher = (publisher) => {
        this.setState({ publisher });
    };
    handleSelectOffer = (offer) => {
        this.setState({ offer });
    };
    handleSelectPublisherBizdev = (bizdevPublisher) => {
        this.setState({ bizdevPublisher });
    };
    handleSelectOfferBizdev = (bizdevOffer) => {
        this.setState({ bizdevOffer });
    };
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };
    isAllNumber = (v) => {
        let reg = /^-?\d+\.?\d*$/;
        return v.match(reg);
    };
    handleSubmit = () => {
        if (
            !this.state.offer.value ||
            !this.state.publisher.value ||
            !this.state.bizdevPublisher.value ||
            !this.state.bizdevOffer.value ||
            !this.state.publisherPercentage ||
            !this.state.offerPercentage
        ) {
            return alert("Please fill in all fields!");
        }
        if (!this.isAllNumber(this.state.publisherPercentage) || 
            !this.isAllNumber(this.state.offerPercentage)
        ) {
            return alert("Offer percentage and pub percentage must be number!");
        }
        if(parseInt(this.state.publisherPercentage) + parseInt(this.state.offerPercentage) > 100){
            return alert("Sum of offer percentage and pub percentage must be smaller than 100!")
        }
        Axios.post(
            `${process.env.REACT_APP_ADMIN_URL}/kpi`,
            {
                offer: this.state.offer.value,
                publisher: this.state.publisher.value,
                bizdevPub: this.state.bizdevPublisher.value,
                bizdevOffer: this.state.bizdevOffer.value,
                pubKpiPercentage: this.state.publisherPercentage,
                offerKpiPercentage: this.state.offerPercentage,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            }
        )
            .then((response) => {
                alert("Kpi Created Successfully!")
                this.getMissingKpi();
                this.handleModal1Close();
                this.props.updateKpiList();
            })
            .catch((error) => {
                alert("Some problem occured! " + error.response.data.message);
                //this.props.history.push("/error?message=" + error.message);
            });
    };

    render() {
        return (
            <div className="col-12 col-lg-6 Statistics">
                <div className="py-3 bg-menu">
                    <div className="px-4 d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                            <h2 className="menu-h2-title mb-0">Unassigned Deal (30 days)</h2>
                        </div>
                        <div className="d-flex align-items-center">
                            <button className="btn button-devas d-inline-block mr-2" onClick={this.handleModal1Open}>
                                Create/Edit Deal
                            </button>
                        </div>
                    </div>
                </div>
                <DataTable
                    columns={columns}
                    data={this.state.missingKpi}
                    pagination
                    theme="myTheme"
                    className="table"
                    customStyles={customStyles}
                    noHeader={true}
                    progressPending={this.state.missingKpiLoading}
                    highlightOnHover={true}
                    defaultSortField="order"
                    defaultSortAsc={false}
                    paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 500, 1000]}
                />
                <Modal
                    show={this.state.showModal1}
                    onHide={this.handleModal1Close}
                    className={`${localStorage.getItem("theme") === "light" ? "light-theme" : "dark-theme"} myModal`}
                    centered
                >
                    <Modal.Header closeButton>
                        <h2 className="menu-h2-title modal-title">Create/Edit KPI</h2>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mt-3">
                            <span className="mr-1">Publisher:</span>
                            <Select
                                isMulti={false}
                                options={this.props.publisherList}
                                className="devas-select d-inline-block w-50 bg-menu"
                                classNamePrefix="devas-select"
                                onChange={this.handleSelectPublisher}
                                theme={selectTheme}
                                value={this.state.publisher}
                            />
                        </div>
                        <div className="mt-3">
                            <span className="mr-1">Offer:</span>
                            <Select
                                isMulti={false}
                                options={this.props.offerList}
                                className="devas-select d-inline-block w-50 bg-menu"
                                classNamePrefix="devas-select"
                                onChange={this.handleSelectOffer}
                                theme={selectTheme}
                                value={this.state.offer}
                            />
                        </div>
                        <div className="mt-3">
                            <span className="mr-1">Publisher Bizdev:</span>
                            <Select
                                isMulti={false}
                                options={this.props.bizdevList}
                                className="devas-select d-inline-block w-50 bg-menu"
                                classNamePrefix="devas-select"
                                onChange={this.handleSelectPublisherBizdev}
                                theme={selectTheme}
                                value={this.state.bizdevPublisher}
                            />
                        </div>
                        <div className="mt-3">
                            <span className="mr-1">Offer Bizdev:</span>
                            <Select
                                isMulti={false}
                                options={this.props.bizdevList}
                                className="devas-select d-inline-block w-50 bg-menu"
                                classNamePrefix="devas-select"
                                onChange={this.handleSelectOfferBizdev}
                                theme={selectTheme}
                                value={this.state.bizdevOffer}
                            />
                        </div>
                        <div className="mt-3">
                            <span>Publisher Margin Percentage:</span>
                            <input
                                name="publisherPercentage"
                                type="text"
                                value={this.state.publisherPercentage}
                                className="form-control search-input w-auto d-inline-block"
                                onChange={this.handleChange}
                                placeholder="Order Id..."
                            />
                        </div>
                        <div className="mt-3">
                            <span>Offer Margin Percentage:</span>
                            <input
                                name="offerPercentage"
                                type="text"
                                value={this.state.offerPercentage}
                                className="form-control search-input w-auto d-inline-block"
                                onChange={this.handleChange}
                                placeholder="Order Id..."
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn button-devas-gray" onClick={this.handleModal1Close}>
                            Cancel
                        </button>
                        <button type="button" className="btn button-devas" onClick={this.handleSubmit}>
                            Create/Edit
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
