import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import * as XLSX from "xlsx";
import axios from "axios";
import Select from "react-select";
import { selectTheme } from "../../../style/reactselect";

import "../../../style/dropdown.css";
import "../../../style/modal.css";

export default class SubmitAnonymousConversions extends Component {
    state = {
        offerList: [],
        publisherList: [],
        offer: { label: "Select an Offer", value: "" },
        publisher: { label: "Select a Publisher", value: "" },
        income: 0,
        pubPayout: 0,
        numConversion: 0,
        submitToken: "",
    };

    componentDidMount() {
        this.signal = axios.CancelToken.source();
        axios
            .get(`${process.env.REACT_APP_ADMIN_URL}/conversions/submit-token`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            })
            .then((response) => {
                this.setState({ submitToken: response.data.token });
            })
            .catch((error) => {
                alert("Error getting submit token!");
            });
        this.getAllOffers();
        this.getAllPubUsers();
    }

    getAllOffers = () => {
        this.signal = axios.CancelToken.source();
        axios
            .get(`${process.env.REACT_APP_ADMIN_URL}/offers`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            })
            .then((response) => {
                this.setState({
                    offerList: response.data.map((v) => {
                        return { label: v.alias, value: v._id };
                    }),
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    getAllPubUsers = () => {
        this.signal = axios.CancelToken.source();
        axios
            .get(`${process.env.REACT_APP_ADMIN_URL}/users/pub`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            })
            .then((response) => {
                console.log(response.data);
                let data = response.data.reverse().map((v) => {
                    return {
                        label: v.username,
                        value: v._id,
                    };
                });
                this.setState({
                    publisherList: data,
                });
            })
            .catch((error) => {
                console.log(error);
                //this.props.history.push("/error?message=" + error.message);
            });
    };

    handleModalClose = () => {
        this.setState({
            offer: { label: "Select an Offer", value: "" },
            publisher: { label: "Select a Publisher", value: "" },
            income: 0,
            pubPayout: 0,
            numConversion: 0,
            submitToken: "",
        });
        this.props.handleModalClose();
    };

    handleSubmit = () => {
        let { offer, publisher, income, pubPayout, numConversion, submitToken } = this.state;
        if (submitToken === "") return alert("Missing Submit Token, Please Reload Webpage!");
        if (offer.value === "" || publisher.value === "") return alert("Empty offer or publisher!");
        if (parseInt(income) != income || parseInt(pubPayout) != pubPayout || parseInt(numConversion) != numConversion)
            return alert("Last 3 fields must be number!");
        if (numConversion <= 0) return alert("Number of conversions must be larger than 0!");
        if (income <= pubPayout) return alert("income must be larger than pubPayout!");
        axios
            .post(
                `${process.env.REACT_APP_SUBMIT_URL}/conversions/admin-anonymous-conversions`,
                {
                    offer: offer.value,
                    publisher: publisher.value,
                    income,
                    pubPayout,
                    numConversion,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        token: this.state.submitToken,
                    },
                }
            )
            .then((response) => {
                alert(response.data.message);
                alert("Please restart webpage to see new conversions if available!");
                this.handleModalClose();
            })
            .catch((error) => {
                alert("error!");
                this.setState({ invalidMsg: error.response.data.message });
                //this.props.history.push("/error?message=" + error.message);
            });
    };

    hanldeDropdownChange = (e, name) => {
        this.setState({ [name]: e });
    };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    render() {
        return (
            <Modal
                show={this.props.showModal}
                onHide={this.handleModalClose}
                className={`${localStorage.getItem("theme") === "light" ? "light-theme" : "dark-theme"} myModal`}
                centered
            >
                <Modal.Header closeButton>
                    <h2 className="menu-h2-title modal-title">Submit Anonymous Conversions</h2>
                </Modal.Header>
                <Modal.Body>
                    <div className="mt-3">
                        <span className="mr-1">Offer:</span>
                        <Select
                            defaultValue={""}
                            isMulti={false}
                            options={this.state.offerList}
                            placeholder="Offer Id"
                            className="devas-select d-inline-block w-50 bg-menu"
                            classNamePrefix="devas-select"
                            onChange={(e) => this.hanldeDropdownChange(e, "offer")}
                            theme={selectTheme}
                            value={this.state.offer}
                        />
                    </div>
                    <div className="mt-3">
                        <span className="mr-1">Publisher:</span>
                        <Select
                            defaultValue={""}
                            isMulti={false}
                            options={this.state.publisherList}
                            placeholder="Offer Id"
                            className="devas-select d-inline-block w-50 bg-menu"
                            classNamePrefix="devas-select"
                            onChange={(e) => this.hanldeDropdownChange(e, "publisher")}
                            theme={selectTheme}
                            value={this.state.publisher}
                        />
                    </div>
                    <div className="mt-3">
                        <span>Number of Conversions:</span>
                        <input
                            name="numConversion"
                            type="number"
                            value={this.state.numConversion}
                            className="form-control search-input w-auto d-inline-block"
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="mt-3">
                        <span>Income:</span>
                        <input
                            name="income"
                            type="number"
                            value={this.state.income}
                            className="form-control search-input w-auto d-inline-block"
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="mt-3">
                        <span>Pub Payout:</span>
                        <input
                            name="pubPayout"
                            type="number"
                            value={this.state.pubPayout}
                            className="form-control search-input w-auto d-inline-block"
                            onChange={this.handleChange}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn button-devas-gray" onClick={this.handleModalClose}>
                        Cancel
                    </button>
                    <button type="button" className="btn button-devas" onClick={this.handleSubmit}>
                        Submit
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }
}
