import React, {Component} from "react";
import DataTable from "react-data-table-component";
import {customStyles} from "../../style/table";
const columns = [
    {
        name: "Order Id",
        selector: "order_id",
        maxWidth: "70px",
        sortable: true,
        center: true,
        wrap: true
    },
    {
        name: "Publisher",
        selector: "pub",
        sortable: true,
        wrap: true,
    },
    {
        name: "Offer Name",
        selector: "offer",
        sortable: true,
        wrap: true,
    },
    {
        name: "Status",
        selector: "status",
        sortable: true,
        wrap: true,
        conditionalCellStyles: [
            {
                when: row => row.status === "approved",
                style: {
                    color: "var(--green)"
                }
            },
            {
                when: row => row.status === "new" || row.status === "callbacks",
                style: {
                    color: "var(--orange)"
                }
            },
            {
                when: row =>row.status==="customer_reject",
                style:{
                    color:"var(--red)"
                }
            },
            {
                when: row =>row.status === "duplicated" || row.status==="trash",
                style:{
                    color: "var(--blue)"
                }
            }

        ]
    },
    {
        name: "Pub Status",
        selector: "pubStatus",
        sortable: true,
        wrap: true,
        conditionalCellStyles: [
            {
                when: row => row.pubStatus === "approved",
                style: {
                    color: "var(--green)"
                }
            },
            {
                when: row => row.pubStatus === "new" || row.pubStatus === "callbacks",
                style: {
                    color: "var(--orange)"
                }
            },
            {
                when: row =>row.pubStatus==="customer_reject",
                style:{
                    color:"var(--red)"
                }
            },
            {
                when: row =>row.pubStatus === "duplicated" || row.pubStatus==="trash",
                style:{
                    color: "var(--blue)"
                }
            }

        ]
    },

    {
        name: "Sale Log",
        selector: "sale_message",
        sortable: true,
        wrap: true,
    },
    {
        name: "Sale Log Pub",
        selector: "sale_message_pub",
        sortable: true,
        wrap: true,
    }
];
export default class ConversionsPubStatus extends Component{
    render() {
        return(
            <div className="col-12 col-md-6 Statistics">
                <div className="py-3 bg-menu">
                    <div className="px-4 d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                            <h2 className="menu-h2-title mb-0">PubStatus Conversions</h2>
                        </div>
                    </div>
                </div>
                <DataTable
                    data={this.props.synthesizedConversions}
                    columns={columns}
                    pagination
                    theme="myTheme"
                    className="table"
                    customStyles={customStyles}
                    noHeader={true}
                    defaultSortField="name"
                    defaultSortAsc={false}
                    paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 500, 1000]}
                />
            </div>
        )
    }
}