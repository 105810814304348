import React, {Component} from "react";
import {moneyFormat} from "../../helper/helper";
import "../../style/statistics.css";

export default class SummaryStatistics extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        var {
            approved_rate,
            approved,
            new_conv,
            callbacks,
            trash,
            customer_reject,
            duplicated,
            allConversions,
            approvedPayout,
            pendingPayout,
            rejectedPayout,
            payoutAdv,
            payoutPartner,
            payoutPub,
            pubStatusDeviation,
            deliveryStatusStats,
        } = this.props;
        return (
            <div className="SummaryStatistics col-12 mb-3">
                <div className="row">
                    <div className="col-6 order-0 col-lg-3">
                        <div className="mx-1 bg-menu px-3 py-3">
                            <div className="d-flex pb-2">
                                <div>
                                    <h5>Approval Rate</h5>
                                    <h4 className="text-blue">{approved_rate}%</h4>
                                </div>
                                <div className="ml-4">
                                    <h5>
                                        PubStatus Deviation
                                    </h5>
                                    <h4 className="text-blue cursor-pointer">
                                        {pubStatusDeviation}
                                    </h4>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div>
                                    <h5>All Conversions</h5>
                                    <h4 className="text-blue cursor-pointer" onClick={() => this.props.changeStatus("")}>
                                        {allConversions}
                                    </h4>
                                </div>
                                <div className="ml-4">
                                    <h5>
                                        {"Delivery Status (shipped/delivered/returned)"}
                                    </h5>
                                    <h4 className="text-blue cursor-pointer">
                                        {deliveryStatusStats}
                                    </h4>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-6 mt-3 mt-lg-0 order-2 order-lg-1 col-lg-3">
                        <div className="mx-1 bg-menu px-3 py-3">
                            <div className="d-flex">
                                <div>
                                    <h5>Margin</h5>
                                    <h4 className="pb-2 text-green">{moneyFormat(parseFloat(approvedPayout), 0, 3)}₫</h4>
                                </div>
                                <div className="ml-4">
                                    <h5>Payout Publisher</h5>
                                    <h4 className="text-blue">{moneyFormat(parseFloat(payoutPub), 0, 3)}₫</h4>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div>
                                    <h5>Approved</h5>
                                    <h4 className="text-green cursor-pointer"
                                        onClick={() => this.props.changeStatus("approved")}>
                                        {approved}
                                    </h4>
                                </div>
                                <div className="ml-4">
                                    <h5>Payout Adv</h5>
                                    <h4 className="text-blue">{moneyFormat(parseFloat(payoutAdv), 0, 3)}₫</h4>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-6 order-1 order-lg-2 col-lg-3">
                        <div className="mx-1 bg-menu px-3 py-3">
                            <h5>Pending Payout</h5>
                            <h4 className="pb-2 text-orange">{moneyFormat(parseFloat(pendingPayout), 0, 3)}₫</h4>
                            <div className="d-flex">
                                <div>
                                    <h5>New</h5>
                                    <h4 className="text-orange cursor-pointer"
                                        onClick={() => this.props.changeStatus("new")}>
                                        {new_conv}
                                    </h4>
                                </div>
                                <div className="ml-4">
                                    <h5>Callbacks</h5>
                                    <h4
                                        className="text-orange cursor-pointer"
                                        onClick={() => this.props.changeStatus("callbacks")}
                                    >
                                        {callbacks}
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 mt-3 mt-lg-0 order-3 col-lg-3">
                        <div className="mx-1 bg-menu px-3 py-3 pb-2">
                            {/* <h5>Rejected Payout</h5> */}
                            {/* <h4 className="pb-2 text-red">{moneyFormat(parseFloat(rejectedPayout), 0, 3)}₫</h4> */}
                            <div className="pb-2">
                                <h5>Rejected</h5>
                                <h4
                                    className="text-red cursor-pointer"
                                    onClick={() => this.props.changeStatus("customer_reject")}
                                >
                                    {customer_reject}
                                </h4>
                            </div>
                            <div className="d-flex">
                                <div className="">
                                    <h5>Trash</h5>
                                    <h4 className="text-red cursor-pointer"
                                        onClick={() => this.props.changeStatus("trash")}>
                                        {trash}
                                    </h4>
                                </div>
                                <div className="ml-4">
                                    <h5>Duplicated</h5>
                                    <h4 className="text-red cursor-pointer"
                                        onClick={() => this.props.changeStatus("duplicated")}>
                                        {duplicated}
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
