import React, { Component } from "react";
import Dropdown from "react-dropdown";
import axios from "axios";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

import Main from "./adminbase/MainAdmin";
import OfferLists from "./adminbase/OfferLists";
import redirectNoToken from "../helper/redirectNoToken";
import { moneyFormat } from "../helper/helper";

import "react-dropdown/style.css";
import "../style/dropdown.css";
import "../style/offers.css";
import "../style/offers.css";

export default class Offers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            offerDropDown: "",
            offerDropDownLists: [{ value: "", label: "All" }],
            search: "",
            offers: [],
            offersLists: [],
            isLoading: true,
        };
    }
    componentDidMount() {
        this.signal = axios.CancelToken.source();
        document.title = "Offers";
        redirectNoToken.bind(this)();
        axios
            .get(`${process.env.REACT_APP_ADMIN_URL}/offers`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            })
            .then((response) => {
                var offers = response.data;
                var category = offers.map((offer) => {
                    return offer.category.hasOwnProperty("name") ? offer.category.name : "No Category";
                });
                category = [...new Set(category)].map((value) => {
                    return { value: value, label: value };
                });
                category.push({ value: "", label: "All" });
                category = category.reverse();
                offers.sort((a, b) => {
                    if (a.status === "inactive" && b.status === "active") {
                        return 1;
                    } else return -1;
                });
                console.log(res);
                var res = offers.map((v, i) => ({
                    imgLink: (
                        <Link to={`/offers/${v.alias}`} className="offerlist-link">
                            <img className="img-offerlist" src={v.imgLink} />
                        </Link>
                    ),
                    alias: (
                        <Link to={`/offers/${v.alias}`} className="offerlist-link">
                            {v.alias}
                        </Link>
                    ),
                    name: (
                        <Link to={`/offers/${v.alias}`} className="offerlist-link">
                            {v.name}
                        </Link>
                    ),
                    category: v.category.name,
                    price: !v.isCombo ? (
                        moneyFormat(parseFloat(v.price), 0, 3) + " " + (v.currency ? v.currency : "")
                    ) : (
                        <Link to={`/offers/${v.alias}`} className="offerlist-link">
                            More Info
                        </Link>
                    ),
                    payoutPub: !v.isCombo ? (
                        moneyFormat(parseFloat(v.payoutPub), 0, 3) + " " + v.systemCurrency
                    ) : (
                        <Link to={`/offers/${v.alias}`} className="offerlist-link">
                            More Info
                        </Link>
                    ),
                    income: !v.isCombo ? (
                        moneyFormat(parseFloat(v.income), 0, 3) + " " + v.systemCurrency
                    ) : (
                        <Link to={`/offers/${v.alias}`} className="offerlist-link">
                            More Info
                        </Link>
                    ),
                    extraFee: !v.isCombo ? (
                        moneyFormat(parseFloat(v.extraFee), 0, 3) + " " + v.systemCurrency
                    ) : (
                        <Link to={`/offers/${v.alias}`} className="offerlist-link">
                            More Info
                        </Link>
                    ),
                    status: v.status,
                    resources: v.resourcesLink ? (
                        <a href={v.resourcesLink} target="_blank">
                            <FontAwesomeIcon icon={faExternalLinkAlt} className="ml-1" size="xs" />
                        </a>
                    ) : (
                        ""
                    ),
                    isCombo: v.isCombo ? "Yes" : "No",
                }));
                this.setState({
                    offersList: res,
                    offers: res,
                    isLoading: false,
                    offerDropDownLists: category,
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }
    componentWillUnmount() {
        this.signal.cancel("Api is being canceled");
    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };
    handleSearchForm = (e) => {
        e.preventDefault();
    };
    handleDropDown = (e) => {
        this.setState({ offerDropDown: e.value }, () => this.offerSearch());
    };
    handleSearch = (e) => {
        this.setState({ search: e.target.value }, () => this.offerSearch());
    };
    offerSearch = (e) => {
        this.setState((state, props) => {
            let offers = state.offersList.filter((offer) => {
                return (
                    offer.category.includes(state.offerDropDown) &&
                    (offer.name.props.children.toLowerCase().includes(state.search.toLowerCase()) ||
                        offer.alias.props.children.toLowerCase().includes(state.search.toLowerCase()))
                );
            });
            return { offers };
        });
    };
    render() {
        var { offerDropDown, offerDropDownLists, search, offers } = this.state;
        return (
            <Main history={this.props.history} linkTo={["/offers"]} linkName={["Offers"]} selectedSection={2}>
                <div className="col-12 col-lg-6">
                    <div className="row h-100">
                        <div className="col-8 col-lg-7 pb-3 pt-lg-3">
                            <div className="bg-menu">
                                <form onSubmit={this.handleSearchForm}>
                                    <input
                                        type="text"
                                        className="form-control search-input"
                                        name="search"
                                        value={search}
                                        onChange={this.handleSearch}
                                        placeholder="Seach Offers"
                                    />
                                </form>
                            </div>
                        </div>
                        <div className="col-4 col-lg-5 pb-3 pt-lg-3">
                            <Dropdown
                                options={offerDropDownLists}
                                onChange={this.handleDropDown}
                                value={offerDropDown}
                                placeholder="Category"
                            />
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="py-3 bg-menu mb-1">
                        <div className="px-4 d-flex justify-content-between">
                            <h2 className="menu-h2-title">Offers</h2>
                            <div className="d-flex ">
                                <button
                                    className="btn button-devas mr-2"
                                    onClick={() => this.props.history.push("/offers/templates")}
                                >
                                    Offer Templates
                                </button>
                                <button className="btn button-devas ml-1" onClick={() => this.props.history.push("/offers/new")}>
                                    Create Offer
                                </button>
                            </div>
                        </div>
                        <div className="mt-3">
                            <OfferLists offers={offers} history={this.props.history} isLoading={this.state.isLoading} />
                        </div>
                    </div>
                </div>
            </Main>
        );
    }
}
