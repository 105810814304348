import React, { Component } from "react";
import Dropdown from "react-dropdown";

import MainAdmin from "./adminbase/MainAdmin";
import AdvInvoice from "./adminbase/AdvInvoice";
import PubInvoice from "./adminbase/PubInvoice";
import "react-dropdown/style.css";
import "../style/dropdown.css";
import "../style/invoice.css";

export default class InvoiceAdmin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            invoiceType: { value: "", label: "Select..." },
        };
    }

    handleDropDown = (e) => {
        this.setState({ invoiceType: e });
    };
    render() {
        return (
            <MainAdmin history={this.props.history} linkTo={["/invoices"]} linkName={["Invoices"]} selectedSection={5}>
                <div className="d-none d-lg-block col-6"></div>
                <div className="col-6 col-lg-3 py-3 mr-4">
                    <Dropdown
                        options={[
                            { value: "", label: "Select..." },
                            { value: "adv", label: "Adv Invoice" },
                            { value: "pub", label: "Pub Invoice" },
                        ]}
                        onChange={this.handleDropDown}
                        value={this.state.invoiceType}
                    />
                </div>
                {this.state.invoiceType.value === "adv" ? (
                    <AdvInvoice />
                ) : this.state.invoiceType.value === "pub" ? (
                    <PubInvoice />
                ) : (
                    ""
                )}
            </MainAdmin>
        );
    }
}
