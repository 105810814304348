import React, { Component } from "react";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import axios from "axios";
import moment from "moment";
import { moneyFormat } from "../../helper/helper";
import { selectTheme } from "../../style/reactselect";
import "../../style/react-datepicker.css";
import "../../style/daterangepicker.css";
import "../../style/dropdown.css";
import "../../style/modal.css";

export default class EditConversionPayout extends Component {
    state = {
        offerList: [],
        pubUsersList: [],
        offer: { label: "Select Offer...", value: "" },
        publisher: { label: "Select Publisher...", value: "" },
        date: [new Date(), new Date()],
    };
    componentDidMount() {
        this.signal = axios.CancelToken.source();
        this.getAllOffers();
        this.getAllPubUsers();
    }
    getAllOffers = () => {
        axios
            .get(`${process.env.REACT_APP_ADMIN_URL}/offers`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            })
            .then((response) => {
                this.setState({
                    offerList: response.data.map((v) => {
                        return { label: v.alias, value: v._id };
                    }),
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };
    getAllPubUsers = () => {
        axios
            .get(`${process.env.REACT_APP_ADMIN_URL}/users/pub`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            })
            .then((response) => {
                let data = response.data.reverse().map((v) => {
                    return {
                        label: v.username,
                        value: v._id,
                    };
                });
                this.setState({
                    pubUsersList: data,
                });
            })
            .catch((error) => {
                console.log(error);
                //this.props.history.push("/error?message=" + error.message);
            });
    };
    onChange = (date) => this.setState({ date });
    handleChangeOffer = (offer) => {
        this.setState({ offer });
    };
    handleChangePub = (publisher) => {
        this.setState({ publisher });
    };
    handleModalClose = () => {
        this.setState({
            offer: { label: "Select Offer...", value: "" },
            publisher: { label: "Select Publisher...", value: "" },
            date: [new Date(), new Date()],
        });
        this.props.handleModalClose();
    };
    handleSubmit = () => {
        if (!this.state.offer.value || !this.state.publisher.value) {
            return alert("Please select all fields!");
        }
        axios
            .post(
                `${process.env.REACT_APP_ADMIN_URL}/conversions/payout-list-update`,
                {
                    from_date: moment(this.state.date[0]).startOf("date").unix() * 1000,
                    to_date: moment(this.state.date[1]).endOf("month").unix() * 1000 + 999,
                    offer: this.state.offer.value,
                    publisher: this.state.publisher.value,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "JWT " + localStorage.getItem("token_admin"),
                    },
                }
            )
            .then((response) => {
                alert(response.data.message);
            })
            .catch((error) => {
                alert("Some problem occured! " + error.response.data.message);
                //this.props.history.push("/error?message=" + error.message);
            });
    };
    render() {
        return (
            <Modal
                show={this.props.showModal}
                onHide={this.handleModalClose}
                className={`${localStorage.getItem("theme") === "light" ? "light-theme" : "dark-theme"} myModal`}
                centered
            >
                <Modal.Header closeButton>
                    <h2 className="menu-h2-title modal-title">Edit Conversion Payout</h2>
                </Modal.Header>
                <Modal.Body>
                    <div>Created Date: </div>
                    <div className="d-flex align-items-center bg-daterangepicker">
                        <DateRangePicker onChange={this.onChange} value={this.state.date} />
                    </div>
                    <div className="mt-3">
                        <span className="mr-1">Offer:</span>
                        <Select
                            defaultValue={""}
                            isMulti={false}
                            name="offerId"
                            options={this.state.offerList}
                            placeholder="Offer Id"
                            className="devas-select d-inline-block w-50 bg-menu"
                            classNamePrefix="devas-select"
                            onChange={this.handleChangeOffer}
                            theme={selectTheme}
                            value={this.state.offer}
                        />
                    </div>
                    <div className="mt-3">
                        <span className="mr-1">Publisher:</span>
                        <Select
                            defaultValue={""}
                            isMulti={false}
                            name="offerId"
                            options={this.state.pubUsersList}
                            placeholder="Offer Id"
                            className="devas-select d-inline-block w-50 bg-menu"
                            classNamePrefix="devas-select"
                            onChange={this.handleChangePub}
                            theme={selectTheme}
                            value={this.state.publisher}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn button-devas-gray" onClick={this.handleModalClose}>
                        Cancel
                    </button>
                    <button type="button" className="btn button-devas" onClick={this.handleSubmit}>
                        Submit
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }
}
