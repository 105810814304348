import React, { Component } from "react";
import { StatisticsTable } from "../../style/statisticsByPubAndOfferTable";
import DataTable from "react-data-table-component";

const columns = [
    {
        name: "Publisher ID",
        selector: "name",
        maxWidth: "70px",
        sortable: true,
        center: true,
        wrap: true,
    },
    {
        name: "Conversion",
        selector: "conversion_text",
        sortable: true,
        center: true,
    },
    {
        name: "AR",
        selector: "ar",
        sortable: true,
        center: true,
    },
];
export default class ConversionStatisticsPub extends Component {
    rowClicked = (rowData) => {
        const pubName = rowData.name;
        // const recentPubFilter = this.props.pubFilter;
        // if (recentPubFilter.filter((e) => e.value === pubName).length > 0) {
        //     return false;
        // }
        // recentPubFilter.push({
        //     value: pubName,
        //     label: pubName,
        // });
        this.props.handleClick("pub", [{value: pubName, label: pubName}], ".select-pub");
    };
    render() {
        return (
            <div className="col-12 col-md-6 Statistics">
                <div className="py-3 bg-menu">
                    <div className="px-4 d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                            <h2 className="menu-h2-title mb-0">Publisher Statistics</h2>
                        </div>
                    </div>
                </div>
                <DataTable
                    data={this.props.synthesizedConversions}
                    columns={columns}
                    pagination
                    theme="myTheme"
                    className="table"
                    customStyles={StatisticsTable}
                    noHeader={true}
                    defaultSortField="name"
                    defaultSortAsc={false}
                    paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 500, 1000]}
                    onRowClicked={this.rowClicked}
                />
            </div>
        );
    }
}
