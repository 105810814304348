import React, { Component } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import MainAdmin from "./adminbase/MainAdmin";
import redirectNoToken from "../helper/redirectNoToken";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { moneyFormat } from "../helper/helper";
import "../style/offerDetail.css";
import "../style/modal.css";
import Main from "./adminbase/MainAdmin";
import DataTable from "react-data-table-component";
import { customStyles } from "../style/table";
const columns = [
    {
        name: "Offer Id",
        selector: "offerID",
        minWidth: "210px",
        sortable: true,
        wrap: true,
    },
    {
        name: "Offer Name",
        selector: "offerName",
        sortable: true,
        center: true,
    },
    {
        name: "Offer Alias",
        selector: "offerAlias",
        center: true,
        sortable: true,
    },
    {
        name: "Pub",
        selector: "pub",
        center: true,
        sortable: true,
    },
    {
        name: "Pub Payout",
        selector: "payoutPub",
        center: true,
        sortable: true,
        wrap: true,
    },
    {
        name: "Is Combo",
        selector: "isCombo",
        center: true,
        sortable: true,
    },
    {
        name: "Edit Pub Payout",
        selector: "edit",
        center: true,
        sortable: true,
    },
];
export default class OfferDetailAdmin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            offer: {},
            showModal1: false,
            pubsHaveOffer: [],
            currentPayout: {},
            currentOffer: {},
            newPayout: "",
            showModal2: false,
            isLoading: false,
        };
    }

    componentDidMount() {
        this.signal = axios.CancelToken.source();
        document.title = "Offers";
        redirectNoToken.bind(this)();
        axios
            .get(`${process.env.REACT_APP_ADMIN_URL}/offers`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            })
            .then((response) => {
                let offers = response.data;
                let alias = this.props.match.params.alias;
                if (offers.some((v) => v.alias === alias)) {
                    offers.forEach((offer) => {
                        if (offer.alias === alias) {
                            this.setState({ offer }, this.getPubsHaveOffer);
                        }
                    });
                } else {
                    this.props.history.push("/404");
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    getPubsHaveOffer = () => {
        const offerID = this.state.offer._id;
        this.signal = axios.CancelToken.source();
        axios
            .get(`${process.env.REACT_APP_ADMIN_URL}/pub/offer/price/getPubsHaveOffer/${offerID}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            })
            .then((res) => {
                const pubsHaveOffer = res.data.reverse().map((v) => {
                    return {
                        offerID: v.offerId._id,
                        offerName: v.offerId.name,
                        offerAlias: v.offerId.alias,
                        pub: v.pubId.username,
                        payoutPub: !v.offerId.isCombo ? (
                            moneyFormat(parseFloat(v.price), 0, 3) + " " + v.offerId.systemCurrency
                        ) : (
                            <ul className="mb-0 table-ul">
                                {v.price.map((value, i) => (
                                    <li key={i}>
                                        {value.alias} - {moneyFormat(parseFloat(value.payoutPub), 0, 3) + " " + v.offerId.systemCurrency}
                                    </li>
                                ))}
                            </ul>
                        ),
                        edit: (
                            <button
                                className="btn button-devas-medium"
                                onClick={() => {
                                    let newPayout = "";
                                    if (v.isCombo) {
                                        newPayout = [];
                                    }
                                    this.setState(
                                        {
                                            showModal2: true,
                                            currentPayout: v,
                                            currentOffer: v.offerId,
                                            newPayout,
                                        },
                                        () => console.log(this.state.currentOffer)
                                    );
                                }}
                            >
                                Edit
                            </button>
                        ),
                        isCombo: v.offerId.isCombo ? "Yes" : "No",
                    };
                });
                this.setState({ pubsHaveOffer });
            })
            .catch((error) => {
                console.log(error);
            });
    };
    componentWillUnmount() {
        this.signal.cancel("Api is being canceled");
    }

    handleModal1Close = () => {
        this.setState({ showModal1: false });
    };
    handleModal1Open = () => {
        this.setState({ showModal1: true });
    };
    deleteOffer = () => {
        this.signal = axios.CancelToken.source();
        axios
            .delete(`${process.env.REACT_APP_ADMIN_URL}/offers/${this.state.offer._id}`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            })
            .then((response) => {
                this.props.history.push("/offers");
            })
            .catch((error) => {
                alert("A problem occured while deleting this offer! " + error.response.data.message);
            });
    };
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };
    handleComboPubPayoutChange = (index, value, payoutObj) => {
        payoutObj.payoutPub = value;
        let newPayout = [...this.state.newPayout];
        newPayout[index] = payoutObj;
        this.setState({ newPayout });
    };
    handleModal2Close = () => {
        this.setState({
            showModal2: false,
            currentPayout: {},
            currentOffer: {},
            newPayout: "",
        });
    };
    editPubPayout = (isCombo) => {
        axios
            .put(
                `${process.env.REACT_APP_ADMIN_URL}/pub/offer/price/${this.state.currentPayout._id}`,
                {
                    isCombo: isCombo,
                    price: this.state.newPayout,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "JWT " + localStorage.getItem("token_admin"),
                    },
                }
            )
            .then((response) => {
                let data = response.data;
                console.log(data);
                this.handleModal2Close();
                this.getPubsHaveOffer();
            })
            .catch((error) => {
                console.log(error);
                alert("Something went wrong! " + error.response.data.message);
                //this.props.history.push("/error?message=" + error.message);
            });
    };
    checkNewPayout = () => {
        const isCombo = this.state.currentOffer.isCombo;
        if (isCombo) {
            let newPayoutClone = [...this.state.newPayout];
            let newPayoutFromCurrentPayout = [...this.state.currentPayout.price];
            let check1 = true;
            let check2 = true;
            newPayoutClone.map((item, index) => {
                console.log(item);
                if (!item.payoutPub || item.payoutPub === "") check1 = false;
                else if (!item.payoutPub.match(/^-?\d+\.?\d*$/)) check2 = false;
                newPayoutFromCurrentPayout[index] = item;
            });
            if (!check1) {
                alert("Please fill in all text fields!");
            } else if (!check2) {
                alert("Pub Payout must be a number!");
            } else {
                this.setState({ newPayout: newPayoutFromCurrentPayout }, () => this.editPubPayout(isCombo));
            }
        } else {
            if (this.state.newPayout === "") {
                alert("Please fill in all text fields!");
            } else if (!this.state.newPayout.trim().match(/^-?\d+\.?\d*$/)) {
                alert("Pub Payout must be a number!");
            } else this.editPubPayout(isCombo);
        }
    };
    render() {
        var alias = this.props.match.params.alias;
        var { offer } = this.state;
        return (
            <MainAdmin
                history={this.props.history}
                linkTo={["/offers", "/offers/" + alias]}
                linkName={["Offers", alias]}
                selectedSection={2}
            >
                <div className="col-12">
                    <div className="py-3 bg-menu mb-1">
                        <div className="px-4 d-flex justify-content-between border-bottom-thin-link pb-3 align-items-center">
                            <h2 className="menu-h2-title mb-0">Offer Detail</h2>
                            <div>
                                <button
                                    className="btn button-devas mr-2"
                                    onClick={() => this.props.history.push(`/offers/${offer.alias}/edit`)}
                                >
                                    Edit Offer
                                </button>
                                <button className="btn button-devas-danger" onClick={this.handleModal1Open}>
                                    Delete Offer
                                </button>
                            </div>
                        </div>
                        <div className="offer-admin mt-3">
                            <div className="container-fluid background-dark2 my-2 rounded">
                                <div className="row">
                                    <div className="col-12 col-lg-5 pr-0 pr-lg-4">
                                        <div
                                            className="img-responsive rounded"
                                            style={{
                                                backgroundImage: `url('${offer.imgLink}')`,
                                            }}
                                        ></div>
                                    </div>
                                    <div className="col-12 col-lg-7 mt-4 mt-lg-0 pl-4">
                                        <h4 className="menu-h2-title mb-2">{offer.name}</h4>
                                        <h6 className="my-4 text">
                                            <span className="bold-text w-200 d-inline-block">Offer ID:</span> {offer._id}
                                        </h6>
                                        <h6 className="my-4 text">
                                            <span className="bold-text w-200 d-inline-block">Offer alias:</span> {offer.alias}
                                        </h6>
                                        <h6 className="my-4 text">
                                            <span className="bold-text w-200 d-inline-block">Currency:</span> {offer.currency}
                                        </h6>
                                        <h6 className="my-4 text">
                                            <span className="bold-text w-200 d-inline-block">ADV username:</span>{" "}
                                            {offer.adv ? offer.adv.username : ""}
                                        </h6>
                                        <h6 className="my-4 text">
                                            <span className="bold-text w-200 d-inline-block">Category:</span>{" "}
                                            {offer.category ? offer.category.name : ""}
                                        </h6>
                                        <h6 className="my-4 text">
                                            <span className="bold-text w-200 d-inline-block">Geolocation:</span> {offer.geo}
                                        </h6>
                                        <h6 className="my-4 text">
                                            <span className="bold-text w-200 d-inline-block">Approved Status Change:</span>{" "}
                                            {`${offer.approved_conversion_change_duration} day(s)`}
                                        </h6>
                                        <h6 className="my-4 text">
                                            <span className="bold-text w-200 d-inline-block">Use Delivery Status:</span>{" "}
                                            <span className="">{offer.useDeliveryStatus ? "Yes" : "No"}</span>
                                        </h6>
                                        {offer.details && (
                                            <h6 className="my-4 text">
                                                <span className="bold-text w-200 d-inline-block">Details:</span> {offer.details}
                                            </h6>
                                        )}
                                        {offer.resourcesLink && (
                                            <h6 className="my-4 text">
                                                <span className="bold-text w-200 d-inline-block">Resources:</span>{" "}
                                                <a href={offer.resourcesLink} target="_blank">
                                                    <FontAwesomeIcon icon={faExternalLinkAlt} className="ml-1" size="xs" />
                                                </a>
                                            </h6>
                                        )}
                                        {!offer.isCombo && (
                                            <>
                                                <h6 className="my-4 text">
                                                    <span className="bold-text w-200 d-inline-block">Price:</span>{" "}
                                                    {moneyFormat(parseFloat(offer.price), 0, 3) +
                                                        " " +
                                                        (offer.currency ? offer.currency : "")}
                                                </h6>
                                                <h6 className="my-4 bold-text">
                                                    <span className="w-200 d-inline-block">Adv Payout / Income:</span>
                                                    <span className="color-primary">
                                                        {moneyFormat(parseFloat(offer.income), 0, 3) + " " + offer.systemCurrency}
                                                    </span>
                                                </h6>
                                                <h6 className="my-4 bold-text">
                                                    <span className="w-200 d-inline-block">Default Pub Payout:</span>
                                                    <span className="color-primary">
                                                        {moneyFormat(parseFloat(offer.payoutPub), 0, 3) + " " + offer.systemCurrency}
                                                    </span>
                                                </h6>
                                            </>
                                        )}
                                        <h6 className="my-4 bold-text">
                                            <span className="w-200 d-inline-block">Extra Fee:</span>
                                            <span className="color-primary">
                                                {moneyFormat(parseFloat(offer.extraFee), 0, 3) + " " + offer.systemCurrency}
                                            </span>
                                        </h6>
                                        {offer.isCombo && (
                                            <>
                                                {offer.comboChoices.map((v, i) => (
                                                    <div key={i}>
                                                        <h4 className="my-4">Combo {i + 1}</h4>
                                                        <h6 className="my-4 text">
                                                            <span className="bold-text w-200 d-inline-block">Name:</span> {v.name}
                                                        </h6>
                                                        <h6 className="my-4 text">
                                                            <span className="bold-text w-200 d-inline-block">Alias:</span>{" "}
                                                            {v.alias}
                                                        </h6>
                                                        <h6 className="my-4 text">
                                                            <span className="bold-text w-200 d-inline-block">Description:</span>{" "}
                                                            {v.description}
                                                        </h6>
                                                        <h6 className="my-4 text">
                                                            <span className="bold-text w-200 d-inline-block">Price:</span>{" "}
                                                            {moneyFormat(parseFloat(v.price), 0, 3) +
                                                                " " +
                                                                (offer.currency ? offer.currency : "")}
                                                        </h6>
                                                        <h6 className="my-4 bold-text">
                                                            <span className="w-200 d-inline-block">Adv Payout / Income:</span>
                                                            <span className="color-primary">
                                                                {moneyFormat(parseFloat(v.income), 0, 3) + " " + offer.systemCurrency}
                                                            </span>
                                                        </h6>
                                                        <h6 className="my-4 bold-text">
                                                            <span className="w-200 d-inline-block">Default Pub Payout:</span>
                                                            <span className="color-primary">
                                                                {moneyFormat(parseFloat(v.payoutPub), 0, 3) + " " + offer.systemCurrency}
                                                            </span>
                                                        </h6>
                                                    </div>
                                                ))}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="py-3 bg-menu mb-1 mt-4">
                        <div className="px-4 pb-3">
                            <h2 className="menu-h2-title mb-0">Offer Permissions</h2>
                        </div>
                        <DataTable
                            columns={columns}
                            data={this.state.pubsHaveOffer}
                            pagination
                            theme="myTheme"
                            className="table"
                            customStyles={customStyles}
                            noHeader={true}
                            progressPending={this.state.isLoading}
                            highlightOnHover={true}
                            paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 500, 1000]}
                        />
                    </div>
                </div>
                <Modal
                    show={this.state.showModal1}
                    onHide={this.handleModal1Close}
                    className={`${localStorage.getItem("theme") === "light" ? "light-theme" : "dark-theme"} myModal`}
                    centered
                >
                    <Modal.Header closeButton>
                        <h2 className="menu-h2-title modal-title">Delete Offer</h2>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="mb-3">
                            Deleting an offer will result in complicated behaviors. You should only delete an offer when you
                            really know what you are doing. Are you sure you want to delete this offer?
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn button-devas-gray" onClick={this.handleModal1Close}>
                            No
                        </button>
                        <button type="button" className="btn button-devas" onClick={this.deleteOffer}>
                            Yes
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={this.state.showModal2}
                    onHide={this.handleModal2Close}
                    className={`${localStorage.getItem("theme") === "light" ? "light-theme" : "dark-theme"} myModal`}
                    centered
                >
                    <Modal.Header closeButton>
                        <h2 className="menu-h2-title modal-title">Edit Pub Payout</h2>
                    </Modal.Header>
                    <Modal.Body>
                        <div>Offer name: {this.state.currentOffer.alias}</div>
                        {!this.state.currentOffer.isCombo ? (
                            <div>
                                <div className="mt-3">Current Payout: {this.state.currentPayout.price}</div>
                                <div className="mt-3">
                                    <span>Pub Payout:</span>
                                    <input
                                        name="newPayout"
                                        type="text"
                                        value={this.state.newPayout}
                                        className="form-control search-input w-auto d-inline-block"
                                        onChange={this.handleChange}
                                        placeholder="New Pub Payout..."
                                    />
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div className="mt-3">
                                    Current Payout:{" "}
                                    {this.state.currentPayout.price.map((obj, index) => {
                                        return (
                                            <div key={index}>
                                                <div className="mt-3">
                                                    Name: {obj.alias} - {obj.name}
                                                </div>
                                                <div className="mt-3 mb-2">
                                                    <span>Payout Pub: {obj.payoutPub}</span>
                                                    <input
                                                        name="newPubPayoutCombo"
                                                        type="text"
                                                        value={
                                                            this.state.newPayout[index] &&
                                                            this.state.newPayout[index].hasOwnProperty("payoutPub")
                                                                ? this.state.newPayout[index].payoutPub
                                                                : ""
                                                        }
                                                        placeholder="New Pub Payout ..."
                                                        onChange={(event) =>
                                                            this.handleComboPubPayoutChange(index, event.target.value, { ...obj })
                                                        }
                                                        className="form-control search-input w-auto d-inline-block"
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn button-devas-gray" onClick={this.handleModal2Close}>
                            Close
                        </button>
                        <button type="button" className="btn button-devas" onClick={this.checkNewPayout}>
                            Edit
                        </button>
                    </Modal.Footer>
                </Modal>
            </MainAdmin>
        );
    }
}
