import React, { Component } from "react";
import Select from "react-select";
import axios from "axios";
import Main from "./MainAdmin";
import InputAssign from "./inputAssign";
import { selectTheme } from "../../style/reactselect";
import "../../style/dropdown.css";
import { moneyFormat } from "../../helper/helper";
export default class TemplateForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            offerLists: [],
        };
    }
    componentDidMount() {
        this.signal = axios.CancelToken.source();
        axios
            .get(`${process.env.REACT_APP_ADMIN_URL}/offers`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            })
            .then((response) => {
                let offerLists = response.data
                    .filter((v) => v.isCombo === false)
                    .reverse()
                    .map((v) => {
                        return { label: v.alias, value: v._id, payoutPub: v.payoutPub };
                    });
                this.setState({ offerLists });
            })
            .catch((error) => {
                console.log(error);
            });
    }
    componentWillUnmount() {
        this.signal.cancel("Api is being canceled");
    }
    handleSubmit = (e) => {
        e.preventDefault();
        let { offers, templateName, handleChangeName, submitAction } = this.props;
        if (!offers.every((v) => v.hasOwnProperty("payoutPub") && v.payoutPub !== "") || templateName === "") {
            handleChangeName("invalidMsg", "Please fill in all fields!");
        } else if (
            !offers.every((v) => {
                let reg = /^-?\d+\.?\d*$/;
                return v.payoutPub.toString().trim().match(reg);
            })
        ) {
            handleChangeName("invalidMsg", "Allow number input only!");
        } else {
            submitAction();
        }
    };
    render() {
        let {
            isNew,
            invalidMsg,
            validMsg,
            offers,
            history,
            handleOfferChange,
            handleChangeOfferPayoutPub,
            templateName,
        } = this.props;
        let inputClass = "";
        if (invalidMsg !== "") {
            inputClass = "is-invalid";
        }
        if (validMsg !== "") {
            inputClass = "is-valid";
        }
        let linkTo = isNew
            ? ["/offers", "/offers/templates", "/offers/templates/new"]
            : [
                  "/offers/",
                  "/offers/templates",
                  "/offers/templates",
                  "/offers/templates/" + this.props.match.params.templateId + "/edit",
              ];
        let linkName = isNew
            ? ["Offers", "Templates", "New"]
            : ["Offers", "Templates", this.props.match.params.templateId, "Edit"];
        return (
            <Main history={this.props.history} linkTo={linkTo} linkName={linkName} selectedSection={2}>
                <div className="col-12">
                    <div className="py-3 bg-menu mb-1">
                        <div className="px-4 d-flex justify-content-between">
                            <h2 className="menu-h2-title">{isNew ? "Create Template" : "Edit Template"}</h2>
                        </div>

                        <form onSubmit={this.handleSubmit} className="normal-form">
                            <div className="px-4 row mt-2">
                                <div className="col-12 col-md-9 col-xl-6">
                                    <div className="form-group">
                                        <div className="mb-2 dropdown-form">
                                            <label htmlFor="url">Offers</label>
                                            <Select
                                                defaultValue="one"
                                                isMulti={true}
                                                name="offerId"
                                                options={this.state.offerLists}
                                                className="devas-select"
                                                classNamePrefix="devas-select"
                                                onChange={handleOfferChange}
                                                theme={selectTheme}
                                                value={offers}
                                            />
                                        </div>
                                        {offers &&
                                            offers.map((v, i) => (
                                                <InputAssign
                                                    value={v.payoutPub}
                                                    index={i}
                                                    runFunction={handleChangeOfferPayoutPub}
                                                    text={
                                                        "Offer Name: " +
                                                        v.label +
                                                        " - PayoutPub Default: " +
                                                        moneyFormat(
                                                            parseFloat(
                                                                this.state.offerLists.filter((value) => value.value === v.value)
                                                                    .length !== 0
                                                                    ? this.state.offerLists.filter(
                                                                          (value) => value.value === v.value
                                                                      )[0].payoutPub
                                                                    : 0
                                                            ),
                                                            0,
                                                            3
                                                        ) +
                                                        "₫"
                                                    }
                                                    placeholder="Publisher Payout"
                                                    key={i}
                                                />
                                            ))}
                                        <div className="mb-2">
                                            <label htmlFor="url">Template Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={templateName}
                                                name="templateName"
                                                onChange={this.props.handleChange}
                                                placeholder="Template Name"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 form-group">
                                    <div className="d-flex align-items-center justify-content-center mt-4">
                                        <button
                                            type="button"
                                            className="btn button-devas-gray-large mr-4"
                                            onClick={() => history.push("/offers/templates")}
                                        >
                                            Cancel
                                        </button>
                                        <button type="submit" className="btn button-devas-large">
                                            {isNew ? "Create" : "Edit"}
                                        </button>
                                    </div>
                                    <input type="hidden" className={inputClass} />
                                    <div className="invalid-feedback mt-3 text-center">{invalidMsg}</div>
                                    <div className="valid-feedback mt-3 text-center">{validMsg}</div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Main>
        );
    }
}
