import React, { Component } from "react";
import "./style/app.css";
import { withRouter, Route, Switch, Redirect } from "react-router-dom";
import { createTheme } from "react-data-table-component";
import { myTheme } from "./style/table";
// import axios from "axios";

// const BASE_URL = "https://devas.xyz";

import Login from "./Component/user/Login";
import Logout from "./Component/user/Logout";
import Home from "./Component/Home";
import Error from "./Component/Error";
import DashboardAdmin from "./Component/DashboardAdmin";
import OfferAdmin from "./Component/OfferAdmin";
import OfferDetailAdmin from "./Component/OfferDetailAdmin";
import CreateOfferAdmin from "./Component/CreateOfferAdmin";
import EditOfferAdmin from "./Component/EditOfferAdmin";
import UserPermissionsAdmin from "./Component/UserPermissionsAdmin";
import InvoiceAdmin from "./Component/InvoiceAdmin";
import NotFound from "./Component/NotFound";
import ManageUsersAdmin from "./Component/ManageUsersAdmin";
import OfferTemplateAdmin from "./Component/OfferTemplateAdmin";
import CreateTemplateAdmin from "./Component/CreateTemplateAdmin";
import EditTemplateAdmin from "./Component/EditTemplateAdmin";
import UserDetail from "./Component/UserDetail";
import HostedPageAdmin from "./Component/HostedPageAdmin";
import ManageNewPubUserAdmin from "./Component/ManageNewPubUserAdmin";
import ApprovedConversionsStatistics from "./Component/adminbase/ApprovedConversionsStatistics";
import BannerTool from "./Component/adminbase/BannerTool";
import Kpi from "./Component/Kpi";

import WrongVersion from "./Component/WrongVersion";

createTheme("myTheme", myTheme);

class App extends Component {
    componentDidMount() {
        if (localStorage.getItem("token_pub") || localStorage.getItem("token_admin")) {
            if (localStorage.getItem("theme") === "dark") {
                document.body.style = "background-color: #1b2039";
            } else {
                document.body.style = "background-color: #f9f9f9";
            }
            if (parseInt(Date.now() / 1000) - localStorage.getItem("createdDate") > 172700) {
                localStorage.clear();
                this.props.history.push("/?msg=Your+token+has+expired.+Please+login+again.");
            } else {
                this.loginTimeout();
            }

            // axios
            //     .get(`${BASE_URL}/api/v1.0/users/profile`, {
            //         headers: {
            //             "Content-Type": "application/json",
            //             Authorization: "JWT " + localStorage.getItem("token")
            //         }
            //     })
            //     .then(response => {
            //         this.loginTimeout();
            //     })
            //     .catch(error => {
            //         localStorage.clear();
            //         this.props.history.push("/?msg=Your+token+has+expired.+Please+login+again.");
            //     });
        }
    }

    loginTimeout = () => {
        var time = 172800 - (parseInt(Date.now() / 1000) - localStorage.getItem("createdDate"));
        this.loginCurrentTimeout = setTimeout(() => {
            localStorage.clear();
            this.props.history.push("/?msg=Your+token+has+expired.+Please+login+again.");
        }, time * 1000);
        if (localStorage.getItem("rememberMe") === "false") {
            window.addEventListener("beforeunload", () => {
                localStorage.clear();
            });
        }
    };

    componentWillUnmount() {
        clearTimeout(this.loginCurrentTimeout);
    }

    render() {
        return (
            <Switch>
                <Route
                    exact
                    path="/login"
                    render={(routeProps) => (
                        <Login {...routeProps} key={Date.now()} loginTimeout={this.loginTimeout} isAdmin={true} />
                    )}
                />
                <Route exact path="/logout" render={(routeProps) => <Logout {...routeProps} key={Date.now()} />} />
                <Route exact path="/dashboard" render={(routeProps) => <DashboardAdmin {...routeProps} key={Date.now()} />} />
                <Route exact path="/offers" render={(routeProps) => <OfferAdmin {...routeProps} key={Date.now()} />} />
                <Route exact path="/offers/new" render={(routeProps) => <CreateOfferAdmin {...routeProps} key={Date.now()} />} />
                <Route
                    exact
                    path="/offers/templates/new"
                    render={(routeProps) => <CreateTemplateAdmin {...routeProps} key={Date.now()} />}
                />
                <Route
                    exact
                    path="/offers/templates/:templateId/edit"
                    render={(routeProps) => <EditTemplateAdmin {...routeProps} key={Date.now()} />}
                />
                <Route
                    exact
                    path="/offers/templates"
                    render={(routeProps) => <OfferTemplateAdmin {...routeProps} key={Date.now()} />}
                />
                <Route
                    exact
                    path="/offers/:alias/edit"
                    render={(routeProps) => <EditOfferAdmin {...routeProps} key={Date.now()} />}
                />
                <Route
                    exact
                    path="/offers/:alias"
                    render={(routeProps) => <OfferDetailAdmin {...routeProps} key={Date.now()} />}
                />
                <Route exact path="/pages" render={(routeProps) => <HostedPageAdmin {...routeProps} key={Date.now()} />} />
                <Route
                    exact
                    path="/users/pub/new-register"
                    render={(routeProps) => <ManageNewPubUserAdmin {...routeProps} key={Date.now()} />}
                />
                <Route exact path="/users/:id/detail" render={(routeProps) => <UserDetail {...routeProps} key={Date.now()} />} />
                <Route
                    exact
                    path="/users/permissions"
                    render={(routeProps) => <UserPermissionsAdmin {...routeProps} key={Date.now()} />}
                />
                <Route exact path="/users" render={(routeProps) => <ManageUsersAdmin {...routeProps} key={Date.now()} />} />
                <Route exact path="/invoices" render={(routeProps) => <InvoiceAdmin {...routeProps} key={Date.now()} />} />
                <Route exact path="/" render={(routeProps) => <Home {...routeProps} key={Date.now()} />} />
                <Route exact path="/wrong-version" render={(routeProps) => <WrongVersion {...routeProps} key={Date.now()} />} />
                <Route
                    exact
                    path="/approved_conversions_statistic"
                    render={(routeProps) => <ApprovedConversionsStatistics {...routeProps} key={Date.now()} />}
                />
                <Route exact path="/banner_tool" render={(routeProps) => <BannerTool {...routeProps} key={Date.now()} />} />
                <Route exact path="/kpi" render={(routeProps) => <Kpi {...routeProps} key={Date.now()} />} />
                <Route exact path="/404" render={(routeProps) => <NotFound {...routeProps} key={Date.now()} />} />
                <Route exact path="/error" render={(routeProps) => <Error {...routeProps} key={Date.now()} />} />
                <Redirect to="/404" />
            </Switch>
        );
    }
}

export default withRouter(App);
