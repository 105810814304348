import axios from "axios";
import React, { Component } from "react";
import KpiGoalList from "./adminbase/kpi/KpiGoalList";
import KpiList from "./adminbase/kpi/KpiList";
import KpiStatistics from "./adminbase/kpi/KpiStatistics";
import MissingKpiGoal from "./adminbase/kpi/MisingKpiGoal";
import MissingKpi from "./adminbase/kpi/MissingKpi";
import MainAdmin from "./adminbase/MainAdmin";

export default class Kpi extends Component {
    state = {
        offer: [],
        bizdev: [],
        publisher: [],
        kpiListKey: Date.now(),
        kpiGoalListKey: Date.now() + 10,
    };
    componentDidMount() {
        this.signal = axios.CancelToken.source();
        this.getOfferList();
        this.getPublisher();
        this.getBizdev();
    }
    componentWillUnmount() {
        this.signal.cancel("Api is being canceled");
    }
    getOfferList = () => {
        axios
            .get(`${process.env.REACT_APP_ADMIN_URL}/offers`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            })
            .then((response) => {
                var offer = response.data.reverse().map((v) => {
                    return { value: v._id, label: v.alias };
                });
                this.setState({ offer });
            })
            .catch((error) => {
                console.log(error);
            });
    };
    getPublisher = () => {
        axios
            .get(`${process.env.REACT_APP_ADMIN_URL}/users/pub`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            })
            .then((response) => {
                let publisher = response.data.reverse().map((v) => {
                    return { value: v._id, label: v.username };
                });
                this.setState({ publisher });
            })
            .catch((error) => {
                console.log(error);
            });
    };
    getBizdev = () => {
        axios
            .get(`${process.env.REACT_APP_ADMIN_URL}/users/bizdev`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            })
            .then((response) => {
                let bizdev = response.data.reverse().map((v) => {
                    return { value: v._id, label: v.username };
                });
                this.setState({ bizdev });
            })
            .catch((error) => {
                console.log(error);
            });
    };
    updateKpiList = () => {
        this.setState({ kpiListKey: Date.now() });
    };
    updateKpiGoalList = () => {
        this.setState({ kpiGoalListKey: Date.now() });
    };
    render() {
        return (
            <MainAdmin history={this.props.history} linkTo={["/kpi"]} linkName={["Bizdev KPI"]} selectedSection={9}>
                <div className="col-9"></div>
                <KpiStatistics />
                <MissingKpi
                    offerList={this.state.offer}
                    bizdevList={this.state.bizdev}
                    publisherList={this.state.publisher}
                    updateKpiList={this.updateKpiList}
                />
                <MissingKpiGoal bizdevList={this.state.bizdev} updateKpiGoalList={this.updateKpiGoalList} />
                <KpiList key={this.state.kpiListKey} />
                <KpiGoalList key={this.state.kpiGoalListKey} />
            </MainAdmin>
        );
    }
}
