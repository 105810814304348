import React, { Component } from "react";
import axios from "axios";
import TemplateForm from "./adminbase/TemplateForm";

export default class EditTemplateAdmin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            offers: [],
            validMsg: "",
            invalidMsg: "",
            templateName: "",
        };
    }
    componentDidMount() {
        this.getOfferTemplate();
    }
    componentWillUnmount() {
        this.signal.cancel("Api is being canceled");
    }
    getOfferTemplate = () => {
        this.signal = axios.CancelToken.source();
        axios
            .get(`${process.env.REACT_APP_ADMIN_URL}/templates/${this.props.match.params.templateId}`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            })
            .then((response) => {
                let template = response.data;
                if (!template.hasOwnProperty("name")) {
                    this.props.history.push("/offers/templates");
                } else {
                    this.setState({
                        templateName: template.name,
                        offers: template.pubPayout.map((v) => {
                            return { label: v.offer.alias, value: v.offer._id, payoutPub: v.price + "" };
                        }),
                    });
                }
            })
            .catch((error) => {
                this.props.history.push("/offers/templates");
            });
    };
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };
    handleOfferChange = (offers) => {
        this.setState({ offers, validMsg: "", invalidMsg: "" }, () => {
            this.setState((state, props) => {
                let offers = state.offers.map((value) => {
                    let v = { ...value };
                    if (!v.hasOwnProperty("payoutPub")) {
                        v.payoutPub = "";
                    }
                    return v;
                });
                return { offers };
            });
        });
    };
    handleChangeOfferPayoutPub = (v, i) => {
        this.setState((state, props) => {
            return {
                offers: state.offers.map((value, index) => {
                    if (index === i) {
                        value.payoutPub = v;
                    }
                    return value;
                }),
                validMsg: "",
                invalidMsg: "",
            };
        });
    };
    handleChangeName = (name, value) => {
        this.setState({ validMsg: "", invalidMsg: "", [name]: value });
    };
    submitAction = () => {
        let body = {
            name: this.state.templateName,
            pubPayout: this.state.offers.map((v) => {
                return { offer: v.value, price: v.payoutPub.toString().trim() };
            }),
        };
        axios
            .put(`${process.env.REACT_APP_ADMIN_URL}/templates/${this.props.match.params.templateId}`, body, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            })
            .then((response) => {
                let data = response.data;
                this.setState({ validMsg: `${this.state.templateName} has been edited successfully!` });
            })
            .catch((error) => {
                this.setState({ invalidMsg: error.response.data.message });
                //this.props.history.push("/error?message=" + error.message);
            });
    };
    render() {
        return (
            <TemplateForm
                isNew={false}
                history={this.props.history}
                match={this.props.match}
                handleOfferChange={this.handleOfferChange}
                validMsg={this.state.validMsg}
                invalidMsg={this.state.invalidMsg}
                handleChangeOfferPayoutPub={this.handleChangeOfferPayoutPub}
                offers={this.state.offers}
                templateName={this.state.templateName}
                handleChangeName={this.handleChangeName}
                handleChange={this.handleChange}
                submitAction={this.submitAction}
            />
        );
    }
}
