import React, {Component} from "react";
import CanvasJS from "../../helper/canvasjs/canvasjs.react";
import "../../style/dashboard.css";

const CanvasJSChart = CanvasJS.CanvasJSChart;
CanvasJS.CanvasJS.addColorSet("devas", [
    //colorSet Array
    "#696969",
    "#bada55",
    "#7fe5f0",
    "#ff0000",
    "#ff80ed",
    "#407294",
    "#cbcba9",
    "#420420",
    "#133337",
    "#065535",
    "#c0c0c0",
    "#5ac18e",
    "#f7347a",
    "#ffc0cb",
    "#ffe4e1",
    "#008080",
    "#ffd700",
    "#ffa500",
    "#00ffff",
    "#ff7373",
    "#40e0d0",
    "#0000ff",
    "#d3ffce",
    "#b0e0e6",
    "#003366",
    "#faebd7",
    "#fa8072",
    "#ffff00",
    "#7fffd4",
    "#ffb6c1",
    "#800000",
    "#f08080",
    "#800080",
    "#00ff00",
    "#c39797",
    "#fff68f",
    "#ffc3a0",
    "#cccccc",
    "#20b2aa",
    "#66cdaa",
    "#ff6666",
    "#ff00ff",
    "#4ca3dd",
    "#ffdab9",
    "#ff7f50",
    "#468499",
    "#c0d6e4",
    "#f6546a",
    "#afeeee",
    "#008000",
    "#00ced1",
    "#660066",
    "#b6fcd5",
    "#cbbeb5",
    "#088da5",
    "#0e2f44",
    "#990000",
    "#b4eeb4",
    "#daa520",
    "#808080",
    "#8b0000",
    "#6897bb",
    "#ffff66",
    "#000080",
    "#81d8d0",
    "#ff4040",
    "#ccff00",
]);
export default class ApprovedStatisticsChart extends Component {
    render() {
        let data = [];
        if (Object.entries(this.props.chartData).length > 0) {
            for (const [key, value] of Object.entries(this.props.chartData)) {
                data.push({
                    type: "line",
                    axisYType: "secondary",
                    name: key,
                    showInLegend: false,
                    dataPoints: value[0],
                    yValueFormatString: "#0.##0'%'"
                })
            }
        }
        const options = {
            backgroundColor: localStorage.getItem("theme") === "dark" ? "#262B4B" : "#ffffff",
            responsive: true,
            colorSet: "devas",
            theme: localStorage.getItem("theme") === "dark" ? "dark1" : "light1",
            animationEnabled: true,
            exportEnabled: true,
            title: {
                text: "Approved Conversions Statistics Chart"
            },
            axisX: {
                gridThickness: 0,
                valueFormatString: "DD MMM",
                crosshair: {
                    enabled: true,
                    snapToDataPoint: true
                },
                labelPlacement:"inside"
            },
            axisY2: {
                title: "Rate (%)",
                gridThickness: 0,
                crosshair: {
                    enabled: true,
                    snapToDataPoint: true
                }
            },
            toolTip: {
                shared: true,
            },
            legend: {
                cursor: "pointer",
                verticalAlign: "bottom",
                horizontalAlign: "center",
                dockInsidePlotArea: false
            },
            data: data
        }
        return (
            <div className="mt-4">
                <div className="py-3 bg-menu mb-1">
                    <div className="px-4">
                        <h2 className="menu-h2-title">Chart</h2>
                    </div>
                    <div className="px-3 px-lg-4 px-xl-5 pt-2 pb-0 pb-lg-1 chartContainer">
                        {Object.entries(this.props.chartData).length > 0 ? (<CanvasJSChart
                            options={options}
                            onRef={(ref) => (this.chart = ref)}
                            containerProps={{width: "100%", position: "relative", height: "100%"}}
                        />) : <h1 className="text-center">Nothing To Show</h1>}

                    </div>
                </div>
            </div>
        )
    }
}