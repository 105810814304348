import React, { Component } from "react"
import axios from "axios"
import "./adminbase/OfferForm"
import OfferForm from "./adminbase/OfferForm"

export default class CreateOfferAdmin extends Component {
    constructor(props) {
        super(props)

        this.state = {
            name: "",
            alias: "",
            category: "",
            price: "",
            payoutPub: "",
            income: "",
            geo: "",
            imgLink: "",
            status: "",
            approved_conversion_change_duration: "",
            networkId: "",
            networkName: "",
            details: "",
            resourcesLink: "",
            validMsg: "",
            invalidMsg: "",
            adv: "",
            isCombo: false,
            combosList: [],
            numberOfCombos: 0,
            currency: "",
            systemCurrency: "",
            extraFee: "0",
            useDeliveryStatus: { label: "No", value: false },
            isNewProcess: {label: "No", value: false},
            useTelegramNoti: {label: "Yes", value: true},
        }
    }

    handleChange = (e) => {
        this.setState({ validMsg: "", invalidMsg: "", [e.target.name]: e.target.value })
    }
    handleChangeName = (name, value) => {
        this.setState({ validMsg: "", invalidMsg: "", [name]: value })
    }
    createOffer = () => {
        let {
            name,
            alias,
            category,
            price,
            payoutPub,
            income,
            geo,
            imgLink,
            status,
            approved_conversion_change_duration,
            networkId,
            networkName,
            resourcesLink,
            details,
            adv,
            combosList,
            isCombo,
            currency,
            systemCurrency,
            extraFee,
            useDeliveryStatus,
            isNewProcess,
            useTelegramNoti,
        } = this.state
        let comboChoices = combosList
        axios
            .post(
                `${process.env.REACT_APP_ADMIN_URL}/offers`,
                {
                    name,
                    alias,
                    category: category.value,
                    adv: adv.value,
                    status: status.value,
                    price,
                    payoutPub,
                    income,
                    geo,
                    imgLink,
                    approved_conversion_change_duration,
                    network: {
                        id: networkId,
                        name: networkName
                    },
                    resourcesLink,
                    details,
                    isCombo,
                    comboChoices,
                    currency,
                    systemCurrency,
                    extraFee,
                    useDeliveryStatus: useDeliveryStatus.value,
                    isNewProcess: isNewProcess.value,
                    useTelegramNoti: useTelegramNoti.value
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "JWT " + localStorage.getItem("token_admin")
                    }
                }
            )
            .then((response) => {
                let data = response.data
                this.setState({ validMsg: `Offer ${data.alias} has been created successfully!` })
            })
            .catch((error) => {
                this.setState({ invalidMsg: error.response.data.message })
                //this.props.history.push("/error?message=" + error.message);
            })
    }
    handleIsComboStatusChanged = (isCombo) => {
        if (isCombo) {
            this.setState({ isCombo, payoutPub: "0", combosList: [], numberOfCombos: 0, income: "0", price: "0" })
        } else
            this.setState({
                isCombo,
                payoutPub: "",
                combosList: [],
                numberOfCombos: 0,
                income: "",
                price: "",
                validMsg: "",
                invalidMsg: ""
            })
    }
    handleComboDetailChange = (index, key, value) => {
        let combosList = [...this.state.combosList]
        if (!combosList[index]) {
            combosList[index] = {}
        }
        combosList[index][key] = value
        this.setState({ combosList, validMsg: "", invalidMsg: "" })
    }
    handleNumberOfComboChanged = (number) => {
        let newComboList = [...this.state.combosList]
        if (newComboList.length > number) {
            newComboList.splice(number, newComboList.length - 1)
        }
        this.setState({ combosList: newComboList, numberOfCombos: number, validMsg: "", invalidMsg: "" })
    }

    render() {
        let {
            name,
            alias,
            category,
            price,
            payoutPub,
            income,
            geo,
            imgLink,
            status,
            approved_conversion_change_duration,
            networkId,
            networkName,
            adv,
            invalidMsg,
            validMsg,
            resourcesLink,
            details,
            isCombo,
            combosList,
            extraFee,
            useDeliveryStatus,
            isNewProcess,
            useTelegramNoti,
        } = this.state
        return (
            <OfferForm
                match={this.props.match}
                history={this.props.history}
                isNewOffer={true}
                name={name}
                alias={alias}
                category={category}
                payoutPub={payoutPub}
                income={income}
                geo={geo}
                imgLink={imgLink}
                status={status}
                approved_conversion_change_duration={approved_conversion_change_duration}
                networkId={networkId}
                networkName={networkName}
                resourcesLink={resourcesLink}
                details={details}
                price={price}
                adv={adv}
                handleChange={this.handleChange}
                handleChangeName={this.handleChangeName}
                invalidMsg={invalidMsg}
                validMsg={validMsg}
                submitAction={this.createOffer}
                isCombo={isCombo}
                changeIsComboStatus={this.handleIsComboStatusChanged}
                combosList={combosList}
                onComboDetailChange={this.handleComboDetailChange}
                numberOfComboOnChange={this.handleNumberOfComboChanged}
                numberOfCombos={this.state.numberOfCombos}
                disableChangeIsCombo={false}
                currency={this.state.currency}
                systemCurrency={this.state.systemCurrency}
                extraFee={this.state.extraFee}
                useDeliveryStatus={useDeliveryStatus}
                isNewProcess={isNewProcess}
                useTelegramNoti={useTelegramNoti}
            />
        )
    }
}
