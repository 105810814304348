import React, {Component} from "react";
import CanvasJS from "../../helper/canvasjs/canvasjs.react";
import "../../style/dashboard.css";
import {moneyFormat} from "../../helper/helper";


const CanvasJSChart = CanvasJS.CanvasJSChart;
CanvasJS.CanvasJS.addColorSet("devas", [
    //colorSet Array
    "#ff8f39",
    "#ee1280",
    "#e52d27",
    "#ab4199",
    "#2f80ed",
    "#27ae60",
    "#ff0000",
    "#420420",
    "#0000ff",
    "#ffff00",
    "#800000",
    "#660066",
    "#990000",
    "#000080",
    "#8b0000",

]);

export default class MarginByOffersStatistics extends Component {

    render() {
        const options = {
            backgroundColor: localStorage.getItem("theme") === "dark" ? "#262B4B" : "#ffffff",
            responsive: true,
            colorSet: "devas",
            theme: localStorage.getItem("theme") === "dark" ? "dark1" : "light1",
            animationEnabled: true,
            exportEnabled: true,
            title: {
                text: "",
            },
            axisX: {
                valueFormatString: "DD MMM",
            },
            axisY: {
                title: "",
            },
            toolTip: {
                shared: true,
                contentFormatter: (e) => {
                    return "Offer: " + e.entries[0].dataPoint.indexLabel + "<br>" + "Percentage: " + e.entries[0].dataPoint.y.toFixed(2) + "%<br>" + "Margin: " + moneyFormat(parseFloat(e.entries[0].dataPoint.thisOfferMargin), 0, 3) + "₫";
                }
            },
            legend: {
                verticalAlign: "top",
                horizontalAlign: "center",
                reversed: true,
                cursor: "pointer",
            },
            data: [
                {
                    yValueFormatString: "##0.00\"%\"",
                    type: "pie",
                    dataPoints: this.props.synthesizedConversions,
                },
            ],
        };
        return (
            <div className="col-12 col-md-6 Statistics mb-3">
                <div className="py-3 bg-menu">
                    <div className="px-4 d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                            <h2 className="menu-h2-title mb-0">Margin By Offers Statistics</h2>
                        </div>
                    </div>
                    <div className="px-4 pt-3">
                        <CanvasJSChart
                            options={options}
                            /* onRef = {ref => this.chart = ref} */
                        />
                        <div className="block-trial"></div>
                    </div>
                </div>
            </div>
        )
    }
}