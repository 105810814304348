import React, { Component } from "react";
import moment from "moment";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import Select from "react-select";
import { selectTheme } from "../../../style/reactselect";

import "../../../style/modal.css";
import "../../../style/dropdown.css";
import "../../../style/daterangepicker.css";

export default class SubmitRejectedConversion extends Component {
    constructor(props) {
        super(props);

        this.state = {
            date: null,
            offerList: [],
            offer: { label: "Select offer...", value: "" },
            newOffer: { label: "Select offer...", value: "" },
            isLoading: true,
            conversions: [],
        };
    }
    componentDidMount() {
        this.getOfferList();
    }
    getOfferList = () => {
        this.signal = axios.CancelToken.source();
        axios
            .get(`${process.env.REACT_APP_ADMIN_URL}/offers`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            })
            .then((response) => {
                var offerList = response.data.reverse().map((v) => {
                    return { value: v._id, label: v.alias };
                });
                this.setState({ offerList });
            })
            .catch((error) => {
                console.log(error);
            });
    };
    getRejectedConversionList = () => {
        this.signal = axios.CancelToken.source();
        if (this.state.offer.value == "" || !this.state.date) {
            return alert("Please fill in all input!");
        }
        let from_date = moment(this.state.date[0]).startOf("date").unix() * 1000;
        let to_date = moment(this.state.date[1]).endOf("date").unix() * 1000 + 999;
        axios
            .get(
                `${process.env.REACT_APP_ADMIN_URL}/conversions/customer_reject/offer/${this.state.offer.value}?from_date=${from_date}&to_date=${to_date}`,
                {
                    cancelToken: this.signal.token,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "JWT " + localStorage.getItem("token_admin"),
                    },
                }
            )
            .then((response) => {
                let data = response.data;
                this.setState({ conversions: data, isLoading: false });
            })
            .catch((error) => {
                //this.props.history.push("/error?message=" + error.message);
            });
    };
    handleDateOnChange = (date) => {
        this.setState({ date, isLoading: true });
    };
    handleModalClose = () => {
        this.setState({
            date: null,
            adv: { label: "Select adv...", value: "" },
            offer: { label: "Select offer...", value: "" },
            conversions: [],
            isLoading: true,
        });
        this.props.handleModalClose();
    };
    handleChangeOffer = (offer) => {
        this.setState({ offer, isLoading: true });
    };
    handleChangeNewOffer = (newOffer) => {
        this.setState({ newOffer });
    };
    submit = () => {
        if (this.state.conversions.length <= 0 || this.state.newOffer.value === "" || !this.state.newOffer) {
            alert("Please fill in all fields!");
        } else {
            let conversions = this.state.conversions.map((v) => {
                let data = {
                    name: v.name,
                    phone: v.phone,
                    address: v.address,
                    message: v.message,
                    aff_sub1: v.aff_sub1,
                    aff_sub5: "Re-submit rejected conversions",
                    offer: this.state.newOffer.value,
                    publisher: "5efab11066458336ce3dec49",
                };
                return data;
            });
            axios
                .post(
                    `https://conversion.click/conversions/list`,
                    {
                        conversions,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                )
                .then((response) => {
                    alert("Submit Completed!");
                    this.handleModalClose();
                })
                .catch((error) => {
                    alert("Something went Wrong! " + error.response.data.message);
                });
        }
    };
    render() {
        return (
            <Modal
                show={this.props.showModal}
                onHide={this.handleModalClose}
                className={`${localStorage.getItem("theme") === "light" ? "light-theme" : "dark-theme"} myModal`}
                centered
            >
                <Modal.Header closeButton>
                    <h2 className="menu-h2-title modal-title">Re-submit Rejected Conversions</h2>
                </Modal.Header>
                <Modal.Body>
                    <span className="mr-1">Conversion Created Date:</span>
                    <div className="bg-datepicker">
                        <DateRangePicker value={this.state.date} onChange={this.handleDateOnChange} />
                    </div>
                    <div className="mt-3">
                        <span className="mr-1">Offer: </span>
                        <Select
                            isMulti={false}
                            options={this.state.offerList}
                            placeholder="Offer Select"
                            className="devas-select"
                            classNamePrefix="devas-select"
                            theme={selectTheme}
                            value={this.state.offer}
                            onChange={this.handleChangeOffer}
                        />
                    </div>
                    <button type="button" className="btn button-devas mt-3" onClick={this.getRejectedConversionList}>
                        Query Selection
                    </button>
                    {!this.state.isLoading && (
                        <>
                            <div className="mt-3">
                                <span className="mr-1">
                                    Rejected conversions for offer {this.state.offer.label}: {this.state.conversions.length}
                                </span>
                            </div>
                            {this.state.conversions.length !== 0 && (
                                <div className="mt-3">
                                    <span className="mr-1">New Offer:</span>
                                    <Select
                                        isMulti={false}
                                        options={this.state.offerList}
                                        placeholder="Adv Select"
                                        className="devas-select"
                                        classNamePrefix="devas-select"
                                        theme={selectTheme}
                                        value={this.state.newOffer}
                                        onChange={this.handleChangeNewOffer}
                                    />
                                </div>
                            )}
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn button-devas-gray" onClick={this.handleModalClose}>
                        Cancel
                    </button>
                    {!this.state.isLoading && this.state.conversions.length !== 0 && (
                        <button type="button" className="btn button-devas" onClick={this.submit}>
                            Submit
                        </button>
                    )}
                </Modal.Footer>
            </Modal>
        );
    }
}
