import React from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

export const ExportExcel = ({ csvData, fileName }) => {
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const exportToCSV = (csvData, fileName) => {
        let csvDataMap = csvData.map((v) => {
            return { ...v, 
                // payoutPub: undefined, payoutAdmin: undefined 
            };
        });
        const ws = XLSX.utils.json_to_sheet(csvDataMap);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };

    return (
        <button className="btn button-devas d-inline-block" onClick={(e) => exportToCSV(csvData, fileName)}>
            Export Data
        </button>
    );
};
