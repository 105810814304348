import React, { Component } from "react";
import axios from "axios";
import Select from "react-select";
import DataTable from "react-data-table-component";
import Modal from "react-bootstrap/Modal";
import GetAppIcon from "@material-ui/icons/GetApp";
import { ExportExcel } from "../../helper/ExportDataToExcel";
import { selectTheme } from "../../style/reactselect";
import { customStyles } from "../../style/table";
import "../../style/dropdown.css";
import "../../style/modal.css";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "../../style/daterangepicker.css";
import moment from "moment";
import InvoicesStatistics from "./InvoicesStatistics";

export default class PubInvoice extends Component {
    constructor(props) {
        super(props);

        this.state = {
            publisherList: [],
            publisher: { value: "", label: "Select Publisher..." },
            pubInvoiceList: [],
            pubInvoices: [],
            isLoading: false,
            editStatus: { value: true, label: "true" },
            real_payout: "",
            invoice_log: "",
            showModal2: false,
            dateType: { label: "Created Date", value: "created_date" },
            date: [moment(new Date("2020-05-15T00:00:00")).startOf("date").unix() * 1000, new Date()],
            status: false,
            totalInvoices: 0,
            totalEstimatePayout: 0,
            totalRealPayout: 0,
            totalPaid: 0,
            allPublishers: [],
        };
    }

    componentDidMount() {
        document.title = "Pub Invoices";
        this.getAllPubUsers();
    }

    componentDidUpdate(preProps, preState) {
        if (this.state.allPublishers.length !== 0 && preState.allPublishers.length === 0) {
            this.getPubInvoiceList(this.state.status);
        }
    }

    componentWillUnmount() {
        this.signal.cancel("Api is being canceled");
    }

    getAllPubUsers = () => {
        this.signal = axios.CancelToken.source();
        axios
            .get(`${process.env.REACT_APP_ADMIN_URL}/users/pub`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            })
            .then((response) => {
                let data = response.data.reverse().map((v) => {
                    return { value: v._id, label: v.username };
                });
                this.setState({
                    publisherList: data,
                    allPublishers: response.data,
                });
            })
            .catch((error) => {
                console.log(error);
                //this.props.history.push("/error?message=" + error.message);
            });
    };
    getPubInvoiceList = (status) => {
        this.setState({ publisher: { value: "", label: "Select Publisher..." }, isLoading: true, status: status });
        this.signal = axios.CancelToken.source();
        let from_date = moment(this.state.date[0]).startOf("date").unix() * 1000;
        let to_date = moment(this.state.date[1]).endOf("date").unix() * 1000 + 999;
        axios
            .get(
                `${process.env.REACT_APP_ADMIN_URL}/pub/invoices/?is_paid=${status}&from_date=${from_date}&to_date=${to_date}&modified_date=${this.state.dateType.value}`,
                {
                    cancelToken: this.signal.token,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "JWT " + localStorage.getItem("token_admin"),
                    },
                }
            )
            .then((response) => {
                var data = response.data.reverse().map((v) => {
                    if (!v.is_paid) {
                        v.edit = (
                            <button
                                className="btn button-devas-medium"
                                onClick={() =>
                                    this.setState({
                                        selectedModalInvoiceId: v._id,
                                        showModal2: true,
                                        real_payout: "",
                                        invoice_log: "",
                                    })
                                }
                            >
                                Edit
                            </button>
                        );
                    }
                    v.is_paid = v.is_paid ? "true" : "false";
                    v.download = (
                        <button className="btn button-devas-medium" onClick={() => this.exportData(v._id, v.name)}>
                            <GetAppIcon />
                        </button>
                    );
                    v.created_date = new Date(v.created_date).toLocaleString();
                    let allPubs = this.state.allPublishers;
                    let pub = allPubs.filter((el) => {
                        return el.username === v.pub.username;
                    });
                    v.pub_info = pub[0];
                    if (!v.pub_info.hasOwnProperty("bank_name")) {
                        v.pub_info.bank_name = "";
                    }
                    if (!v.pub_info.hasOwnProperty("bank_account_number")) {
                        v.pub_info.bank_account_number = "";
                    }
                    if (!v.pub_info.hasOwnProperty("beneficiary_name")) {
                        v.pub_info.beneficiary_name = "";
                    }
                    return v;
                });
                this.calculateSummary(response.data);
                this.setState({ pubInvoiceList: data, pubInvoices: data, isLoading: false });
            })
            .catch((error) => {
                console.log(error);
            });
    };
    calculateSummary = (data) => {
        let totalInvoices = 0;
        let totalEstimatePayout = 0;
        let totalRealPayout = 0;
        let totalPaid = 0;
        data.forEach((e) => {
            totalInvoices++;
            totalEstimatePayout += e.payout ? e.payout : 0;
            totalRealPayout += e.real_payout ? e.real_payout : 0;
            if (e.is_paid && e.is_paid === "true") {
                totalPaid++;
            }
        });
        this.setState({ totalEstimatePayout, totalInvoices, totalRealPayout, totalPaid });
    };
    filterPublisher = () => {
        this.setState((state, props) => {
            return { pubInvoices: state.pubInvoiceList.filter((v) => v.pub._id.includes(state.publisher.value)) };
        });
    };
    exportData = (invoiceId, fileName) => {
        this.signal = axios.CancelToken.source();
        axios
            .get(`${process.env.REACT_APP_ADMIN_URL}/pub/invoices/${invoiceId}`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            })
            .then((response) => {
                ExportExcel(response.data.conversions, fileName);
            })
            .catch((error) => {
                alert("Something went wrong!");
            });
    };
    EditPubInvoice = () => {
        axios
            .put(
                `${process.env.REACT_APP_ADMIN_URL}/pub/invoices/${this.state.selectedModalInvoiceId}`,
                {
                    is_paid: this.state.editStatus.value,
                    real_payout: this.state.real_payout.trim(),
                    invoice_log: this.state.invoice_log,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "JWT " + localStorage.getItem("token_admin"),
                    },
                }
            )
            .then((response) => {
                let data = response.data;
                this.getPubInvoiceList("false");
                this.setState({ showModal2: false }, () =>
                    setTimeout(() => {
                        alert(`Edit Invoice Successfully!`);
                    }, 150)
                );
            })
            .catch((error) => {
                alert("Something went wrong! " + error.response.data.message);
            });
    };
    onChangePublisher = (publisher) => this.setState({ publisher }, () => this.filterPublisher());
    handleModal2Close = () => {
        this.setState({ showModal2: false });
    };
    onChangeEditStatus = (editStatus) => {
        this.setState({ editStatus });
    };
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };
    handleChangeDateType = (dateType) => {
        this.setState({ dateType }, () => this.getPubInvoiceList(this.state.status));
    };
    handleChangeDate = (date) => {
        if (date == null) {
            date = [new Date(), new Date()];
        }
        this.setState({ date }, () => this.getPubInvoiceList(this.state.status));
    };

    render() {
        let {
            publisherList,
            publisher,
            pubInvoices,
            isLoading,
            showModal2,
            editStatus,
            invoice_log,
            real_payout,
            dateType,
        } = this.state;
        return (
            <>
                <div className="col-12">
                    <div className="bg-menu py-3 mb-1">
                        <div className="px-4 d-flex justify-content-between align-items-center pb-3">
                            <div className="d-flex justify-content-start align-items-center">
                                <h2 className="menu-h2-title mb-0 mr-2">Pub Invoices</h2>
                                <button className="btn button-devas mr-2" onClick={() => this.getPubInvoiceList("")}>
                                    All Invoices
                                </button>
                                <button className="btn button-devas mr-2" onClick={() => this.getPubInvoiceList("false")}>
                                    Pending Invoices
                                </button>
                            </div>
                            <div className="dropdown-form w-25">
                                <Select
                                    isMulti={false}
                                    options={[...publisherList, { value: "", label: "Select Publisher..." }].reverse()}
                                    className="devas-select"
                                    classNamePrefix="devas-select"
                                    onChange={this.onChangePublisher}
                                    theme={selectTheme}
                                    value={publisher}
                                />
                            </div>
                        </div>
                        <div className="row px-4">
                            <div className="col-12 col-md-6 pb-3">
                                <div className="dropdown-form">
                                    <Select
                                        isMulti={false}
                                        options={[
                                            { label: "Created Date", value: "false" },
                                            { label: "Modified Date", value: "true" },
                                        ]}
                                        className="devas-select"
                                        classNamePrefix="devas-select"
                                        onChange={this.handleChangeDateType}
                                        theme={selectTheme}
                                        value={dateType}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-6 pb-3">
                                <div className="d-flex align-items-center bg-daterangepicker border-grey">
                                    <DateRangePicker onChange={this.handleChangeDate} value={this.state.date} />
                                </div>
                            </div>
                        </div>
                        <InvoicesStatistics
                            key={new Date()}
                            totalInvoices={this.state.totalInvoices}
                            totalEstimatePayout={this.state.totalEstimatePayout}
                            totalRealPayout={this.state.totalRealPayout}
                            totalPaid={this.state.totalPaid}
                            totalHome={this.state.totalHome}
                        />
                        <div className="px-4 d-flex justify-content-between align-items-center">
                            <DataTable
                                columns={columns}
                                data={pubInvoices}
                                pagination
                                theme="myTheme"
                                className="table"
                                customStyles={customStyles}
                                noHeader={true}
                                progressPending={isLoading}
                                highlightOnHover={true}
                                defaultSortField="order"
                                defaultSortAsc={false}
                                paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 500, 1000]}
                            />
                        </div>
                    </div>
                    <Modal
                        show={showModal2}
                        onHide={this.handleModal2Close}
                        className={`${localStorage.getItem("theme") === "light" ? "light-theme" : "dark-theme"} myModal`}
                        centered
                    >
                        <Modal.Header closeButton>
                            <h2 className="menu-h2-title modal-title">Edit Pub Invoice</h2>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="dropdown-form">
                                <span className="mr-1">Is Paid:</span>
                                <Select
                                    isMulti={false}
                                    name="offerId"
                                    options={[
                                        { value: true, label: "true" },
                                        { value: false, label: "false" },
                                    ]}
                                    className="devas-select d-inline-block w-25"
                                    classNamePrefix="devas-select"
                                    onChange={this.onChangeEditStatus}
                                    theme={selectTheme}
                                    value={editStatus}
                                />
                            </div>
                            <div className="mt-2">
                                <span>Real Payout:</span>
                                <input
                                    name="real_payout"
                                    type="text"
                                    value={real_payout}
                                    className="form-control search-input w-auto d-inline-block"
                                    onChange={this.handleChange}
                                    placeholder="Payout in VND"
                                />
                            </div>
                            <div className="mt-2">
                                <span>Invoice Log:</span>
                                <input
                                    name="invoice_log"
                                    type="text"
                                    value={invoice_log}
                                    className="form-control search-input w-auto d-inline-block"
                                    onChange={this.handleChange}
                                    placeholder="Input Text Here"
                                />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="btn button-devas-gray" onClick={this.handleModal2Close}>
                                Close
                            </button>
                            <button type="button" className="btn button-devas" onClick={this.EditPubInvoice}>
                                Edit
                            </button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </>
        );
    }
}
const columns = [
    {
        name: "",
        selector: "edit",
        wrap: true,
        minWidth: "110px",
    },
    {
        name: "Id",
        selector: "_id",
        wrap: true,
        sortable: true,
    },
    {
        name: "Order",
        selector: "order_id",
        sortable: true,
        wrap: true,
    },
    {
        name: "Name",
        selector: "name",
        wrap: true,
    },
    {
        name: "Is Paid",
        selector: "is_paid",
        sortable: true,
    },
    {
        name: "Conversion Count",
        selector: "conversion_count",
        sortable: true,
    },
    {
        name: "Pub ID",
        selector: "pub.username",
        wrap: true,
    },
    {
        name: "Created Date",
        selector: "created_date",
        wrap: true,
    },
    {
        name: "Bank Name",
        selector: "pub_info.bank_name",
        wrap: true,
    },
    {
        name: "Bank Account Number",
        selector: "pub_info.bank_account_number",
        wrap: true,
    },
    {
        name: "Beneficiary Name",
        selector: "pub_info.beneficiary_name",
        wrap: true,
    },
    {
        name: "Estimate Payout",
        selector: "payout",
        sortable: true,
        wrap: true,
    },
    {
        name: "Real Payout",
        selector: "real_payout",
        sortable: true,
        wrap: true,
    },
    {
        name: "Invoice Log",
        selector: "invoice_log",
        sortable: true,
        wrap: true,
    },
    {
        name: "Download",
        selector: "download",
        wrap: true,
    },
];
