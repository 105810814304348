import React, { Component } from "react"
import { StatisticsTable } from "../../style/statisticsByPubAndOfferTable"
import DataTable from "react-data-table-component"

const columns = [
    {
        name: "Offer",
        selector: "offer",
        sortable: true,
        center: true,
        wrap: true
    },
    {
        name: "Margin",
        selector: "margin",
        sortable: true,
        center: true,
        wrap: true
    },
    {
        name: "Approved",
        selector: "approvedConversions",
        sortable: true,
        center: true,
        wrap: true
    },
    {
        name: "Manager",
        selector: "manager",
        sortable: true,
        center: true,
        wrap: true
    }
]
export default class MarginBizDevOfferChart extends Component {
    render() {
        return (
            <div className="col-12 col-md-6 Statistics">
                <div className="py-3 bg-menu">
                    <div className="px-4 d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                            <h2 className="menu-h2-title mb-0">Margin Bizdev</h2>
                        </div>
                    </div>
                </div>
                <DataTable
                    data={this.props.synthesizedConversions}
                    columns={columns}
                    pagination
                    theme="myTheme"
                    className="table"
                    customStyles={StatisticsTable}
                    noHeader={true}
                    defaultSortField="name"
                    defaultSortAsc={false}
                    paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 500, 1000]}
                />
            </div>
        )
    }
}
