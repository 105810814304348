import Axios from "axios";
import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { customStyles } from "../../../style/table";
import { moneyFormat } from "../../../helper/helper";

const columns = [
    {
        name: "Bizdev",
        selector: "bizdev.username",
        sortable: true,
        wrap: true,
    },
    {
        name: "Goal",
        selector: "goal_formatted",
        sortable: true,
        wrap: true,
    },
    {
        name: "Period",
        selector: "period",
        sortable: true,
        wrap: true,
    },
];

export default class KpiGoalList extends Component {
    state = {
        data: [],
        isLoading: true,
    };
    componentDidMount() {
        this.signal = Axios.CancelToken.source();
        this.getMissingKpi();
    }
    componentWillUnmount() {
        this.signal.cancel("Api is being canceled");
    }
    getMissingKpi = () => {
        Axios.get(`${process.env.REACT_APP_ADMIN_URL}/kpi-goal`, {
            cancelToken: this.signal.token,
            headers: {
                "Content-Type": "application/json",
                Authorization: "JWT " + localStorage.getItem("token_admin"),
            },
        })
            .then((response) => {
                let data = response.data.reverse().map((v) => {
                    v.goal_formatted = moneyFormat(parseFloat(v.goal), 0, 3) + "₫";
                    v.period = v.month + " - " + v.year;
                    return v;
                });
                this.setState({ data, isLoading: false });
            })
            .catch((error) => {
                //this.props.history.push("/error?message=" + error.message);
            });
    };
    render() {
        return (
            <div className="col-12 col-lg-6 Statistics">
                <div className="py-3 bg-menu">
                    <div className="px-4 d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                            <h2 className="menu-h2-title mb-0">All KPI Goal</h2>
                        </div>
                        <div className="d-flex align-items-center">
                        </div>
                    </div>
                </div>
                <DataTable
                    columns={columns}
                    data={this.state.data}
                    pagination
                    theme="myTheme"
                    className="table"
                    customStyles={customStyles}
                    noHeader={true}
                    progressPending={this.state.isLoading}
                    highlightOnHover={true}
                    defaultSortField="order"
                    defaultSortAsc={false}
                    paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 500, 1000]}
                />
            </div>
        );
    }
}
