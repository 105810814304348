import React, { Component } from "react";
import { Link } from "react-router-dom";

import "../style/homepage.css";
import devaslogo from "../img/devaslogo.svg";
export default class Home extends Component {
    componentDidMount() {
        this.props.history.push("/login");
    }
    render() {
        return <div></div>;
    }
}
