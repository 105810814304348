import React, { Component } from "react";
import Select from "react-select";
import axios from "axios";
import Modal from "react-bootstrap/Modal";

import { selectTheme } from "../style/reactselect";
import { customStyles } from "../style/table";

import MainAdmin from "./adminbase/MainAdmin";
import "../style/dropdown.css";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

const columns = [
    {
        name: "Page Id",
        selector: "_id",
        sortable: true,
        wrap: true,
    },
    {
        name: "Page Name",
        selector: "name",
        sortable: true,
        wrap: true,
    },
    {
        name: "Is Private",
        selector: "isPrivate",
        sortable: true,
        wrap: true,
    },
    {
        name: "Is Active",
        selector: "status",
        sortable: true,
        wrap: true,
    },
    {
        name: "Upload Source",
        selector: "uploadSource",
        sortable: true,
        wrap: true,
    },
    {
        name: "Edit",
        selector: "edit",
    }
];

const values = {
    type: {
        preland: {
            value: "preland", label: "Prelanding",
        },
        landing: {
            value: "landing", label: "Landing",
        },
    },
    status: {
        true: {
            value: true, label: "Active",
        },
        false: {
            value: false, label: "Inactive",
        },
    },
    isPrivate: {
        false: {
            value: false, label: "No",
        },
        true: {
            value: true, label: "Yes",
        },
    },
    uploadSource: {
        regular: {
            value: "regular", label: "Regular HTML",
        },
        ladipage: {
            value: "ladipage", label: "Ladipage",
        },
    },
};

export default class HostedPageAdmin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            showEditModal: false,
            offer: { value: "", label: "Select an offer..." },
            offerList: [],
            allPageList: [],
            prelandingList: [],
            landingList: [],
            action: "", // create or edit
            _id: "",
            name: "",
            type: "preland",
            status: true,
            isPrivate: false,
            uploadSource: "regular",
            file: undefined,
            fileKey: undefined,
        };
    }

    componentDidMount() {
        document.title = "Manage Pages";
        this.getAllOffers();
        this.getAllPages();
    }

    componentWillUnmount() {
        this.signal.cancel("Api is being canceled");
    }

    getAllOffers = () => {
        this.signal = axios.CancelToken.source();
        axios
            .get(`${process.env.REACT_APP_ADMIN_URL}/offers`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            })
            .then((response) => {
                this.setState({
                    offerList: response.data.map((v) => {
                        return { label: v.alias, value: v._id };
                    }),
                });
            })
            .catch((error) => {
                let response = error;
                if (error.response?.data) {
                    response = error.response.data.message;
                }
                this.setState({ invalidMsg: response }, () => {
                    setTimeout(() => {
                        this.setState({ invalidMsg: "" });
                    }, 1500);
                });
            });
    };

    getAllPages = () => {
        this.setState({ allPageList: [] });
        this.signal = axios.CancelToken.source();
        axios
            .get(`${process.env.REACT_APP_ADMIN_URL}/pages/`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            })
            .then((response) => {
                this.setState(
                    {
                        allPageList: response.data.map((v) => {
                            const { _id, offer, name, type, status, isPrivate, uploadSource } = v;
                            let edit = <FontAwesomeIcon
                                icon={faEdit}
                                size="lg"
                                className="hover-primary"
                                onClick={()=> this.setState({
                                    showEditModal: true, 
                                    action: "edit",
                                    _id,
                                    name,
                                    type,
                                    status,
                                    isPrivate,
                                    uploadSource,
                                })}
                            />
                            return { 
                                _id,
                                offer,
                                name,
                                type: values.type[type].label,
                                status: values.status[status].label,
                                isPrivate: values.isPrivate[isPrivate].label,
                                uploadSource: values.uploadSource[uploadSource].label,
                                edit,
                            };
                        }),
                    }, () => {
                        if (this.state.offer.value !== "") {
                            this.getPagesByOffer(this.state.offer.value);
                        }
                    }
                );
            })
            .catch((error) => {
                let response = error;
                if (error.response?.data) {
                    response = error.response.data.message;
                }
                this.setState({ invalidMsg: response }, () => {
                    setTimeout(() => {
                        this.setState({ invalidMsg: "" });
                    }, 1500);
                });
            });
    };

    getPagesByOffer = (e) => {
        this.setState({
            prelandingList: this.state.allPageList.filter(
                (v) => v.offer === e && v.type === "Prelanding"
            ),
            landingList: this.state.allPageList.filter(
                (v) => v.offer === e && v.type === "Landing"
            ),
            isLoading: false,
        });
    };

    handleChangeSelectOffer = (e) => {
        this.setState({ offer: e }, () => {
            this.getPagesByOffer(e.value);
        });
    };

    resetData = () => {
        this.setState({
            name: "",
            type: "preland",
            status: true,
            isPrivate: false,
            uploadSource: "regular",
            file: undefined,
            fileKey: undefined,
        });
    }

    editPageModal = {
        typeList: Object.values(values.type),
        statusList: Object.values(values.status),
        isPrivateList: Object.values(values.isPrivate),
        uploadSourceList: Object.values(values.uploadSource),

        onEntryChange: (e, evOpt) => {
            if(e.target?.name) {
                this.setState({ [e.target.name]: e.target.value });
            } else if(evOpt?.name) {
                this.setState({[evOpt.name]: e.value});
            }
        },

        onFileChange: (e) => {
            this.setState({ file: e.target.files[0] });
        },

        onClearFile: (e) => {
            this.setState({ fileKey: Date.now() });
        },

        onConfirm: () => {
            const {
                action, 
                _id,
                name,
                offer,
                type,
                status,
                isPrivate,
                uploadSource,
                file,
            } = this.state;

            let okMessage = "Page Created!";
            let errMessage = "Page not created, please try again later.";
            if(action === "edit") {
                okMessage = "Page Edited!";
                errMessage = "Page not edited, please try again later.";
            }

            if (!this.state.name) return alert("Please add a name for this...");
            const data = {
                name,
                offer: offer.value,
                type,
                status,
                isPrivate,
                uploadSource,
            };

            axios({
                url: `${process.env.REACT_APP_ADMIN_URL}/pages/${action === "edit" ? `${_id}` : ""}`,
                method: action === "create" ? "POST" : "PUT",
                data,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            }).then((res) => {
                alert(okMessage);
                this.getAllPages();
            }).catch((e) => {
                alert(errMessage);
                this.setState({ invalidMsg: JSON.stringify(e.response.data.message) });
            });

            if (uploadSource === "ladipage" && file?.name) {
                const fileUpload = new FormData();

                fileUpload.append(
                    "page",
                    file,
                    file.name,
                );

                axios({
                    method: "POST",
                    url: `${process.env.REACT_APP_CLICK_URL}/upload/ladipage/${_id}`,
                    data: fileUpload,
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: "JWT " + localStorage.getItem("token_admin"),
                    }
                }).catch((e) => {
                    console.log(JSON.stringify(e.response.data.message));
                });
            }

            this.setState({
                showEditModal: false,
            });
            this.resetData();
        },

        onClose: () => {
            this.setState({
                showEditModal: false,
            });
            this.resetData();
        },
    };

    render() {
        const { action, offer, offerList, prelandingList, landingList, _id, name, type, status, isPrivate, uploadSource, file, fileKey } = this.state;
        const { editPageModal } = this;
        return (
            <MainAdmin history={this.props.history} linkTo={["/pages"]} linkName={["Pages"]} selectedSection={6}>
                <div className="col-6 col-lg-4 py-3">
                    <Select
                        name="offerId"
                        options={offerList}
                        className="devas-select"
                        classNamePrefix="devas-select"
                        onChange={this.handleChangeSelectOffer}
                        theme={selectTheme}
                        placeholder="Select an offer..."
                        value={offer}
                    />
                </div>
                {this.state.offer.value !== "" && (
                    <div className="col-12">
                        <div className="py-3 bg-menu mb-1">
                            <div className="px-4 d-flex justify-content-between mb-2">
                                <h2 className="menu-h2-title">{`Manage pages for offer ${offer.label}`}</h2>
                                <button
                                    className="btn button-devas d-inline-block"
                                    onClick={() => this.setState({ showEditModal: true, action: "create" })}
                                >
                                    Create A New Page
                                </button>
                            </div>

                            <div className="px-4 mt-3 d-flex justify-content-between mb-2">
                                <h2 className="menu-h2-title">Prelanding Pages</h2>
                            </div>

                            <div className="">
                                <DataTable
                                    columns={columns}
                                    data={prelandingList}
                                    pagination
                                    theme="myTheme"
                                    className="table"
                                    customStyles={customStyles}
                                    noHeader={true}
                                    progressPending={this.state.isLoading}
                                    highlightOnHover={true}
                                    paginationRowsPerPageOptions={[10, 20, 50]}
                                />
                            </div>

                            <div className="px-4 d-flex justify-content-between mb-2">
                                <h2 className="menu-h2-title">Landing Pages</h2>
                            </div>

                            <div className="">
                                <DataTable
                                    columns={columns}
                                    data={landingList}
                                    pagination
                                    theme="myTheme"
                                    className="table"
                                    customStyles={customStyles}
                                    noHeader={true}
                                    progressPending={this.state.isLoading}
                                    highlightOnHover={true}
                                    paginationRowsPerPageOptions={[10, 20, 50]}
                                />
                            </div>
                        </div>
                    </div>
                )}
                <Modal
                    show={this.state.showEditModal}
                    onHide={editPageModal.onClose}
                    className={`${localStorage.getItem("theme") === "light" ? "light-theme" : "dark-theme"} myModal`}
                    centered
                >
                    <Modal.Header closeButton>
                        <h2 className="menu-h2-title modal-title">
                            {action==="create" ? "Create A New Page" : `Edit Page ${_id}`}
                        </h2>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <span>Page Name*:</span>
                            <input
                                name="name"
                                type="text"
                                value={name}
                                className="form-control search-input w-auto d-inline-block"
                                onChange={editPageModal.onEntryChange}
                                placeholder="Name"
                            />
                        </div>
                        <div className="mt-3">
                            <span className="mr-1">Page Type</span>
                            <Select
                                name="type"
                                options={editPageModal.typeList}
                                className="devas-select"
                                classNamePrefix="devas-select"
                                onChange={editPageModal.onEntryChange}
                                theme={selectTheme}
                                value={values.type[type]}
                            />
                        </div>
                        <div className="mt-3">
                            <span className="mr-1">Page Status</span>
                            <Select
                                name="status"
                                options={editPageModal.statusList}
                                className="devas-select"
                                classNamePrefix="devas-select"
                                onChange={editPageModal.onEntryChange}
                                theme={selectTheme}
                                value={values.status[status]}
                            />
                        </div>
                        <div className="mt-3">
                            <span className="mr-1">Private Page</span>
                            <Select
                                name="isPrivate"
                                options={editPageModal.isPrivateList}
                                className="devas-select"
                                classNamePrefix="devas-select"
                                onChange={editPageModal.onEntryChange}
                                theme={selectTheme}
                                value={values.isPrivate[isPrivate]}
                            />
                        </div>
                        <div className="mt-3">
                            <span className="mr-1">Upload Source</span>
                            <Select
                                name="uploadSource"
                                options={editPageModal.uploadSourceList}
                                className="devas-select"
                                classNamePrefix="devas-select"
                                onChange={editPageModal.onEntryChange}
                                theme={selectTheme}
                                value={values.uploadSource[uploadSource]}
                            />
                        </div>
                        {uploadSource === "ladipage" &&
                            <div className="mt-3">
                                <span className="mt-2">Upload page here:</span>
                                <input
                                    type="file"
                                    key={fileKey}
                                    onChange={editPageModal.onFileChange}
                                    className="mt-2"
                                />
                                {file?.name !== "" &&
                                    <button type="button" className="btn button-devas-gray mt-2" onClick={editPageModal.onClearFile}>
                                        Clear File Selection
                                    </button>
                                }
                                <p className="mt-2">HTML files exported from LadiPage are supported</p>
                            </div>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn button-devas-gray" onClick={editPageModal.onClose}>
                            Close
                        </button>
                        <button type="button" className="btn button-devas" onClick={editPageModal.onConfirm}>
                            {action === "create" ? "Create Page" : "Edit Page"}
                        </button>
                    </Modal.Footer>
                </Modal>
            </MainAdmin>
        );
    }
}
