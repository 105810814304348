import Axios from "axios";
import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { customStyles } from "../../../style/table";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import moment from "moment";
import { selectTheme } from "../../../style/reactselect";
import "react-dropdown/style.css";
import "../../../style/dropdown.css";

const columns = [
    {
        name: "User",
        selector: "bizdev.username",
        sortable: true,
        wrap: true,
    },
    {
        name: "Period",
        selector: "period",
        sortable: true,
        wrap: true,
    },
    {
        name: "Action",
        selector: "action",
        sortable: true,
        wrap: true,
    },
];

export default class MissingKpiGoal extends Component {
    state = {
        data: [],
        isLoading: true,
        year: { value: "", label: "Select..." },
        month: { value: "", label: "Select..." },
        bizdev: { value: "", label: "Select..." },
        kpiGoal: "",
    };
    componentDidMount() {
        this.signal = Axios.CancelToken.source();
        this.getMissingKpiGoal();
    }
    componentWillUnmount() {
        this.signal.cancel("Api is being canceled");
    }
    getMissingKpiGoal = () => {
        Axios.get(`${process.env.REACT_APP_ADMIN_URL}/kpi-goal/missing`, {
            cancelToken: this.signal.token,
            headers: {
                "Content-Type": "application/json",
                Authorization: "JWT " + localStorage.getItem("token_admin"),
            },
            params: {
                month: moment().month() + 1,
                year: moment().year(),
            },
        })
            .then((response) => {
                let data = response.data.map((v) => {
                    v.period = v.month + " - " + v.year;
                    v.action = (
                        <button
                            className="btn button-devas"
                            onClick={() => {
                                this.setState({
                                    showModal1: true,
                                    month: { label: v.month, value: v.month },
                                    year: { label: v.year, value: v.year },
                                    bizdev: { label: v.bizdev.username, value: v.bizdev._id },
                                });
                            }}
                        >
                            Create Kpi Goal
                        </button>
                    );
                    return v;
                });
                console.log(data);
                this.setState({ data, isLoading: false });
            })
            .catch((error) => {
                //this.props.history.push("/error?message=" + error.message);
            });
    };

    handleModal1Open = () => {
        this.setState({ showModal1: true });
    };

    handleModal1Close = () => {
        this.setState({
            showModal1: false,
            year: { value: "", label: "Select..." },
            month: { value: "", label: "Select..." },
            bizdev: { value: "", label: "Select..." },
            kpiGoal: "",
        });
    };
    handleSelectBizdev = (bizdev) => {
        this.setState({ bizdev });
    };
    handleSelectMonth = (month) => {
        this.setState({ month });
    };
    handleSelectYear = (year) => {
        this.setState({ year });
    };
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };
    handleSelectMap = (arr) => {
        return arr.map((v) => {
            return { value: v, label: v };
        });
    };
    isAllNumber = (v) => {
        let reg = /^-?\d+\.?\d*$/;
        return v.match(reg);
    };
    handleSubmit = () => {
        if (!this.state.year.value || !this.state.month.value || !this.state.bizdev.value || !this.state.kpiGoal) {
            return alert("Please fill in all fields!");
        }
        if (!this.isAllNumber(this.state.kpiGoal)) {
            return alert("Offer percentage and pub percentage must be number!");
        }
        Axios.post(
            `${process.env.REACT_APP_ADMIN_URL}/kpi-goal`,
            {
                bizdev: this.state.bizdev.value,
                goal: this.state.kpiGoal,
                month: this.state.month.value,
                year: this.state.year.value,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            }
        )
            .then((response) => {
                alert("Kpi Goal Created Successfully!");
                this.getMissingKpiGoal();
                this.handleModal1Close();
                this.props.updateKpiGoalList();
            })
            .catch((error) => {
                alert("Some problem occured! " + error.response.data.message);
                //this.props.history.push("/error?message=" + error.message);
            });
    };

    render() {
        return (
            <div className="col-12 col-lg-6 Statistics">
                <div className="py-3 bg-menu">
                    <div className="px-4 d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                            <h2 className="menu-h2-title mb-0">Unassigned KPI Goal</h2>
                        </div>
                        <div className="d-flex align-items-center">
                            <button className="btn button-devas d-inline-block mr-2" onClick={this.handleModal1Open}>
                                Create/Edit KPI Goal
                            </button>
                        </div>
                    </div>
                </div>
                <DataTable
                    columns={columns}
                    data={this.state.data}
                    pagination
                    theme="myTheme"
                    className="table"
                    customStyles={customStyles}
                    noHeader={true}
                    progressPending={this.state.isLoading}
                    highlightOnHover={true}
                    defaultSortField="order"
                    defaultSortAsc={false}
                    paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 500, 1000]}
                />
                <Modal
                    show={this.state.showModal1}
                    onHide={this.handleModal1Close}
                    className={`${localStorage.getItem("theme") === "light" ? "light-theme" : "dark-theme"} myModal`}
                    centered
                >
                    <Modal.Header closeButton>
                        <h2 className="menu-h2-title modal-title">Create/Edit KPI Goal</h2>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mt-3">
                            <span className="mr-1">Bizdev User:</span>
                            <Select
                                isMulti={false}
                                options={this.props.bizdevList}
                                className="devas-select d-inline-block w-50 bg-menu"
                                classNamePrefix="devas-select"
                                onChange={this.handleSelectBizdev}
                                theme={selectTheme}
                                value={this.state.bizdev}
                            />
                        </div>
                        <div className="mt-3">
                            <span className="mr-1">Month:</span>
                            <Select
                                isMulti={false}
                                options={this.handleSelectMap(["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"])}
                                className="devas-select d-inline-block w-50 bg-menu"
                                classNamePrefix="devas-select"
                                onChange={this.handleSelectMonth}
                                theme={selectTheme}
                                value={this.state.month}
                            />
                        </div>
                        <div className="mt-3">
                            <span className="mr-1">Year:</span>
                            <Select
                                isMulti={false}
                                options={this.handleSelectMap(["2021", "2022", "2023", "2024", "2025"])}
                                className="devas-select d-inline-block w-50 bg-menu"
                                classNamePrefix="devas-select"
                                onChange={this.handleSelectYear}
                                theme={selectTheme}
                                value={this.state.year}
                            />
                        </div>
                        <div className="mt-3">
                            <span>Kpi Goal:</span>
                            <input
                                name="kpiGoal"
                                type="text"
                                value={this.state.kpiGoal}
                                className="form-control search-input w-auto d-inline-block"
                                onChange={this.handleChange}
                                placeholder="Kpi Goal..."
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn button-devas-gray" onClick={this.handleModal1Close}>
                            Cancel
                        </button>
                        <button type="button" className="btn button-devas" onClick={this.handleSubmit}>
                            Create/Edit
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
