import React, { Component } from "react";
import Select from "react-select";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import axios from "axios";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import "react-dropdown/style.css";
import "../style/dropdown.css";
import "../style/daterangepicker.css";
import { selectTheme } from "../style/reactselect";
import { moneyFormat } from "../helper/helper";

import MainAdmin from "./adminbase/MainAdmin";
import SummaryStatistics from "./adminbase/SummaryStatistics";
import ConversionList from "./adminbase/ConversionList";
import ConversionStatisticsPub from "./adminbase/ConversionStatisticsPub";
import ConversionStatisticsOffer from "./adminbase/ConversionStatisticsOffer";
import ConversionsPubStatus from "./adminbase/ConversionsPubStatus";
import RejectedChart from "./adminbase/RejectedChart";
import MarginByOffersStatistics from "./adminbase/MarginByOffersStatistics";
import ConversionsStatisticsTwoHours from "./adminbase/ConversionsStatisticsTwoHours";
import EditConversionPayout from "./adminbase/EditConversionPayout";
import SubmitNewConversions from "./adminbase/modal/SubmitNewConversions";
import MarginBizDevOfferChart from "./adminbase/MarginBizDevOfferChart";

import UploadStatusFile from "./adminbase/UploadStatusFile";
import redirectNoToken from "../helper/redirectNoToken";
import SubmitRejectedConversion from "./adminbase/modal/SubmitRejectedConversion";
import "../style/modal.css";
import "../style/reactcontextmenu.css";
import { parse } from "query-string";
import { off } from "codemirror/src/util/event";
import SubmitDeliveryStatus from "./adminbase/modal/SubmitDeliveryStatus";
import SearchConversion from "./adminbase/modal/SearchConversion";
import SubmitAnonymousConversions from "./adminbase/modal/SubmitAnonymousConversions";

export default class DashboardAdmin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            offerIdOptions: [
                { value: "", label: "Offer ID" },
                { value: "one", label: "One" },
                { value: "two", label: "Two" },
            ],
            pubOptions: [
                { value: "", label: "Publisher" },
                { value: "one", label: "One" },
                { value: "two", label: "Two" },
            ],
            offerId: [],
            pub: [],
            status: "",
            date: [new Date(), new Date()],
            dateType: { label: "Created Date", value: "false" },
            conversionsList: [],
            conversions: [],
            isLoadingTable: true,
            approved: "0",
            approved_rate: "0",
            new_conv: "0",
            callbacks: "0",
            trash: "0",
            customer_reject: "0",
            duplicated: "0",
            allConversions: "0",
            approvedPayout: "0",
            pendingPayout: "0",
            rejectedPayout: "0",
            showModal1: false,
            editOrderId: "",
            editStatus: { label: "Select...", value: "" },
            editSaleMessage: "",
            editIsCombo: "",
            editComboChoice: "",
            payoutPub: "",
            payoutAdv: "",
            editConversionStatusType: { value: "status", label: "status" },
            pubStatusDeviation: 0,
            quickFilter: { value: "today", label: "Today" },
            showModalUpload: false,
            rejectReason: { value: "", label: "Select a reason..." },
            showModalSubmitRejectedConversion: false,
            showModalEditPayout: false,
            chosenEditComboAlias: "",
            showModalSubmitNewConversions: false,
            showModalSubmitDeliveryStatus: false,
            deliveryStatusStats: "0/0/0",
            showModalSearchConversion: false,
            showModalSubmitAnonymousConversions: false,
        };
    }

    componentDidMount() {
        document.title = "Dashboard";
        redirectNoToken.bind(this)();
        this.getConversionList();
    }

    componentWillUnmount() {
        this.signal.cancel("Api is being canceled");
    }

    getConversionList = () => {
        this.signal = axios.CancelToken.source();
        let from_date = moment(this.state.date[0]).startOf("date").unix() * 1000;
        let to_date = moment(this.state.date[1]).endOf("date").unix() * 1000 + 999;
        axios
            .get(
                `${process.env.REACT_APP_ADMIN_URL}/conversions?from_date=${from_date}&to_date=${to_date}&modified_date=${this.state.dateType.value}`,
                {
                    cancelToken: this.signal.token,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "JWT " + localStorage.getItem("token_admin"),
                    },
                }
            )
            .then((response) => {
                let data = response.data.reverse().map((v) => ({
                    _id: v._id,
                    order_id: v.order_id,
                    created_date: new Date(v.created_date).toLocaleString(),
                    modified_date: new Date(v.modified_date).toLocaleString(),
                    created_date_unmodified: v.created_date,
                    offer: v.offer.alias,
                    offer_id: v.offer._id,
                    status: v.status,
                    modify_count: v.modify_count,
                    name: v.name,
                    phone: v.phone,
                    quantity: v.quantity,
                    sale_username: v.hasOwnProperty("sale") && v.sale.hasOwnProperty("username") ? v.sale.username : "",
                    sale_message: v.sale_message,
                    pub: v.publisher.username,
                    pub_id: v.publisher._id,
                    address: v.address,
                    payoutAdmin: v.payoutAdmin,
                    payoutPub: v.payoutPub,
                    income: v.income,
                    message: v.message,
                    pubStatus: v.pubStatus,
                    submitCount: v.submitCount,
                    sale_message_pub: v.sale_message_pub,
                    rejectedMessageInfo: v.rejectedMessageInfo,
                    is_home: v.is_home ? "Yes" : "No",
                    is_paid: v.is_paid ? "Yes" : "No",
                    adv_invoice: v.adv_invoice_id && v.adv_invoice_id.hasOwnProperty("name") ? v.adv_invoice_id.name : "",
                    pub_invoice: v.pub_invoice_id && v.pub_invoice_id.hasOwnProperty("name") ? v.pub_invoice_id.name : "",
                    comboAlias: v.comboLog ? v.comboLog.alias : "",
                    adv: v.offer.adv.username,
                    aff_sub5: v.aff_sub5,
                    bizdevPubKPI: v.bizdevPubKPI,
                    bizdevOfferKPI: v.bizdevOfferKPI,
                    bizdevOffer: v.bizdevOffer && v.bizdevOffer.username,
                    bizdevPub: v.bizdevPub && v.bizdevPub.username,
                    extraFee: v.extraFee,
                    isCombo: v.offer.isCombo,
                    isComboText: v.offer.isCombo ? "Yes" : "No",
                    delivery_status: v.delivery_status ? v.delivery_status : "",
                    useDeliveryStatus: v.offer.useDeliveryStatus ? "Yes" : "No",
                    delivery_code: v.delivery_code ? v.delivery_code : "",
                    incomeDetail: v.incomeDetail ? JSON.stringify(v.incomeDetail) : "",
                }));
                var offers = response.data.map((offer) => {
                    return offer.offer.alias;
                });
                offers = [...new Set(offers)].map((value) => {
                    return { value: value, label: value };
                });
                offers = offers.reverse();
                var publisher = response.data.map((value) => {
                    return value.publisher.username;
                });
                publisher = [...new Set(publisher)].map((value) => {
                    return { value: value, label: value };
                });
                console.log(data);
                this.setState(
                    {
                        offerIdOptions: offers,
                        pubOptions: publisher,
                        conversions: data,
                        conversionsList: data,
                        isLoadingTable: false,
                    },
                    () => this.filterConversion()
                );
            })
            .catch((error) => {
                //this.props.history.push("/error?message=" + error.message);
            });
    };

    filterConversion = () => {
        this.setState((state, props) => {
            let conversions = state.conversionsList.filter((conversion) => {
                return (
                    conversion.status.includes(state.status) &&
                    (state.offerId === null || state.offerId.length === 0 || this.checkOfferInList(conversion.offer)) &&
                    (state.pub === null || state.pub.length === 0 || this.checkPubInList(conversion.pub))
                );
            });

            this.calculateSummary(conversions);
            return { conversions };
        });
    };
    checkOfferInList = (offerId) => {
        return this.state.offerId.some((v) => {
            return offerId == v.value;
        });
    };
    checkPubInList = (pub) => {
        return this.state.pub.some((v) => {
            return pub == v.value;
        });
    };
    calculateSummary = (data) => {
        let approved = data.filter((v) => v.status === "approved").length;
        let new_conv = data.filter((v) => v.status === "new").length;
        let callbacks = data.filter((v) => v.status === "callbacks").length;
        let customer_reject = data.filter((v) => v.status === "customer_reject").length;
        let trash = data.filter((v) => v.status === "trash").length;
        let duplicated = data.filter((v) => v.status === "duplicated").length;
        let conversionsTotal = data.length - trash - duplicated === 0 ? 1 : data.length - trash - duplicated;
        let approved_rate = ((approved / conversionsTotal) * 100).toFixed(2);
        let allConversions = data.length;
        let approvedPayout = 0;
        let pendingPayout = 0;
        let rejectedPayout = 0;
        let payoutAdv = 0;
        let payoutPub = 0;
        let pubStatusDeviation = data.filter((v) => v.status !== v.pubStatus).length;
        data.forEach((v) => {
            if (v.status === "approved") {
                payoutAdv += v.income;
                if (v.pubStatus === "approved") {
                    approvedPayout += v.payoutAdmin;
                    payoutPub += v.payoutPub;
                } else {
                    approvedPayout += v.payoutAdmin + v.payoutPub;
                }
            } else if (v.status === "new" || v.status === "callbacks") pendingPayout += v.payoutAdmin;
            else rejectedPayout += v.payoutAdmin;
        });
        let deliveryStatusStats = (data) => {
            let shipped = 0;
            let delivered = 0;
            let returned = 0;
            data.forEach((v) => {
                if (v.delivery_status === "shipped") {
                    shipped += 1;
                }
                if (v.delivery_status === "delivered") {
                    delivered += 1;
                }
                if (v.delivery_status === "returned") {
                    returned += 1;
                }
            });
            return shipped + "/" + delivered + "/" + returned;
        };
        this.setState({
            approved,
            new_conv,
            callbacks,
            customer_reject,
            trash,
            duplicated,
            approved_rate,
            allConversions,
            approvedPayout,
            pendingPayout,
            rejectedPayout,
            payoutAdv,
            payoutPub,
            pubStatusDeviation,
            deliveryStatusStats: deliveryStatusStats(data),
        });
    };
    onChange = (date) =>
        this.setState({ date }, () => {
            if (this.state.date && this.state.date[0] && this.state.date[1]) {
                this.getConversionList();
            } else if (this.state.date == null) {
                this.resetState();
            }
        });
    handleChangeOfferId = (offerId) => {
        this.setState({ offerId }, () => this.filterConversion());
    };
    handleChangePub = (pub) => {
        this.setState({ pub }, () => this.filterConversion());
    };
    handleChangeDateType = (dateType) => {
        this.setState({ dateType }, () => this.getConversionList());
    };
    handleChangeEditStatus = (editStatus) => {
        this.setState({ editStatus });
    };
    handleChangeEditConversionStatusType = (editConversionStatusType) => {
        this.setState({ editConversionStatusType });
    };
    handleChangeRejectReason = (rejectReason) => {
        this.setState({ rejectReason });
    };
    handleModal1Close = () => {
        this.setState({
            showModal1: false,
            editOrderId: "",
            editStatus: { label: "Select...", value: "" },
            editSaleMessage: "",
            editConversionStatusType: { value: "status", label: "status" },
            editIsCombo: "",
            editComboChoice: "",
            chosenEditComboAlias: "",
        });
    };
    handleModal1Open = () => {
        this.setState({ showModal1: true });
    };
    handleModalUploadOpen = () => {
        this.setState({ showModalUpload: true });
    };
    handleModalUploadClose = () => {
        this.setState({ showModalUpload: false });
    };
    handleSubmitRejectedConversionClose = () => {
        this.setState({ showModalSubmitRejectedConversion: false });
    };
    handleModalEditPayoutClose = () => {
        this.setState({ showModalEditPayout: false });
    };
    handleModalSubmitNewConversionsClose = () => {
        this.setState({ showModalSubmitNewConversions: false });
    };
    handleModalSubmitDeliveryStatusClose = () => {
        this.setState({ showModalSubmitDeliveryStatus: false });
    };
    handleModalSearchConversion = () => {
        this.setState({ showModalSearchConversion: false });
    };
    handleModalSubmitAnonymousConversionsClose = () => {
        this.setState({ showModalSubmitAnonymousConversions: false });
    };
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };
    handleChangeStatisticsTable = (name, value, select) => {
        this.setState({ [name]: value }, () => {
            this.triggerMouseEvent(document.querySelector(select + " .devas-select__control"), "mousedown");
            this.filterConversion();
        });
    };
    triggerMouseEvent = (node, eventType) => {
        var clickEvent = document.createEvent("MouseEvents");
        clickEvent.initEvent(eventType, true, true);
        node.dispatchEvent(clickEvent);
    };
    resetState = () => {
        this.setState({
            conversionsList: [],
            conversions: [],
            approved: "0",
            approved_rate: "0",
            new_conv: "0",
            callbacks: "0",
            trash: "0",
            customer_reject: "0",
            duplicated: "0",
            allConversions: "0",
            approvedPayout: "0",
            pendingPayout: "0",
            rejectedPayout: "0",
            conversionPoolList: [],
            pub: [],
            pubOptions: [],
            offerIdOptions: [],
            offerId: [],
            dateType: { label: "Created Date", value: "false" },
            isLoadingTable: true,
            editConversionStatusType: { value: "status", label: "status" },
        });
    };
    handleEditStatus = () => {
        if (this.state.editConversionStatusType.value === "status") {
            if (this.state.editSaleMessage === "" || this.state.editOrderId === "" || this.state.editStatus.value === "") {
                alert("Please fill in all fields!");
            } else if (!this.state.editOrderId.trim().match(/^-?\d+\.?\d*$/)) {
                alert("Order Id must be a number!");
            } else if (this.state.editStatus.value === "customer_reject" && this.state.rejectReason.value === "") {
                alert("Please choose a reject reason!");
            } else {
                axios
                    .post(
                        `${process.env.REACT_APP_ADMIN_URL}/conversions/`,
                        {
                            order_id: this.state.editOrderId,
                            status: this.state.editStatus.value,
                            sale_message: this.state.editSaleMessage,
                            rejected_message_info:
                                this.state.editStatus.value !== "customer_reject" ? undefined : this.state.rejectReason.value,
                        },
                        {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: "JWT " + localStorage.getItem("token_admin"),
                            },
                        }
                    )
                    .then((response) => {
                        alert(response.data.message);
                        this.handleModal1Close();
                        this.getConversionList();
                    })
                    .catch((error) => {
                        alert("Something went wrong!" + error.response.data.message);
                        //this.props.history.push("/error?message=" + error.message);
                    });
            }
        } else if (this.state.editConversionStatusType.value === "pubStatus") {
            if (this.state.editSaleMessage === "" || this.state.editOrderId === "" || this.state.editStatus.value === "") {
                alert("Please fill in all fields!");
            } else if (!this.state.editOrderId.trim().match(/^-?\d+\.?\d*$/)) {
                alert("Order Id must be a number!");
            } else {
                axios
                    .patch(
                        `${process.env.REACT_APP_ADMIN_URL}/conversions/pubStatus`,
                        {
                            order_id: this.state.editOrderId,
                            pubStatus: this.state.editStatus.value,
                            sale_message_pub: this.state.editSaleMessage,
                        },
                        {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: "JWT " + localStorage.getItem("token_admin"),
                            },
                        }
                    )
                    .then((response) => {
                        alert(response.data.message);
                        this.handleModal1Close();
                        this.getConversionList();
                    })
                    .catch((error) => {
                        alert("Something went wrong!" + error.response.data.message);
                        //this.props.history.push("/error?message=" + error.message);
                    });
            }
        } else if (this.state.editConversionStatusType.value === "combo") {
            if (!this.state.editIsCombo) {
                alert("The editing conversion is not from combo offer");
                return false;
            }
            if (!this.state.chosenEditComboAlias || !this.state.editComboChoice) {
                alert("Please select a combo");
                return false;
            }
            if (!this.state.editSaleMessage) {
                alert("Please fill in the sale message");
                return false;
            }
            axios
                .post(
                    `${process.env.REACT_APP_ADMIN_URL}/conversions/combo`,
                    {
                        pub_combo_price_id: this.state.editComboChoice._id,
                        chosen_combo_alias: this.state.chosenEditComboAlias,
                        order_id: parseInt(this.state.editOrderId),
                        sale_message: this.state.editSaleMessage,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "JWT " + localStorage.getItem("token_admin"),
                        },
                    }
                )
                .then((res) => {
                    alert(res.data.message);
                    this.handleModal1Close();
                    this.getConversionList();
                })
                .catch((err) => {
                    alert("Failed");
                    console.log(err);
                    return false;
                });
        } else {
            alert("Try again later!");
            return false;
        }
    };
    synthesizeConversionByPub = (conversions) => {
        let synthesizedConversions = {};
        conversions.forEach(function (item, index) {
            if (!(item.pub_id in synthesizedConversions)) {
                synthesizedConversions[item.pub_id] = {
                    total: 0,
                    approved: 0,
                    rejected: 0,
                    pending: 0,
                };
            }
            synthesizedConversions[item.pub_id]["total"]++;
            synthesizedConversions[item.pub_id]["name"] = item.pub;
            synthesizedConversions[item.pub_id]["pub_id"] = item.pub_id;
            if (item.status === "approved") synthesizedConversions[item.pub_id]["approved"]++;
            else if (item.status === "customer_reject") {
                synthesizedConversions[item.pub_id]["rejected"]++;
            } else if (item.status === "new" || item.status === "callbacks") {
                synthesizedConversions[item.pub_id]["pending"]++;
            }
            synthesizedConversions[item.pub_id]["conversion_text"] =
                synthesizedConversions[item.pub_id]["approved"] +
                "/" +
                synthesizedConversions[item.pub_id]["pending"] +
                "/" +
                synthesizedConversions[item.pub_id]["rejected"] +
                "/" +
                synthesizedConversions[item.pub_id]["total"];
            let ar =
                synthesizedConversions[item.pub_id]["approved"] /
                (synthesizedConversions[item.pub_id]["approved"] +
                    synthesizedConversions[item.pub_id]["pending"] +
                    synthesizedConversions[item.pub_id]["rejected"]);
            synthesizedConversions[item.pub_id]["ar"] = Number.isNaN(ar) ? 0 + "%" : (ar * 100).toFixed(2) + "%";
        });
        return Object.values(synthesizedConversions);
    };
    synthesizeConversionByOffer = (conversions) => {
        let synthesizedConversions = {};
        conversions.forEach(function (item, index) {
            if (!(item.offer in synthesizedConversions)) {
                synthesizedConversions[item.offer] = {
                    total: 0,
                    approved: 0,
                    rejected: 0,
                    pending: 0,
                };
            }
            synthesizedConversions[item.offer]["total"]++;
            synthesizedConversions[item.offer]["name"] = item.name;
            synthesizedConversions[item.offer]["offer_id"] = item.offer;
            if (item.status === "approved") synthesizedConversions[item.offer]["approved"]++;
            else if (item.status === "customer_reject") {
                synthesizedConversions[item.offer]["rejected"]++;
            } else if (item.status === "new" || item.status === "callbacks") {
                synthesizedConversions[item.offer]["pending"]++;
            }
            synthesizedConversions[item.offer]["conversion_text"] =
                synthesizedConversions[item.offer]["approved"] +
                "/" +
                synthesizedConversions[item.offer]["pending"] +
                "/" +
                synthesizedConversions[item.offer]["rejected"] +
                "/" +
                synthesizedConversions[item.offer]["total"];
            let ar =
                synthesizedConversions[item.offer]["approved"] /
                (synthesizedConversions[item.offer]["approved"] +
                    synthesizedConversions[item.offer]["pending"] +
                    synthesizedConversions[item.offer]["rejected"]);
            synthesizedConversions[item.offer]["ar"] = Number.isNaN(ar) ? 0 + "%" : (ar * 100).toFixed(2) + "%";
        });
        return Object.values(synthesizedConversions);
    };
    formatConversionListTable = (conversions) => {
        return conversions.map((v) => {
            let a = { ...v };
            a.income = moneyFormat(parseFloat(v.income), 0, 3) + "₫";
            a.payoutPub = moneyFormat(parseFloat(v.payoutPub), 0, 3) + "₫";
            a.bizdevPubKPI = a.bizdevPubKPI && moneyFormat(parseFloat(v.bizdevPubKPI), 0, 3) + "₫";
            a.bizdevOfferKPI = a.bizdevOfferKPI && moneyFormat(parseFloat(v.bizdevOfferKPI), 0, 3) + "₫";
            a.extraFee = moneyFormat(parseFloat(v.extraFee), 0, 3) + "₫";
            return a;
        });
    };
    synthesizeRejectedConversions = (conversions) => {
        let synthesizedConversions = {};
        synthesizedConversions["cancel"] = conversions.filter((el) => {
            return el.rejectedMessageInfo === "cancel";
        }).length;
        synthesizedConversions["cannot_connect"] = conversions.filter((el) => {
            return el.rejectedMessageInfo === "cannot_connect";
        }).length;
        synthesizedConversions["cannot_delivery"] = conversions.filter((el) => {
            return el.rejectedMessageInfo === "cannot_delivery";
        }).length;
        synthesizedConversions["just_asking"] = conversions.filter((el) => {
            return el.rejectedMessageInfo === "just_asking";
        }).length;
        synthesizedConversions["price"] = conversions.filter((el) => {
            return el.rejectedMessageInfo === "price";
        }).length;
        synthesizedConversions["wrong_order"] = conversions.filter((el) => {
            return el.rejectedMessageInfo === "wrong_order";
        }).length;
        synthesizedConversions["others"] = conversions.filter((el) => {
            return el.rejectedMessageInfo === "others";
        }).length;
        return synthesizedConversions;
    };
    returnCMTrigger = (value, conversion) => {
        return (
            <ContextMenuTrigger
                id="same_unique_identifier"
                collect={() => {
                    return { value, conversion };
                }}
            >
                {value}
            </ContextMenuTrigger>
        );
    };
    handleClickContextMenu = (e, data, target) => {
        let text;
        if (data.operation === "item") {
            text = data.value;
        } else if (data.operation === "row") {
            text = this.parseDataFromConversions(data.conversion);
        }
        navigator.clipboard.writeText(text).then(
            function () {
                console.log("Async: Copying to clipboard was successful!");
            },
            function (err) {
                console.error("Async: Could not copy text: ", err);
            }
        );
    };
    parseDataFromConversions = (conv) => {
        conv._id = undefined;
        conv.publisher = conv.publisher.username;
        conv.offer = conv.offer.alias;
        conv.__v = undefined;
        conv.is_paid = undefined;
        conv.is_home = undefined;
        conv.income = undefined;
        conv.payoutPartner = undefined;
        conv.payoutAdmin = undefined;
        conv.created_date = new Date(conv.created_date).toLocaleString();
        conv.modified_date = new Date(conv.modified_date).toLocaleString();
        let keys = [
            "order_id",
            "publisher",
            "offer",
            "status",
            "name",
            "phone",
            "address",
            "message",
            "quantity",
            "sale_message",
            "submitCount",
            "created_date",
            "modified_date",
        ];
        let objectKeys = Object.keys(conv);
        keys = keys.filter((v) => objectKeys.includes(v));
        return JSON.stringify(conv, keys)
            .replace(new RegExp('"', "g"), " ")
            .replace(new RegExp("{", "g"), "")
            .replace(new RegExp("}", "g"), " ");
    };
    handleDropDownQuickFilter = (e) => {
        let date = [];
        switch (e.value) {
            case "today":
                date = [new Date(), new Date()];
                break;
            case "yesterday":
                date = [new Date() - 86400000, new Date() - 86400000];
                break;
            case "last7days":
                date = [new Date() - 604800000, new Date()];
                break;
            case "last14days":
                date = [new Date() - 1209600000, new Date()];
                break;
            case "lastMonth":
                date = [new Date() - 2592000000, new Date()];
                break;
            default:
            // code block
        }
        this.setState({ status: "", offerId: [], date, quickFilter: e }, () => this.getConversionList());
    };
    synthesizeMarginByOffers = (conversions) => {
        let offers = conversions.map((offer) => {
            return offer.offer;
        });
        offers = [...new Set(offers)].map((value) => {
            return value;
        });
        offers = offers.reverse();
        let approvedConversions = conversions.filter((conversion) => {
            return conversion.status === "approved";
        });
        let margin = approvedConversions.reduce((total, conversion) => {
            if (conversion.pubStatus === "approved") {
                return total + conversion.payoutAdmin;
            } else {
                return total + conversion.payoutAdmin + conversion.payoutPub;
            }
        }, 0);

        let data = offers.flatMap((offer) => {
            let thisOfferApprovedConversions = conversions.filter((conversion) => {
                return conversion.offer === offer && conversion.status === "approved";
            });
            let thisOfferMargin = thisOfferApprovedConversions.reduce((total, conversion) => {
                if (conversion.pubStatus === "approved") {
                    return total + conversion.payoutAdmin;
                } else {
                    return total + conversion.payoutAdmin + conversion.payoutPub;
                }
            }, 0);
            if (margin <= 0 || thisOfferMargin <= 0) return [];
            return {
                indexLabel: offer,
                y: (thisOfferMargin / margin) * 100,
                thisOfferMargin,
            };
        });
        return data;
    };
    synthesizeConversionsEveryTwoHours = (conversions) => {
        let data = [];
        for (let i = 2; i <= 24; i += 2) {
            data.push({
                y: conversions.filter((conversion) => {
                    return (
                        moment(conversion.created_date_unmodified).hour() >= i - 2 &&
                        moment(conversion.created_date_unmodified).hour() < i
                    );
                }).length,
                label: (i - 2).toString() + "-" + i.toString(),
            });
        }
        return data;
    };
    handleGetComboList = () => {
        const editOrderIdValue = parseInt(this.state.editOrderId);
        const editingConv = this.state.conversions.filter((conv) => {
            return conv.order_id === editOrderIdValue;
        })[0];
        if (editingConv) {
            const isCombo = editingConv.isCombo;
            if (isCombo) {
                this.setState({ editIsCombo: true }, () => {
                    this.signal = axios.CancelToken.source();
                    axios
                        .get(
                            `${process.env.REACT_APP_ADMIN_URL}/pub/offer/combo-price/${editingConv.pub_id}/${editingConv.offer_id}`,
                            {
                                cancelToken: this.signal.token,
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                                },
                            }
                        )
                        .then((res) => {
                            const editComboChoice = res.data[0];
                            this.setState({ editComboChoice });
                        })
                        .catch((err) => {
                            console.log(err);
                            alert("Can not find any combo for this offer");
                            return false;
                        });
                });
            } else {
                alert("This offer is not a combo offer");
                return false;
            }
        } else {
            alert(`Conversion ${this.state.editOrderId} is not existed`);
            return false;
        }
    };
    handleEditComboSelected = (combo) => {
        this.setState({ chosenEditComboAlias: combo.alias });
    };
    calculateMarginBizDevOffer = (conversions) => {
        let output = conversions.reduce((accumulator, conversion) => {
            if (!(conversion.offer in accumulator)) {
                accumulator[conversion.offer] = {
                    margin: 0,
                    approvedConversions: 0,
                    offer: conversion.offer,
                    manager: conversion.bizdevOffer,
                };
            }
            if (conversion.status === "approved") {
                if (conversion.pubStatus === "approved") {
                    accumulator[conversion.offer].margin += conversion.payoutAdmin;
                } else {
                    accumulator[conversion.offer].margin += conversion.payoutAdmin + conversion.payoutPub;
                }
                accumulator[conversion.offer].approvedConversions += 1;
            }
            return accumulator;
        }, {});
        return Object.values(output);
    };
    render() {
        var {
            offerIdOptions,
            approved_rate,
            approved,
            new_conv,
            callbacks,
            trash,
            customer_reject,
            duplicated,
            allConversions,
            approvedPayout,
            pendingPayout,
            rejectedPayout,
            pubOptions,
            pub,
            offerId,
            dateType,
            editOrderId,
            editStatus,
            editSaleMessage,
            payoutAdv,
            payoutPub,
            editConversionStatusType,
            rejectReason,
            deliveryStatusStats,
        } = this.state;
        return (
            <MainAdmin history={this.props.history} linkTo={["/dashboard"]} linkName={["Dashboard"]} selectedSection={1}>
                <div className="col-5"></div>
                <div className="col-6 col-lg-3 py-3">
                    <Select
                        isMulti={false}
                        options={[
                            { value: "today", label: "Today" },
                            { value: "yesterday", label: "Yesterday" },
                            { value: "last7days", label: "Last 7 Days" },
                            { value: "last14days", label: "Last 14 Days" },
                            { value: "lastMonth", label: "Last Month" },
                        ]}
                        onChange={this.handleDropDownQuickFilter}
                        value={this.state.quickFilter}
                        placeholder="Quick Date Filter"
                        className="devas-select"
                        classNamePrefix="devas-select"
                        theme={selectTheme}
                    />
                </div>
                <div className="col-6 col-lg-3 pb-3">
                    <Select
                        isMulti={false}
                        options={[
                            { label: "Created Date", value: "false" },
                            { label: "Modified Date", value: "true" },
                        ]}
                        className="devas-select"
                        classNamePrefix="devas-select"
                        onChange={this.handleChangeDateType}
                        theme={selectTheme}
                        value={dateType}
                    />
                </div>
                <div className="col-6 col-lg-3 pb-3">
                    <Select
                        defaultValue={""}
                        isMulti
                        name="pub"
                        options={pubOptions}
                        placeholder="Publisher"
                        className="devas-select select-pub"
                        classNamePrefix="devas-select"
                        onChange={this.handleChangePub}
                        theme={selectTheme}
                        value={pub}
                    />
                </div>
                <div className="col-6 col-lg-3 pb-3">
                    <Select
                        defaultValue={""}
                        isMulti
                        name="offerId"
                        options={offerIdOptions}
                        placeholder="Offer Id"
                        className="devas-select select-offer"
                        classNamePrefix="devas-select"
                        onChange={this.handleChangeOfferId}
                        theme={selectTheme}
                        value={offerId}
                    />
                </div>
                <div className="col-6 col-lg-3 pb-3">
                    <div className="d-flex align-items-center bg-daterangepicker">
                        <DateRangePicker onChange={this.onChange} value={this.state.date} />
                    </div>
                </div>
                <SummaryStatistics
                    approved={approved}
                    approved_rate={approved_rate}
                    new_conv={new_conv}
                    callbacks={callbacks}
                    trash={trash}
                    customer_reject={customer_reject}
                    duplicated={duplicated}
                    allConversions={allConversions}
                    approvedPayout={approvedPayout}
                    pendingPayout={pendingPayout}
                    rejectedPayout={rejectedPayout}
                    payoutAdv={payoutAdv}
                    payoutPub={payoutPub}
                    changeStatus={(status) => this.setState({ status }, () => this.filterConversion())}
                    pubStatusDeviation={this.state.pubStatusDeviation}
                    deliveryStatusStats={deliveryStatusStats}
                />
                <ConversionStatisticsPub
                    pubFilter={this.state.pub}
                    isLoading={this.state.isLoadingTable}
                    history={this.props.history}
                    synthesizedConversions={this.synthesizeConversionByPub(this.state.conversions)}
                    handleClick={this.handleChangeStatisticsTable}
                />
                <ConversionStatisticsOffer
                    offerFilter={this.state.offerId}
                    isLoading={this.state.isLoadingTable}
                    history={this.props.history}
                    synthesizedConversions={this.synthesizeConversionByOffer(this.state.conversions)}
                    handleClick={this.handleChangeStatisticsTable}
                />
                <ConversionsPubStatus
                    isLoading={this.state.isLoadingTable}
                    history={this.props.history}
                    synthesizedConversions={this.state.conversions.filter((conversion) => {
                        return conversion.status !== conversion.pubStatus;
                    })}
                />
                <RejectedChart synthesizedConversions={this.synthesizeRejectedConversions(this.state.conversions)} />
                <MarginByOffersStatistics synthesizedConversions={this.synthesizeMarginByOffers(this.state.conversions)} />
                <ConversionsStatisticsTwoHours
                    synthesizedConversions={this.synthesizeConversionsEveryTwoHours(this.state.conversions)}
                />
                <MarginBizDevOfferChart synthesizedConversions={this.calculateMarginBizDevOffer(this.state.conversions)} />
                <ConversionList
                    isAdmin={true}
                    conversions={this.formatConversionListTable(this.state.conversions)}
                    isLoading={this.state.isLoadingTable}
                    history={this.props.history}
                    editConversionPayout={() => {
                        this.setState({ showModalEditPayout: true });
                    }}
                    editConversion={this.handleModal1Open}
                    uploadStatus={this.handleModalUploadOpen}
                    submitRejectConversion={() => this.setState({ showModalSubmitRejectedConversion: true })}
                    submitNewConversion={() => this.setState({ showModalSubmitNewConversions: true })}
                    submitDeliveryStatus={() => this.setState({ showModalSubmitDeliveryStatus: true })}
                    search={() => {
                        this.setState({ showModalSearchConversion: true });
                    }}
                    anonymousConversions={() => {
                        this.setState({ showModalSubmitAnonymousConversions: true });
                    }}
                />
                <Modal
                    show={this.state.showModal1}
                    onHide={this.handleModal1Close}
                    className={`${localStorage.getItem("theme") === "light" ? "light-theme" : "dark-theme"} myModal`}
                    centered
                >
                    <Modal.Header closeButton>
                        <h2 className="menu-h2-title modal-title">Edit Conversion</h2>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mt-3">
                            <span className="mr-1">Type:</span>
                            <Select
                                isMulti={false}
                                name="editConversionStatusType"
                                options={[
                                    { value: "status", label: "Edit Status" },
                                    { value: "pubStatus", label: "Edit pubStatus" },
                                    { value: "combo", label: "Edit Combo" },
                                ]}
                                placeholder="Offer Id"
                                className="devas-select d-inline-block w-50 bg-menu"
                                classNamePrefix="devas-select"
                                onChange={this.handleChangeEditConversionStatusType}
                                theme={selectTheme}
                                value={editConversionStatusType}
                            />
                        </div>
                        <div className="mt-3">
                            <span>Order Id:</span>
                            <input
                                name="editOrderId"
                                type="text"
                                value={editOrderId}
                                className="form-control search-input w-auto d-inline-block"
                                onChange={this.handleChange}
                                // onChange={this.handleEditOrderIdChange}
                                placeholder="Order Id..."
                            />
                            {editConversionStatusType.value === "combo" ? (
                                <button className="btn button-devas ml-3" onClick={this.handleGetComboList}>
                                    Get Combo
                                </button>
                            ) : null}
                        </div>
                        {editConversionStatusType.value !== "combo" ? (
                            <div className="mt-3">
                                <span className="mr-1">Status:</span>
                                <Select
                                    defaultValue={""}
                                    isMulti={false}
                                    name="offerId"
                                    options={[
                                        { value: "callbacks", label: "callbacks" },
                                        { value: "customer_reject", label: "customer_reject" },
                                        { value: "approved", label: "approved" },
                                        { value: "trash", label: "trash" },
                                        { value: "duplicated", label: "duplicated" },
                                    ]}
                                    placeholder="Offer Id"
                                    className="devas-select d-inline-block w-50 bg-menu"
                                    classNamePrefix="devas-select"
                                    onChange={this.handleChangeEditStatus}
                                    theme={selectTheme}
                                    value={editStatus}
                                />
                            </div>
                        ) : null}

                        {this.state.editIsCombo && this.state.editComboChoice ? (
                            <div className="mt-3">
                                <span className="mr-1">Combo:</span>
                                <div className="row mt-2">
                                    {this.state.editComboChoice.price.map((item, index) => {
                                        return (
                                            <div className="col-4" onClick={() => this.handleEditComboSelected(item)}>
                                                <div
                                                    className={
                                                        this.state.chosenEditComboAlias === item.alias
                                                            ? "comboChoiceSelected bg-menu px-2 mt-2"
                                                            : "comboChoice bg-menu px-2 mt-2"
                                                    }
                                                >
                                                    <p className="my-1">Name: {item.name}</p>
                                                    <p className="my-1">Description: {item.description}</p>
                                                    <p className="my-1">Price: {moneyFormat(parseFloat(item.price))}</p>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                        <div className="mt-3">
                            <span>Sale Message:</span>
                            <input
                                name="editSaleMessage"
                                type="text"
                                value={editSaleMessage}
                                className="form-control search-input w-auto d-inline-block"
                                onChange={this.handleChange}
                                placeholder="Sale Message..."
                            />
                        </div>
                        {editConversionStatusType.value === "status" && editStatus.value === "customer_reject" && (
                            <div className="mt-3">
                                <span className="mr-1">Reject Reason:</span>
                                <Select
                                    defaultValue={""}
                                    isMulti={false}
                                    name="offerId"
                                    options={[
                                        { label: "Cancel", value: "cancel" },
                                        { label: "Can't Connect", value: "cannot_connect" },
                                        { label: "Can't delivery", value: "cannot_delivery" },
                                        { label: "Just asking", value: "just_asking" },
                                        { label: "Price", value: "price" },
                                        { label: "Wrong Order", value: "wrong_order" },
                                        { label: "Refusal on delivery", value: "refusal_on_delivery" },
                                        { label: "Others", value: "others" },
                                    ]}
                                    placeholder="Offer Id"
                                    className="devas-select d-inline-block w-50 bg-menu"
                                    classNamePrefix="devas-select"
                                    onChange={this.handleChangeRejectReason}
                                    theme={selectTheme}
                                    value={rejectReason}
                                />
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn button-devas-gray" onClick={this.handleModal1Close}>
                            Cancel
                        </button>
                        <button type="button" className="btn button-devas" onClick={this.handleEditStatus}>
                            Edit
                        </button>
                    </Modal.Footer>
                </Modal>
                <SubmitRejectedConversion
                    showModal={this.state.showModalSubmitRejectedConversion}
                    handleModalClose={this.handleSubmitRejectedConversionClose}
                />
                <UploadStatusFile showModal={this.state.showModalUpload} handleModalClose={this.handleModalUploadClose} />
                <ContextMenu id="same_unique_identifier">
                    <MenuItem data={{ operation: "item" }} onClick={this.handleClickContextMenu}>
                        Copy Item
                    </MenuItem>
                    <MenuItem data={{ operation: "row" }} onClick={this.handleClickContextMenu}>
                        Copy Row
                    </MenuItem>
                </ContextMenu>
                <EditConversionPayout
                    showModal={this.state.showModalEditPayout}
                    handleModalClose={this.handleModalEditPayoutClose}
                />
                <SubmitNewConversions
                    showModal={this.state.showModalSubmitNewConversions}
                    handleModalClose={this.handleModalSubmitNewConversionsClose}
                />
                <SubmitDeliveryStatus
                    showModal={this.state.showModalSubmitDeliveryStatus}
                    handleModalClose={this.handleModalSubmitDeliveryStatusClose}
                />
                <SearchConversion
                    showModal={this.state.showModalSearchConversion}
                    handleModalClose={this.handleModalSearchConversion}
                />
                <SubmitAnonymousConversions
                    showModal={this.state.showModalSubmitAnonymousConversions}
                    handleModalClose={this.handleModalSubmitAnonymousConversionsClose}
                />
            </MainAdmin>
        );
    }
}
