import React, { Component } from "react";
import axios from "axios";
import TemplateForm from "./adminbase/TemplateForm";

export default class CreateTemplateAdmin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            offers: [],
            validMsg: "",
            invalidMsg: "",
            templateName: "",
        };
    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };
    handleOfferChange = (offers) => {
        this.setState({ offers, validMsg: "", invalidMsg: "" }, () => {
            this.setState((state, props) => {
                let offers = state.offers.map((value) => {
                    let v = { ...value };
                    if (!v.hasOwnProperty("payoutPub")) {
                        v.payoutPub = "";
                    }
                    return v;
                });
                return { offers };
            });
        });
    };
    handleChangeOfferPayoutPub = (v, i) => {
        this.setState((state, props) => {
            return {
                offers: state.offers.map((value, index) => {
                    if (index === i) {
                        value.payoutPub = v;
                    }
                    return value;
                }),
                validMsg: "",
                invalidMsg: "",
            };
        });
    };
    handleChangeName = (name, value) => {
        this.setState({ validMsg: "", invalidMsg: "", [name]: value });
    };
    submitAction = () => {
        let body = {
            name: this.state.templateName,
            pubPayout: this.state.offers.map((v) => {
                return { offer: v.value, price: v.payoutPub.toString().trim() };
            }),
        };
        axios
            .post(`${process.env.REACT_APP_ADMIN_URL}/templates`, body, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            })
            .then((response) => {
                let data = response.data;
                this.setState({ validMsg: `${data.name} has been created successfully!` });
            })
            .catch((error) => {
                this.setState({ invalidMsg: error.response.data.message });
                //this.props.history.push("/error?message=" + error.message);
            });
    };
    render() {
        return (
            <TemplateForm
                isNew={true}
                history={this.props.history}
                handleOfferChange={this.handleOfferChange}
                validMsg={this.state.validMsg}
                invalidMsg={this.state.invalidMsg}
                handleChangeOfferPayoutPub={this.handleChangeOfferPayoutPub}
                offers={this.state.offers}
                templateName={this.state.templateName}
                handleChangeName={this.handleChangeName}
                handleChange={this.handleChange}
                submitAction={this.submitAction}
            />
        );
    }
}
