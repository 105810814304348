import React, { Component } from "react";
import axios from "axios";
import "./adminbase/OfferForm";
import OfferForm from "./adminbase/OfferForm";

export default class EditOfferAdmin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            alias: "",
            category: "",
            price: "",
            payoutPub: "",
            income: "",
            geo: "",
            imgLink: "",
            status: "",
            approved_conversion_change_duration: "",
            networkId: "",
            networkName: "",
            details: "",
            resourcesLink: "",
            validMsg: "",
            invalidMsg: "",
            adv: "",
            _id: "",
            isCombo: false,
            combosList: [],
            numberOfCombos: 0,
            currency: "",
            systemCurrency: "",
            extraFee: "0",
            assignPayout: { label: "No", value: false },
            useDeliveryStatus: { label: "No", value: false },
            isNewProcess: { label: "No", value: false },
            useTelegramNoti: { label: "Yes", value: true },
        };
    }

    componentDidMount() {
        this.signal = axios.CancelToken.source();
        axios
            .get(`${process.env.REACT_APP_ADMIN_URL}/offers`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            })
            .then((response) => {
                let offers = response.data;
                let alias = this.props.match.params.alias;
                if (offers.some((v) => v.alias === alias)) {
                    offers.forEach((offer) => {
                        if (offer.alias === alias) {
                            this.setState({
                                _id: offer._id,
                                name: this.hasOwnPropertyCheck(offer, "name"),
                                alias: this.hasOwnPropertyCheck(offer, "alias"),
                                category: offer.hasOwnProperty("category")
                                    ? { value: offer.category._id, label: offer.category.name }
                                    : "",
                                price: this.hasOwnPropertyCheck(offer, "price"),
                                payoutPub: this.hasOwnPropertyCheck(offer, "payoutPub"),
                                income: this.hasOwnPropertyCheck(offer, "income"),
                                geo: this.hasOwnPropertyCheck(offer, "geo"),
                                imgLink: this.hasOwnPropertyCheck(offer, "imgLink"),
                                status: offer.hasOwnProperty("status")
                                    ? {
                                          value: offer.status,
                                          label: offer.status,
                                      }
                                    : "",
                                approved_conversion_change_duration: this.hasOwnPropertyCheck(
                                    offer,
                                    "approved_conversion_change_duration"
                                ),
                                networkId: offer.network ? this.hasOwnPropertyCheck(offer.network, "id") : "",
                                networkName: offer.network ? this.hasOwnPropertyCheck(offer.network, "name") : "",
                                adv: offer.hasOwnProperty("adv")
                                    ? {
                                          value: offer.adv._id,
                                          label: offer.adv.username,
                                      }
                                    : "",
                                details: this.hasOwnPropertyCheck(offer, "details"),
                                resourcesLink: this.hasOwnPropertyCheck(offer, "resourcesLink"),
                                isCombo: offer.hasOwnProperty("isCombo") ? offer.isCombo : false,
                                combosList: offer.hasOwnProperty("comboChoices") ? offer.comboChoices : [],
                                numberOfCombos: offer.comboChoices.length,
                                currency: offer.currency,
                                systemCurrency: offer.systemCurrency,
                                extraFee: this.hasOwnPropertyCheck(offer, "extraFee"),
                                useDeliveryStatus: offer.hasOwnProperty("useDeliveryStatus") ? offer.useDeliveryStatus ? { label: "Yes", value: true } : { label: "No", value: false } : { label: "No", value: false },
                                isNewProcess: offer.hasOwnProperty("isNewProcess") ? offer.isNewProcess ? { label: "Yes", value: true } : { label: "No", value: false } : { label: "No", value: false },
                                useTelegramNoti: offer.hasOwnProperty("useTelegramNoti") ? offer.useTelegramNoti ? { label: "Yes", value: true } : { label: "No", value: false } : { label: "Yes", value: true },
                            });
                        }
                    });
                } else {
                    this.props.history.push("/404");
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    hasOwnPropertyCheck = (v, property) => {
        if (v.hasOwnProperty(property)) return v[property] + "";
        return "";
    };

    componentWillUnmount() {
        this.signal.cancel("Api is being canceled");
    }

    handleChange = (e) => {
        this.setState({ validMsg: "", invalidMsg: "", [e.target.name]: e.target.value });
    };
    handleChangeName = (name, value) => {
        this.setState({ validMsg: "", invalidMsg: "", [name]: value });
    };
    handleAssignPayout = (assignPayout) => {
        this.setState({ assignPayout });
    };
    editOffer = () => {
        let {
            name,
            alias,
            category,
            price,
            payoutPub,
            income,
            geo,
            imgLink,
            status,
            approved_conversion_change_duration,
            networkId,
            networkName,
            adv,
            resourcesLink,
            details,
            isCombo,
            combosList,
            currency,
            systemCurrency,
            extraFee,
            assignPayout,
            useDeliveryStatus,
            isNewProcess,
            useTelegramNoti
        } = this.state;
        let comboChoices = combosList;
        console.log(comboChoices);
        axios
            .put(
                `${process.env.REACT_APP_ADMIN_URL}/offers/${this.state._id}`,
                {
                    name,
                    alias,
                    category: category.value,
                    adv: adv.value,
                    status: status.value,
                    price,
                    payoutPub,
                    income,
                    geo,
                    imgLink,
                    approved_conversion_change_duration,
                    network: {
                        id: networkId,
                        name: networkName,
                    },
                    resourcesLink,
                    details,
                    isCombo,
                    comboChoices,
                    currency,
                    systemCurrency,
                    extraFee,
                    assignPayout: assignPayout.value,
                    useDeliveryStatus: useDeliveryStatus.value,
                    isNewProcess: isNewProcess.value,
                    useTelegramNoti: useTelegramNoti.value,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "JWT " + localStorage.getItem("token_admin"),
                    },
                }
            )
            .then((response) => {
                let data = response.data;
                this.setState({ validMsg: `Offer ${data.alias} has been edited successfully!` });
            })
            .catch((error) => {
                this.setState({ invalidMsg: error.response.data.message });
                //this.props.history.push("/error?message=" + error.message);
            });
    };
    handleIsComboStatusChanged = (isCombo) => {
        this.setState({ isCombo, payoutPub: "0", combosList: [], numberOfCombos: 0, income: "0", validMsg: "", invalidMsg: "" });
    };
    handleComboDetailChange = (index, key, value) => {
        let combosList = [...this.state.combosList];
        if (!combosList[index]) {
            combosList[index] = {};
        }
        combosList[index][key] = value;
        this.setState({ combosList, validMsg: "", invalidMsg: "" });
    };
    handleNumberOfComboChanged = (number) => {
        let newComboList = [...this.state.combosList];
        if (newComboList.length > number) {
            newComboList.splice(number, newComboList.length - 1);
        }
        this.setState({ combosList: newComboList, numberOfCombos: number, validMsg: "", invalidMsg: "" });
    };
    render() {
        let {
            name,
            alias,
            category,
            price,
            payoutPub,
            income,
            geo,
            imgLink,
            status,
            approved_conversion_change_duration,
            networkId,
            networkName,
            resourcesLink,
            details,
            adv,
            invalidMsg,
            validMsg,
            isCombo,
            combosList,
            extraFee,
            useDeliveryStatus,
            isNewProcess,
            useTelegramNoti,
        } = this.state;
        return (
            <OfferForm
                match={this.props.match}
                history={this.props.history}
                isNewOffer={false}
                name={name}
                alias={alias}
                category={category}
                payoutPub={payoutPub}
                income={income}
                geo={geo}
                imgLink={imgLink}
                status={status}
                approved_conversion_change_duration={approved_conversion_change_duration}
                networkId={networkId}
                networkName={networkName}
                resourcesLink={resourcesLink}
                details={details}
                price={price}
                adv={adv}
                handleChange={this.handleChange}
                handleChangeName={this.handleChangeName}
                invalidMsg={invalidMsg}
                validMsg={validMsg}
                submitAction={this.editOffer}
                isCombo={isCombo}
                changeIsComboStatus={this.handleIsComboStatusChanged}
                combosList={combosList}
                onComboDetailChange={this.handleComboDetailChange}
                numberOfComboOnChange={this.handleNumberOfComboChanged}
                numberOfCombos={this.state.numberOfCombos}
                disableChangeIsCombo={true}
                currency={this.state.currency}
                systemCurrency={this.state.systemCurrency}
                extraFee={this.state.extraFee}
                handleAssignPayout={this.handleAssignPayout}
                assignPayout={this.state.assignPayout}
                useDeliveryStatus={useDeliveryStatus}
                isNewProcess={isNewProcess}
                useTelegramNoti={useTelegramNoti}
            />
        );
    }
}
