import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import JSONPretty from "react-json-pretty";
import "react-json-pretty/themes/monikai.css";
import "../../../style/modal.css";

export default class SearchConversion extends Component {
    state = {
        order_id: "",
        conversion: "",
    };
    componentDidMount() {
        this.signal = axios.CancelToken.source();
    }
    handleModalClose = () => {
        this.setState({ order_id: "", conversion: "" });
        this.props.handleModalClose();
    };
    handleSubmit = () => {
        axios
            .get(`${process.env.REACT_APP_ADMIN_URL}/conversions/order-id/${this.state.order_id}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            })
            .then((response) => {
                let data = response.data;
                data.offer = data.offer.alias;
                data.publisher = data.publisher.username;
                this.setState({ conversion: data });
            })
            .catch((error) => {
                alert("Some problem occured! Make sure that your order-id is valid!");
            });
    };
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };
    render() {
        return (
            <Modal
                show={this.props.showModal}
                onHide={this.handleModalClose}
                className={`${localStorage.getItem("theme") === "light" ? "light-theme" : "dark-theme"} myModal`}
                centered
            >
                <Modal.Header closeButton>
                    <h2 className="menu-h2-title modal-title">Search Conversion</h2>
                </Modal.Header>
                <Modal.Body>
                    <div className="mt-3">
                        <span>Order Id:</span>
                        <input
                            name="order_id"
                            type="text"
                            value={this.state.order_id}
                            className="form-control search-input w-auto d-inline-block"
                            onChange={this.handleChange}
                            placeholder="Order Id..."
                        />
                        <button className="btn button-devas ml-3" onClick={this.handleSubmit}>
                            Search
                        </button>
                    </div>
                    {this.state.conversion && (
                        <div className="mt-3 text-wrap">
                            <JSONPretty data={this.state.conversion} />
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn button-devas-gray" onClick={this.handleModalClose}>
                        Exit
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }
}
