import React, { Component } from "react";
import Dropdown from "react-dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faUsersCog,
    faCopy,
    faTimes,
    faToggleOff,
    faToggleOn,
    faInfoCircle,
    faUserEdit,
    faKey,
} from "@fortawesome/free-solid-svg-icons";
import { faWindowRestore } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";

import MainAdmin from "./adminbase/MainAdmin";
import DataTable from "react-data-table-component";
import axios from "axios";
import Select from "react-select";
import { CopyToClipboard } from "react-copy-to-clipboard";
import redirectNoToken from "../helper/redirectNoToken";
import { customStyles } from "../style/table";
import Modal from "react-bootstrap/Modal";
import "../style/modal.css";
import "react-dropdown/style.css";
import "../style/dropdown.css";
import { selectTheme } from "../style/reactselect";

const columns = [
    {
        name: "ID",
        selector: "_id",
        sortable: true,
        wrap: true,
    },
    {
        name: "Username",
        selector: "username",
        sortable: true,
        wrap: true,
    },
    {
        name: "Role",
        selector: "role",
        sortable: true,
        wrap: true,
    },
    {
        name: "Full Name",
        selector: "fullName",
        sortable: true,
        wrap: true,
    },
    {
        name: "Email",
        selector: "email",
        sortable: true,
        wrap: true,
    },
    {
        name: "Active",
        selector: "status",
        center: true,
    },
    {
        name: "Action",
        selector: "action",
        center: true,
    },
    {
        name: "Simulate View",
        selector: "simulate",
        center: true,
    },
    {
        name: "Detail",
        selector: "detail",
        center: true,
    },
    {
        name: "Edit User Detail",
        selector: "userEdit",
        center: true,
        wrap: true,
    },
    {
        name: "Overwrite Password",
        selector: "passwordOverwrite",
        center: true,
        wrap: true,
    }
];
const redirectViewLink = {
    admin: "https://admin.devas.network/login",
    pub: "https://pub.devas.network/login",
    adv: "https://crm.devas.network/adv/login",
    sale: "https://crm.devas.network/sale/login",
    superPub: "https://superpub.devas.network/login",
    superAdv: "https://superadv.devas.network/login",
    bizdev: "https://bizdev.devas.network/login",
};
export default class ManageUsersAdmin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            role: { value: "", label: "User Role" },
            usersList: [],
            currentUsersList: [],
            isLoading: true,
            showModal1: false,
            showModal2: false,
            showModal3: false,
            username: "",
            password: "",
            newRole: { value: "", label: "User Role" },
            email: "",
            fullName: "",
            search: "",
            childDropdownList: [],
            modal2ChildSelect: [],
            modal2CurrentUser: "",
            modal3RedirectLink: "",
            modal3Role: "",
            modal3Name: "",
            modal3Token: "",
            showModalUserEdit: false,
            copied1: false,
            editUsername: "",
            editFullName: "",
            editEmail: "",
            editUserId: "",
            editPubPhoneNumber: "",
            editPubZaloNumber: "",
            editPubFacebookProfileLink: "",
            editPubApproachCustomerWay: [],
            editPubTrafficSource: {},
            editPubWebsiteName: "",
            editPubWebsiteUrl: "",
            editPubWebsiteDescription: "",
            editPubSocialMediaName: {},
            editPubSocialMediaUseMethod: {},
            editPubSocialMediaUrl: "",
            editPubYoutubeName: "",
            editPubYoutubeUrl: "",
            editPubYoutubeDescription: "",
            editPubBankName: "",
            editPubBankAccountNumber: "",
            editPubBeneficiaryName: "",
            editPubCategoryExperience: [],
            editPubCountryExperience: [],
            editPubMaxDailyConversions: "",
            showModalEditPub: false,
            showModalPasswordOverwrite: false,
            passwordOverwrite: "",
            passwordOverwriteId: "",
            passwordOverwriteName: "",
        };
    }

    componentDidMount() {
        document.title = "Manage Users";
        this.getAllUsers();
    }

    componentWillUnmount() {
        this.signal.cancel("Api is being canceled");
    }

    changeUserStatus = (status, userID) => {
        const data = {
            id: userID,
            status: status,
        };
        axios
            .patch(`${process.env.REACT_APP_ADMIN_URL}/users/change-status`, data, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            })
            .then((response) => {
                if (response.data.message === "failed") {
                    alert("Change status failed!");
                }
                this.getAllUsers();
            })
            .catch((error) => {
                alert("Something wrong happended! " + error.response.data.message);
            });
    };
    getAllUsers = () => {
        this.signal = axios.CancelToken.source();
        axios
            .get(`${process.env.REACT_APP_ADMIN_URL}/users/all`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            })
            .then((response) => {
                let data = response.data.reverse().map((v) => {
                    return {
                        _id: v._id,
                        username: v.username,
                        role: v.role,
                        email: v.email,
                        fullName: v.fullName,
                        action:
                            v.role === "superPub" || v.role === "superAdv" ? (
                                <FontAwesomeIcon
                                    icon={faUsersCog}
                                    size="lg"
                                    className="hover-primary"
                                    onClick={() => this.handleModal2Open(v._id, v.userChildren, v.role)}
                                />
                            ) : (
                                ""
                            ),
                        simulate: (
                            <FontAwesomeIcon
                                icon={faWindowRestore}
                                size="lg"
                                className="hover-primary"
                                onClick={() => this.handleModal3Open(v._id, v.username, v.role)}
                            />
                        ),
                        status:
                            v.status === "active" ? (
                                <FontAwesomeIcon
                                    icon={faToggleOn}
                                    size="lg"
                                    className="hover-primary"
                                    onClick={() => this.changeUserStatus("inactive", v._id)}
                                />
                            ) : (
                                <FontAwesomeIcon
                                    icon={faToggleOff}
                                    size="lg"
                                    className="hover-primary"
                                    onClick={() => this.changeUserStatus("active", v._id)}
                                />
                            ),
                        detail: (
                            <Link to={`/users/${v._id}/detail`} className="a-link">
                                <FontAwesomeIcon
                                    icon={faInfoCircle}
                                    size="lg"
                                    className="hover-primary"
                                    onClick={() => this.changeUserStatus("active", v._id)}
                                />
                            </Link>
                        ),
                        userEdit:
                            v.role !== "pub" ? (
                                <FontAwesomeIcon
                                    icon={faUserEdit}
                                    size="lg"
                                    className="hover-primary"
                                    onClick={() => this.handleModalUserEditOpen(v._id, v.username, v.fullName, v.email)}
                                />
                            ) : (
                                <FontAwesomeIcon
                                    icon={faUserEdit}
                                    size="lg"
                                    className="hover-primary"
                                    onClick={() =>
                                        this.handleModalEditPubUser({
                                            id: v._id,
                                            username: v.username,
                                            fullName: v.fullName,
                                            email: v.email,
                                            bank_name: v.bank_name,
                                            bank_account_number: v.bank_account_number,
                                            beneficiary_name: v.beneficiary_name,
                                            category_experience: v.category_experience,
                                            country_experience: v.country_experience,
                                            max_daily_conversions: v.max_daily_conversions,
                                            phone_number: v.phone_number,
                                            zalo_number: v.zalo_number,
                                            facebook_profile_link: v.facebook_profile_link,
                                            approach_customer_way: v.approach_customer_way,
                                            traffic_source: v.traffic_source,
                                            traffic_source_detail: v.traffic_source_detail,
                                        })
                                    }
                                />
                            ),
                        passwordOverwrite: (
                            <FontAwesomeIcon
                                icon={faKey}
                                size="lg"
                                className="hover-primary"
                                onClick={() => this.handleModalPasswordOverwriteOpen(v._id, v.username)}
                            />
                        ),
                    };
                });
                this.setState(
                    {
                        usersList: data,
                        currentUsersList: data,
                        isLoading: false,
                    },
                    () => {
                        this.filterUsers();
                    }
                );
            })
            .catch((error) => {
                console.log(error);
                //this.props.history.push("/error?message=" + error.message);
            });
    };
    filterUsers = () => {
        this.setState((state, props) => {
            return {
                currentUsersList: state.usersList.filter(
                    (user) =>
                        user.role.indexOf(state.role.value) > -1 &&
                        (user.username.indexOf(state.search) > -1 || user._id.indexOf(state.search) > -1)
                ),
            };
        });
    };
    createUser = () => {
        if (
            this.state.username === "" ||
            this.state.password === "" ||
            this.state.email === "" ||
            this.state.newRole.value === "" ||
            this.state.fullName === ""
        ) {
            alert("Please fill in all fields!");
        } else if (this.state.password.length < 6) {
            alert("Password must have at least 6 characters!");
        } else if (this.state.email.indexOf("@") === -1) {
            alert("Email has wrong format!");
        } else {
            axios
                .post(
                    `${process.env.REACT_APP_ADMIN_URL}/users/registerAllRoles`,
                    {
                        username: this.state.username,
                        password: this.state.password,
                        email: this.state.email,
                        role: this.state.newRole.value,
                        fullName: this.state.fullName,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "JWT " + localStorage.getItem("token_admin"),
                        },
                    }
                )
                .then((response) => {
                    this.handleModal1Close();
                    this.getAllUsers();
                })
                .catch((error) => {
                    alert("Something Wrong Happended!");
                    //this.props.history.push("/error?message=" + error.message);
                });
        }
    };
    saveUserChildren = () => {
        axios
            .patch(
                `${process.env.REACT_APP_ADMIN_URL}/users/${this.state.modal2CurrentUser}/childrens`,
                {
                    childrens: this.state.modal2ChildSelect.reduce((a, v) => {
                        a.push(v.value);
                        return a;
                    }, []),
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "JWT " + localStorage.getItem("token_admin"),
                    },
                }
            )
            .then((response) => {
                alert("Saved successfully!");
                this.getAllUsers();
            })
            .catch((error) => {
                alert("Something wrong happended! " + error.response.data.message);
            });
    };
    editUser = () => {
        axios
            .post(
                `${process.env.REACT_APP_ADMIN_URL}/users/update-user-info`,
                {
                    userId: this.state.editUserId,
                    username: this.state.editUsername,
                    fullName: this.state.editFullName,
                    email: this.state.editEmail,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "JWT " + localStorage.getItem("token_admin"),
                    },
                }
            )
            .then((response) => {
                alert("Edit Successfully!");
                this.handleModalUserEditClose();
                this.getAllUsers();
            })
            .catch((error) => {
                alert("Something Wrong Happended! Username and Email might be duplicated!");
                //this.props.history.push("/error?message=" + error.message);
            });
    };
    handleDropDown = (e) => {
        this.setState({ role: e }, () => {
            this.filterUsers();
        });
    };
    handleDropDownNewRole = (e) => {
        this.setState({ newRole: e });
    };
    handleModal1Close = () => {
        this.setState({
            showModal1: false,
            username: "",
            password: "",
            email: "",
            newRole: { value: "", label: "User Role" },
            fullName: "",
        });
    };
    handleModal2Close = () => {
        this.setState({
            showModal2: false,
            modal2ChildSelect: [],
            modal2CurrentUser: "",
        });
    };
    handleModal3Close = () => {
        this.setState({
            modal3RedirectLink: "",
            modal3Role: "",
            modal3Name: "",
            showModal3: false,
            modal3Token: "",
        });
    };
    handleModalUserEditClose = () => {
        this.setState({ showModalUserEdit: false, editUsername: "", editFullName: "", editEmail: "", editUserId: "" });
    };
    handleModal1Open = () => {
        this.setState({ showModal1: true });
    };
    handleModal2Open = (id, children, type) => {
        let childDropdownList = this.state.usersList
            .filter((v) => v.role === (type === "superPub" ? "pub" : "adv"))
            .map((v) => {
                return { value: v._id, label: v.username };
            });
        let modal2ChildSelect = children.map((v) => {
            let ans = { value: v };
            this.state.usersList.forEach((user) => {
                if (user._id === v) ans.label = user.username;
            });
            return ans;
        });
        this.setState({ modal2ChildSelect, childDropdownList, showModal2: true, modal2CurrentUser: id });
    };
    handleModal3Open = (id, name, role) => {
        this.signal = axios.CancelToken.source();
        axios
            .get(`${process.env.REACT_APP_ADMIN_URL}/users/${id}/get-token`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            })
            .then((response) => {
                let data = response.data;
                this.setState({
                    modal3Role: role,
                    modal3Name: name,
                    modal3RedirectLink: redirectViewLink[role],
                    showModal3: true,
                    modal3Token: data.token,
                });
            })
            .catch((error) => {
                console.log(error);
                //this.props.history.push("/error?message=" + error.message);
            });
    };
    handleModalUserEditOpen = (id, username, fullName, email) => {
        this.setState({
            showModalUserEdit: true,
            editUsername: username,
            editFullName: fullName,
            editEmail: email,
            editUserId: id,
        });
    };
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };
    handleSearch = (e) => {
        this.setState({ search: e.target.value }, () => this.filterUsers());
    };
    handlePubSelectModal2 = (modal2ChildSelect) => {
        this.setState({ modal2ChildSelect });
    };
    handleModalEditPubUser = (pub) => {
        this.setState({
            editUsername: pub.username,
            editFullName: pub.fullName,
            editEmail: pub.email,
            editUserId: pub.id,
            editPubBankName: pub.bank_name,
            editPubBankAccountNumber: pub.bank_account_number,
            editPubBeneficiaryName: pub.beneficiary_name,
            editPubCategoryExperience: pub.category_experience,
            editPubCountryExperience: pub.country_experience,
            editPubMaxDailyConversions: pub.max_daily_conversions,
            editPubPhoneNumber: pub.phone_number,
            editPubZaloNumber: pub.zalo_number,
            editPubFacebookProfileLink: pub.facebook_profile_link,
            editPubApproachCustomerWay: pub.approach_customer_way,
            editPubTrafficSource: pub.traffic_source ? pub.traffic_source : { value: "" },
            editPubWebsiteName:
                pub.traffic_source_detail && pub.traffic_source_detail.hasOwnProperty("website_name")
                    ? pub.traffic_source_detail.website_name
                    : "",
            editPubWebsiteUrl:
                pub.traffic_source_detail && pub.traffic_source_detail.hasOwnProperty("website_url")
                    ? pub.traffic_source_detail.website_url
                    : "",
            editPubWebsiteDescription:
                pub.traffic_source_detail && pub.traffic_source_detail.hasOwnProperty("website_description")
                    ? pub.traffic_source_detail.website_description
                    : "",
            editPubSocialMediaName:
                pub.traffic_source_detail && pub.traffic_source_detail.hasOwnProperty("social_media_name")
                    ? pub.traffic_source_detail.social_media_name
                    : {},
            editPubSocialMediaUseMethod:
                pub.traffic_source_detail && pub.traffic_source_detail.hasOwnProperty("social_media_use_method")
                    ? pub.traffic_source_detail.social_media_use_method
                    : {},
            editPubSocialMediaUrl:
                pub.traffic_source_detail && pub.traffic_source_detail.hasOwnProperty("social_media_url")
                    ? pub.traffic_source_detail.social_media_url
                    : "",
            editPubYoutubeName:
                pub.traffic_source_detail && pub.traffic_source_detail.hasOwnProperty("youtube_name")
                    ? pub.traffic_source_detail.youtube_name
                    : "",
            editPubYoutubeUrl:
                pub.traffic_source_detail && pub.traffic_source_detail.hasOwnProperty("youtube_url")
                    ? pub.traffic_source_detail.youtube_url
                    : "",
            editPubYoutubeDescription:
                pub.traffic_source_detail && pub.traffic_source_detail.hasOwnProperty("youtube_description")
                    ? pub.traffic_source_detail.youtube_description
                    : "",
            showModalEditPub: true,
        });
    };
    handleModalEditPubClose = () => {
        this.setState({
            showModalEditPub: false,
            editUsername: "",
            editFullName: "",
            editEmail: "",
            editUserId: "",
            editPubBankName: "",
            editPubBankAccountNumber: "",
            editPubBeneficiaryName: "",
            editPubCategoryExperience: [],
            editPubCountryExperience: [],
            editPubMaxDailyConversions: "",
            editPubPhoneNumber: "",
            editPubZaloNumber: "",
            editPubFacebookProfileLink: "",
            editPubApproachCustomerWay: [],
            editPubTrafficSource: {},
            editPubWebsiteName: "",
            editPubWebsiteUrl: "",
            editPubWebsiteDescription: "",
            editPubSocialMediaName: {},
            editPubSocialMediaUseMethod: {},
            editPubSocialMediaUrl: "",
            editPubYoutubeName: "",
            editPubYoutubeUrl: "",
            editPubYoutubeDescription: "",
        });
    };
    handleModalPasswordOverwriteOpen = (id, name) => {
        this.setState({ showModalPasswordOverwrite: true, passwordOverwriteId: id, passwordOverwriteName: name });
    };
    handleModalPasswordOverwriteClose = () => {
        this.setState({ showModalPasswordOverwrite: false, passwordOverwriteId: "", passwordOverwriteName: "", passwordOverwrite: "" });  
    }
    handleSelectChange = (selectedOptions, event) => {
        this.setState({ [event.name]: selectedOptions });
    };
    editPub = () => {
        let {
            editUsername,
            editFullName,
            editEmail,
            editUserId,
            editPubBankName,
            editPubBankAccountNumber,
            editPubBeneficiaryName,
            editPubCategoryExperience,
            editPubCountryExperience,
            editPubMaxDailyConversions,
            editPubPhoneNumber,
            editPubZaloNumber,
            editPubFacebookProfileLink,
            editPubApproachCustomerWay,
            editPubTrafficSource,
            editPubWebsiteName,
            editPubWebsiteUrl,
            editPubWebsiteDescription,
            editPubSocialMediaName,
            editPubSocialMediaUseMethod,
            editPubSocialMediaUrl,
            editPubYoutubeName,
            editPubYoutubeUrl,
            editPubYoutubeDescription,
        } = this.state;
        if (
            !editUsername ||
            !editFullName ||
            !editEmail ||
            !editUserId ||
            !editPubBankName ||
            !editPubBankAccountNumber ||
            !editPubBeneficiaryName ||
            !editPubCategoryExperience ||
            editPubCategoryExperience.length === 0 ||
            !editPubCountryExperience ||
            editPubCountryExperience.length === 0 ||
            !editPubMaxDailyConversions ||
            !editPubPhoneNumber ||
            !editPubZaloNumber ||
            !editPubFacebookProfileLink ||
            !editPubApproachCustomerWay ||
            editPubApproachCustomerWay.length === 0 ||
            !editPubTrafficSource ||
            Object.keys(editPubTrafficSource).length === 0
        ) {
            alert("All fields are required");
            return false;
        }
        if (isNaN(parseInt(editPubMaxDailyConversions))) {
            alert("Max Daily Conversions must be a number");
            return false;
        }
        let trafficSourceDetail = "";
        if (editPubTrafficSource.value === "website") {
            if (!editPubWebsiteName || !editPubWebsiteUrl || !editPubWebsiteDescription) {
                alert("All fields are required");
                return false;
            }
            trafficSourceDetail = {
                website_name: editPubWebsiteName,
                website_url: editPubWebsiteUrl,
                website_description: editPubWebsiteDescription,
            };
        }
        if (editPubTrafficSource.value === "youtube_channel") {
            if (!editPubYoutubeName || !editPubYoutubeUrl || !editPubYoutubeDescription) {
                alert("All fields are required");
                return false;
            }
            trafficSourceDetail = {
                youtube_name: editPubYoutubeName,
                youtube_url: editPubYoutubeUrl,
                youtube_description: editPubYoutubeDescription,
            };
        }
        if (editPubTrafficSource.value === "social_media") {
            if (
                !editPubSocialMediaName ||
                Object.keys(editPubSocialMediaName).length === 0 ||
                !editPubSocialMediaUrl ||
                Object.keys(editPubSocialMediaUseMethod).length === 0 ||
                !editPubSocialMediaUseMethod
            ) {
                alert("All fields are required");
                return false;
            }
            trafficSourceDetail = {
                social_media_name: editPubSocialMediaName,
                social_media_use_method: editPubSocialMediaUseMethod,
                social_media_url: editPubSocialMediaUrl,
            };
        }
        if (editPubTrafficSource.value === "dont_have_traffic_source") {
            trafficSourceDetail = {};
        }
        if (!trafficSourceDetail) {
            alert("Something went wrong...");
            return false;
        }
        axios
            .put(
                `${process.env.REACT_APP_ADMIN_URL}/users/pub/update-info/${editUserId}`,
                {
                    username: editUsername,
                    email: editEmail,
                    fullName: editFullName,
                    bank_name: editPubBankName,
                    bank_account_number: editPubBankAccountNumber,
                    beneficiary_name: editPubBeneficiaryName,
                    category_experience: editPubCategoryExperience,
                    country_experience: editPubCountryExperience,
                    max_daily_conversions: editPubMaxDailyConversions,
                    phone_number: editPubPhoneNumber,
                    zalo_number: editPubZaloNumber,
                    facebook_profile_link: editPubFacebookProfileLink,
                    approach_customer_way: editPubApproachCustomerWay,
                    traffic_source: editPubTrafficSource,
                    traffic_source_detail: trafficSourceDetail,
                },
                {
                    cancelToken: this.signal.token,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "JWT " + localStorage.getItem("token_admin"),
                    },
                }
            )
            .then((res) => {
                alert(res.data.message);
                this.handleModalEditPubClose();
                this.getAllUsers();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    handlePasswordOverwriteSubmit = () => {
        if (this.state.passwordOverwrite === "") {
            alert("Please fill in password!");
            return false;
        }
        axios
            .patch(
                `${process.env.REACT_APP_ADMIN_URL}/users/change-password`,
                {
                    id: this.state.passwordOverwriteId,
                    new_password: this.state.passwordOverwrite
                },
                {
                    cancelToken: this.signal.token,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "JWT " + localStorage.getItem("token_admin"),
                    },
                }
            )
            .then((res) => {
                alert(res.data.message);
                this.handleModalPasswordOverwriteClose();
                this.getAllUsers();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    render() {
        const categoryExperienceOptions = [
            { label: "Adult", value: "adult" },
            { label: "Nutri (tpcn)", value: "nutri" },
            { label: "Medicine", value: "medicine" },
            { label: "Superstitions", value: "superstitions" },
            { label: "House Appliances", value: "house_appliances" },
            { label: "Others", value: "others" },
        ];
        const countryExperienceOptions = [
            { label: "Australia", value: "AU" },
            { label: "Brazil", value: "BR" },
            { label: "Cambodia", value: "KH" },
            { label: "Canada", value: "CA" },
            { label: "China", value: "CN" },
            { label: "Egypt", value: "EG" },
            { label: "Europe", value: "EU" },
            { label: "India", value: "IN" },
            { label: "Indonesia", value: "ID" },
            { label: "Japan", value: "JP" },
            { label: "Laos", value: "LA" },
            { label: "Malaysia", value: "MY" },
            { label: "Maldives", value: "MV" },
            { label: "Mexico", value: "MX" },
            { label: "Myanmar", value: "MM" },
            { label: "Nepal", value: "NP" },
            { label: "Nigeria", value: "NG" },
            { label: "New Zealand", value: "NZ" },
            { label: "Pakistan", value: "PK" },
            { label: "Philippines", value: "PH" },
            { label: "Qatar", value: "QA" },
            { label: "Russia", value: "RU" },
            { label: "Saudi Arabia", value: "SA" },
            { label: "Singapore", value: "SG" },
            { label: "South Africa", value: "ZA" },
            { label: "South Korea", value: "KR" },
            { label: "Sri Lanka", value: "LK" },
            { label: "Syria", value: "SY" },
            { label: "Taiwan", value: "TW" },
            { label: "Thailand", value: "TH" },
            { label: "Turkey", value: "TR" },
            { label: "Ukraine", value: "UA" },
            { label: "UNITED ARAB EMIRATES (UAE)", value: "AE" },
            { label: "United Kingdom (UK)", value: "GB" },
            { label: "United States of America (USA)", value: "USA" },
            { label: "Uzbekistan", value: "UZ" },
            { label: "Vietnam", value: "VN" },
        ];
        const approachCustomerOptions = [
            { label: "Use Pail Traffic: Facebook Ads, Google Ads, Zalo Ads, GDN,...", value: "pail_traffic" },
            { label: "Use Email, chatbot, sms,...", value: "email_chatbot" },
            { label: "You have traffic source from Website, Youtube,...", value: "have_traffic_from_web" },
            { label: "You are a KOL, you have many followers", value: "kol" },
            { label: "Cashback", value: "cashback" },
            { label: "Others", value: "others" },
            { label: "You haven't known how to sell products and you have made any profit yet", value: "unknown" },
        ];
        const trafficSourceOptions = [
            { label: "Website", value: "website" },
            { label: "Youtube Channel", value: "youtube_channel" },
            { label: "Social Media", value: "social_media" },
            { label: "You don't have any traffic sources", value: "dont_have_traffic_source" },
        ];
        let trafficSourceDetailForm = "";
        if (this.state.editPubTrafficSource.value === "website") {
            trafficSourceDetailForm = (
                <>
                    <div className="mt-3">
                        <span className="mr-1">Website Name:</span>
                        <input
                            required
                            name="editPubWebsiteName"
                            type="text"
                            value={this.state.editPubWebsiteName}
                            className="form-control search-input w-auto d-inline-block"
                            onChange={this.handleChange}
                            placeholder="Website Name..."
                        />
                    </div>
                    <div className="mt-3">
                        <span className="mr-1">Website URL:</span>
                        <input
                            required
                            name="editPubWebsiteUrl"
                            type="text"
                            value={this.state.editPubWebsiteUrl}
                            className="form-control search-input w-auto d-inline-block"
                            onChange={this.handleChange}
                            placeholder="Website URL..."
                        />
                    </div>
                    <div className="mt-3">
                        <span className="mr-1">Website Description:</span>
                        <input
                            required
                            name="editPubWebsiteDescription"
                            type="text"
                            value={this.state.editPubWebsiteDescription}
                            className="form-control search-input w-auto d-inline-block"
                            onChange={this.handleChange}
                            placeholder="Website Description..."
                        />
                    </div>
                </>
            );
        }
        if (this.state.editPubTrafficSource.value === "youtube_channel") {
            trafficSourceDetailForm = (
                <>
                    <div className="mt-3">
                        <span className="mr-1">Youtube Name:</span>
                        <input
                            required
                            name="editPubYoutubeName"
                            type="text"
                            value={this.state.editPubYoutubeName}
                            className="form-control search-input w-auto d-inline-block"
                            onChange={this.handleChange}
                            placeholder="Youtube Name..."
                        />
                    </div>
                    <div className="mt-3">
                        <span className="mr-1">Youtube URL:</span>
                        <input
                            required
                            name="editPubYoutubeUrl"
                            type="text"
                            value={this.state.editPubYoutubeUrl}
                            className="form-control search-input w-auto d-inline-block"
                            onChange={this.handleChange}
                            placeholder="Youtube URL..."
                        />
                    </div>
                    <div className="mt-3">
                        <span className="mr-1">Youtube Description:</span>
                        <input
                            required
                            name="editPubYoutubeDescription"
                            type="text"
                            value={this.state.editPubYoutubeDescription}
                            className="form-control search-input w-auto d-inline-block"
                            onChange={this.handleChange}
                            placeholder="Youtube Description..."
                        />
                    </div>
                </>
            );
        }
        if (this.state.editPubTrafficSource.value === "social_media") {
            trafficSourceDetailForm = (
                <>
                    <div className="mt-3">
                        <span className="mr-1">Social Media Name:</span>
                        <Select
                            required
                            name="editPubSocialMediaName"
                            isMulti={false}
                            options={[
                                { label: "Facebook", value: "facebook" },
                                { label: "Zalo", value: "zalo" },
                                { label: "Twitter", value: "twitter" },
                                { label: "Instagram", value: "instagram" },
                            ]}
                            value={this.state.editPubSocialMediaName}
                            className="devas-select border"
                            classNamePrefix="devas-select"
                            theme={selectTheme}
                            onChange={(selectedOptions, event) => {
                                this.handleSelectChange(selectedOptions, event);
                            }}
                        />
                    </div>
                    <div className="mt-3">
                        <span className="mr-1">Social Media Use Method:</span>
                        <Select
                            required
                            name="editPubSocialMediaUseMethod"
                            isMulti={false}
                            options={[
                                { label: "Use require payment Ads", value: "pay_ads" },
                                { label: "Share posts to Profile, Group, Fanpage", value: "share_post" },
                            ]}
                            value={this.state.editPubSocialMediaUseMethod}
                            className="devas-select border"
                            classNamePrefix="devas-select"
                            theme={selectTheme}
                            onChange={(selectedOptions, event) => {
                                this.handleSelectChange(selectedOptions, event);
                            }}
                        />
                    </div>
                    <span className="mr-1">Social Media URL:</span>
                    <input
                        required
                        name="editPubSocialMediaUrl"
                        type="text"
                        value={this.state.editPubSocialMediaUrl}
                        className="form-control search-input w-auto d-inline-block"
                        onChange={this.handleChange}
                        placeholder="Social Media URL..."
                    />
                </>
            );
        }
        let { role, username, password, newRole, email, fullName, search } = this.state;
        return (
            <MainAdmin history={this.props.history} linkTo={["/users"]} linkName={["Users"]} selectedSection={3}>
                <div className="col-12 col-lg-6 pb-3 py-0 py-lg-3">
                    <div className="row">
                        <div className="col-8 col-lg-7">
                            <div className="bg-menu">
                                <input
                                    type="text"
                                    className="form-control search-input"
                                    name="search"
                                    value={search}
                                    onChange={this.handleSearch}
                                    placeholder="Search Users"
                                />
                            </div>
                        </div>
                        <div className="col-4 col-lg-5">
                            <Dropdown
                                options={[
                                    { value: "", label: "User Role" },
                                    { value: "admin", label: "Admin" },
                                    { value: "pub", label: "Publisher" },
                                    { value: "adv", label: "Advertiser" },
                                    { value: "sale", label: "Sale" },
                                    { value: "bizdev", label: "Bizdev" },
                                    { value: "superPub", label: "Super Pub" },
                                    { value: "superAdv", label: "Super Adv" },
                                ]}
                                onChange={this.handleDropDown}
                                value={role}
                                placeholder="User Role"
                            />
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="py-3 bg-menu mb-1">
                        <div className="px-4 d-flex justify-content-between mb-2">
                            <h2 className="menu-h2-title">Manage Users</h2>
                            <div className="d-flex align-items-center">
                                <button
                                    className="btn button-devas d-inline-block mr-2"
                                    onClick={() => this.props.history.push("/users/pub/new-register")}
                                >
                                    Manage New Pub User
                                </button>
                                <button
                                    className="btn button-devas d-inline-block"
                                    onClick={() => this.setState({ showModal1: true })}
                                >
                                    Create New User
                                </button>
                            </div>
                        </div>
                        <DataTable
                            columns={columns}
                            data={this.state.currentUsersList}
                            pagination
                            theme="myTheme"
                            className="table"
                            customStyles={customStyles}
                            noHeader={true}
                            progressPending={this.state.isLoading}
                            highlightOnHover={true}
                            defaultSortField="order"
                            paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 500, 1000]}
                        />
                    </div>
                </div>
                <Modal
                    show={this.state.showModal1}
                    onHide={this.handleModal1Close}
                    className={`${localStorage.getItem("theme") === "light" ? "light-theme" : "dark-theme"} myModal`}
                    centered
                >
                    <Modal.Header closeButton>
                        <h2 className="menu-h2-title modal-title">Create New User</h2>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <span>Username:</span>
                            <input
                                name="username"
                                type="text"
                                value={username}
                                className="form-control search-input w-auto d-inline-block"
                                onChange={this.handleChange}
                                placeholder="Username"
                            />
                        </div>
                        <div className="mt-3">
                            <span className="mr-1">Password:</span>
                            <input
                                name="password"
                                type="text"
                                value={password}
                                className="form-control search-input w-auto d-inline-block"
                                onChange={this.handleChange}
                                placeholder="Password"
                            />
                        </div>
                        <div className="mt-3">
                            <span>Full Name:</span>
                            <input
                                name="fullName"
                                type="text"
                                value={fullName}
                                className="form-control search-input w-auto d-inline-block"
                                onChange={this.handleChange}
                                placeholder="Full Name"
                            />
                        </div>
                        <div className="mt-3">
                            <span>Email:</span>
                            <input
                                name="email"
                                type="text"
                                value={email}
                                className="form-control search-input w-auto d-inline-block"
                                onChange={this.handleChange}
                                placeholder="ex: user@gmail.com"
                            />
                        </div>
                        <div className="mt-3">
                            <span className="mr-1">Role:</span>
                            <Dropdown
                                options={[
                                    { value: "", label: "User Role" },
                                    { value: "admin", label: "Admin" },
                                    { value: "pub", label: "Publisher" },
                                    { value: "adv", label: "Advertiser" },
                                    { value: "bizdev", label: "Bizdev" },
                                    { value: "superPub", label: "Super Pub" },
                                    { value: "superAdv", label: "Super Adv" },
                                ]}
                                onChange={this.handleDropDownNewRole}
                                value={newRole}
                                placeholder="User Role"
                                className="d-inline-block"
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn button-devas-gray" onClick={this.handleModal1Close}>
                            Close
                        </button>
                        <button type="button" className="btn button-devas" onClick={this.createUser}>
                            Create User
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={this.state.showModal2}
                    onHide={this.handleModal2Close}
                    className={`${localStorage.getItem("theme") === "light" ? "light-theme" : "dark-theme"} myModal`}
                    centered
                >
                    <Modal.Header closeButton>
                        <h2 className="menu-h2-title modal-title">Edit User Children</h2>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="dropdown-form">
                            <Select
                                isMulti
                                options={this.state.childDropdownList}
                                className="devas-select"
                                classNamePrefix="devas-select"
                                onChange={this.handlePubSelectModal2}
                                theme={selectTheme}
                                value={this.state.modal2ChildSelect}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn button-devas-gray" onClick={this.handleModal2Close}>
                            Close
                        </button>
                        <button type="button" className="btn button-devas" onClick={this.saveUserChildren}>
                            Save
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={this.state.showModal3}
                    onHide={this.handleModal3Close}
                    className={`${localStorage.getItem("theme") === "light" ? "light-theme" : "dark-theme"} myModal`}
                    centered
                >
                    <Modal.Header closeButton>
                        <h2 className="menu-h2-title modal-title">Simualte View</h2>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <ol>
                                <li>Copy Token</li>
                                <li>Click Link (may have to logout if login menu doesn't appear)</li>
                                <li>
                                    Enter login menu username as <span className="color-primary">utoken</span>
                                </li>
                                <li>Paste token</li>
                            </ol>
                        </div>
                        <div className="mt-2">
                            <span>Username: {this.state.modal3Name}</span>
                        </div>
                        <div className="mt-2">
                            <span>Role: {this.state.modal3Role}</span>
                        </div>
                        <div className="mt-2 d-flex align-items-center">
                            <span>Token: </span>
                            <CopyToClipboard
                                text={this.state.modal3Token}
                                onCopy={() =>
                                    this.setState({ copied1: true }, () => {
                                        setTimeout(() => {
                                            this.setState({ copied1: false });
                                        }, 1500);
                                    })
                                }
                            >
                                <button className="btn button-devas d-flex align-items-center ml-1">
                                    Copy <FontAwesomeIcon icon={faCopy} className="ml-2" />
                                </button>
                            </CopyToClipboard>
                            <span
                                className={`medium-text text-green fade-out-transition ml-2 ${
                                    this.state.copied1 ? "opacity-1" : "opacity-0"
                                }`}
                            >
                                Copied.
                            </span>
                        </div>
                        <div className="mt-2">
                            Link:{" "}
                            <a target="_blank" href={this.state.modal3RedirectLink}>
                                Click here
                            </a>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn button-devas-gray" onClick={this.handleModal3Close}>
                            Close
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={this.state.showModalUserEdit}
                    onHide={this.handleModalUserEditClose}
                    className={`${localStorage.getItem("theme") === "light" ? "light-theme" : "dark-theme"} myModal`}
                    centered
                >
                    <Modal.Header closeButton>
                        <h2 className="menu-h2-title modal-title">Edit User</h2>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="">
                            <span className="mr-1">Username:</span>
                            <input
                                name="editUsername"
                                type="text"
                                value={this.state.editUsername}
                                className="form-control search-input w-auto d-inline-block"
                                onChange={this.handleChange}
                                placeholder="Username..."
                            />
                        </div>
                        <div className="mt-3">
                            <span className="mr-1">Full Name:</span>
                            <input
                                name="editFullName"
                                type="text"
                                value={this.state.editFullName}
                                className="form-control search-input w-auto d-inline-block"
                                onChange={this.handleChange}
                                placeholder="Full Name..."
                            />
                        </div>
                        <div className="mt-3">
                            <span className="mr-1">Email:</span>
                            <input
                                name="editEmail"
                                type="text"
                                value={this.state.editEmail}
                                className="form-control search-input w-auto d-inline-block"
                                onChange={this.handleChange}
                                placeholder="Email..."
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn button-devas-gray" onClick={this.handleModalUserEditClose}>
                            Close
                        </button>
                        <button type="button" className="btn button-devas" onClick={this.editUser}>
                            Edit User
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={this.state.showModalEditPub}
                    onHide={this.handleModalEditPubClose}
                    className={`${localStorage.getItem("theme") === "light" ? "light-theme" : "dark-theme"} myModal`}
                    centered
                >
                    <Modal.Header closeButton>
                        <h2 className="menu-h2-title modal-title">Edit User</h2>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="">
                            <span className="mr-1">Username:</span>
                            <input
                                required
                                name="editUsername"
                                type="text"
                                value={this.state.editUsername}
                                className="form-control search-input w-auto d-inline-block"
                                onChange={this.handleChange}
                                placeholder="Username..."
                            />
                        </div>
                        <div className="mt-3">
                            <span className="mr-1">Full Name:</span>
                            <input
                                required
                                name="editFullName"
                                type="text"
                                value={this.state.editFullName}
                                className="form-control search-input w-auto d-inline-block"
                                onChange={this.handleChange}
                                placeholder="Full Name..."
                            />
                        </div>
                        <div className="mt-3">
                            <span className="mr-1">Email:</span>
                            <input
                                required
                                name="editEmail"
                                type="text"
                                value={this.state.editEmail}
                                className="form-control search-input w-auto d-inline-block"
                                onChange={this.handleChange}
                                placeholder="Email..."
                            />
                        </div>
                        <div className="mt-3">
                            <span className="mr-1">Phone Number:</span>
                            <input
                                required
                                name="editPubPhoneNumber"
                                type="text"
                                value={this.state.editPubPhoneNumber}
                                className="form-control search-input w-auto d-inline-block"
                                onChange={this.handleChange}
                                placeholder="Phone Number..."
                            />
                        </div>
                        <div className="mt-3">
                            <span className="mr-1">Zalo Number:</span>
                            <input
                                required
                                name="editPubZaloNumber"
                                type="text"
                                value={this.state.editPubZaloNumber}
                                className="form-control search-input w-auto d-inline-block"
                                onChange={this.handleChange}
                                placeholder="Zalo Number..."
                            />
                        </div>
                        <div className="mt-3">
                            <span className="mr-1">Facebook Profile Link:</span>
                            <input
                                required
                                name="editPubFacebookProfileLink"
                                type="text"
                                value={this.state.editPubFacebookProfileLink}
                                className="form-control search-input w-auto d-inline-block"
                                onChange={this.handleChange}
                                placeholder="Facebook Profile Link..."
                            />
                        </div>
                        <div className="mt-3">
                            <span className="mr-1">Bank Name:</span>
                            <input
                                required
                                name="editPubBankName"
                                type="text"
                                value={this.state.editPubBankName}
                                className="form-control search-input w-auto d-inline-block"
                                onChange={this.handleChange}
                                placeholder="Bank Name..."
                            />
                        </div>
                        <div className="mt-3">
                            <span className="mr-1">Bank Account Number:</span>
                            <input
                                required
                                name="editPubBankAccountNumber"
                                type="text"
                                value={this.state.editPubBankAccountNumber}
                                className="form-control search-input w-auto d-inline-block"
                                onChange={this.handleChange}
                                placeholder="Bank Account Number..."
                            />
                        </div>
                        <div className="mt-3">
                            <span className="mr-1">Beneficiary Name:</span>
                            <input
                                required
                                name="editPubBeneficiaryName"
                                type="text"
                                value={this.state.editPubBeneficiaryName}
                                className="form-control search-input w-auto d-inline-block"
                                onChange={this.handleChange}
                                placeholder="Beneficiary Name..."
                            />
                        </div>
                        <div className="mt-3">
                            <span className="mr-1">Category Experience:</span>
                            <Select
                                required
                                name="editPubCategoryExperience"
                                isMulti={true}
                                options={categoryExperienceOptions}
                                value={this.state.editPubCategoryExperience}
                                className="devas-select border"
                                classNamePrefix="devas-select"
                                theme={selectTheme}
                                onChange={(selectedOptions, event) => {
                                    this.handleSelectChange(selectedOptions, event);
                                }}
                            />
                        </div>
                        <div className="mt-3">
                            <span className="mr-1">Country Experience:</span>
                            <Select
                                required
                                name="editPubCountryExperience"
                                isMulti={true}
                                options={countryExperienceOptions}
                                value={this.state.editPubCountryExperience}
                                className="devas-select border"
                                classNamePrefix="devas-select"
                                theme={selectTheme}
                                onChange={(selectedOptions, event) => {
                                    this.handleSelectChange(selectedOptions, event);
                                }}
                            />
                        </div>
                        <div className="mt-3">
                            <span className="mr-1">Max Daily Conversions:</span>
                            <input
                                required
                                name="editPubMaxDailyConversions"
                                type="text"
                                value={this.state.editPubMaxDailyConversions}
                                className="form-control search-input w-auto d-inline-block"
                                onChange={this.handleChange}
                                placeholder="Max Daily Conversions..."
                            />
                        </div>
                        <div className="mt-3">
                            <span className="mr-1">Approach Customer Ways:</span>
                            <Select
                                required
                                name="editPubApproachCustomerWay"
                                isMulti={true}
                                options={approachCustomerOptions}
                                value={this.state.editPubApproachCustomerWay}
                                className="devas-select border"
                                classNamePrefix="devas-select"
                                theme={selectTheme}
                                onChange={(selectedOptions, event) => {
                                    this.handleSelectChange(selectedOptions, event);
                                }}
                            />
                        </div>
                        <div className="mt-3">
                            <span className="mr-1">Traffic Source:</span>
                            <Select
                                required
                                name="editPubTrafficSource"
                                isMulti={false}
                                options={trafficSourceOptions}
                                value={this.state.editPubTrafficSource}
                                className="devas-select border"
                                classNamePrefix="devas-select"
                                theme={selectTheme}
                                onChange={(selectedOptions, event) => {
                                    this.handleSelectChange(selectedOptions, event);
                                }}
                            />
                        </div>
                        {trafficSourceDetailForm}
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn button-devas-gray" onClick={this.handleModalUserEditClose}>
                            Close
                        </button>
                        <button type="button" className="btn button-devas" onClick={this.editPub}>
                            Edit User
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={this.state.showModalPasswordOverwrite}
                    onHide={this.handleModalPasswordOverwriteClose}
                    className={`${localStorage.getItem("theme") === "light" ? "light-theme" : "dark-theme"} myModal`}
                    centered
                >
                    <Modal.Header closeButton>
                        <h2 className="menu-h2-title modal-title">Overwrite User Password</h2>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="">
                            <span className="mr-1">Username: {this.state.passwordOverwriteName}</span>
                        </div>
                        <div className="mt-3">
                            <span className="mr-1">User ID: {this.state.passwordOverwriteId}</span>
                        </div>
                        <div className="mt-3">
                            <span className="mr-1">New Password:</span>
                            <input
                                required
                                name="passwordOverwrite"
                                type="text"
                                value={this.state.passwordOverwrite}
                                className="form-control search-input w-auto d-inline-block"
                                onChange={this.handleChange}
                                placeholder="New Password..."
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn button-devas-gray" onClick={this.handleModalPasswordOverwriteClose}>
                            Close
                        </button>
                        <button type="button" className="btn button-devas" onClick={this.handlePasswordOverwriteSubmit}>
                            Overwrite Password
                        </button>
                    </Modal.Footer>
                </Modal>
            </MainAdmin>
        );
    }
}
