import React, { Component } from "react";
import Dropdown from "react-dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsersCog, faCopy, faTimes, faToggleOff, faToggleOn, faTasks } from "@fortawesome/free-solid-svg-icons";
import { faWindowRestore } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";

import MainAdmin from "./adminbase/MainAdmin";
import DataTable from "react-data-table-component";
import axios from "axios";
import Select from "react-select";
import { CopyToClipboard } from "react-copy-to-clipboard";

import redirectNoToken from "../helper/redirectNoToken";
import { customStyles } from "../style/table";
import Modal from "react-bootstrap/Modal";
import "../style/modal.css";
import "react-dropdown/style.css";
import "../style/dropdown.css";
import { selectTheme } from "../style/reactselect";

const columns = [
    {
        name: "Username",
        selector: "username",
        sortable: true,
        wrap: true,
    },
    {
        name: "Role",
        selector: "role",
        sortable: true,
        wrap: true,
    },
    {
        name: "Full Name",
        selector: "fullName",
        sortable: true,
        wrap: true,
    },
    {
        name: "Email",
        selector: "email",
        sortable: true,
        wrap: true,
    },
    {
        name: "Created Date",
        selector: "created_date",
        center: true,
        wrap: true,
    },
    {
        name: "Max Daily Conversions",
        selector: "max_daily_conversions",
        center: true,
        wrap: true,
    },
    {
        name: "Category",
        selector: "category_experience",
        center: true,
        wrap: true,
    },
    {
        name: "Country",
        selector: "country_experience",
        center: true,
        wrap: true,
    },
    {
        name: "Active",
        selector: "status",
        center: true,
    },
    {
        name: "Manage Pub Offer",
        selector: "pub_payout",
        center: true,
    },
];
const redirectViewLink = {
    admin: "https://admin.devas.network/login",
    pub: "https://pub.devas.network/login",
    adv: "https://crm.devas.network/adv/login",
    sale: "https://crm.devas.network/sale/login",
    superPub: "https://superpub.devas.network/login",
    superAdv: "https://superadv.devas.network/login",
    partner: "https://partner.devas.network/login",
};
export default class ManageNewPubUserAdmin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            role: { value: "", label: "User Role" },
            usersList: [],
            currentUsersList: [],
            isLoading: true,
            showModal1: false,
            showModal2: false,
            showModal3: false,
            username: "",
            password: "",
            fullName: "",
            newRole: { value: "", label: "User Role" },
            email: "",
            fullName: "",
            search: "",
            childDropdownList: [],
            modal2ChildSelect: [],
            modal2CurrentUser: "",
            modal3RedirectLink: "",
            modal3Role: "",
            modal3Name: "",
            modal3Token: "",
            copied1: false,
        };
    }

    componentDidMount() {
        document.title = "Manage Users";
        this.getAllUsers();
    }

    componentWillUnmount() {
        this.signal.cancel("Api is being canceled");
    }
    changeUserStatus = (status, userID) => {
        const data = {
            id: userID,
            status: status,
        };
        axios
            .patch(`${process.env.REACT_APP_ADMIN_URL}/users/change-status`, data, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            })
            .then((response) => {
                console.log(response.data);
                if (response.data.message === "failed") {
                    alert("Change status failed!");
                }
                this.getAllUsers();
            })
            .catch((error) => {
                alert("Something wrong happended! " + error.response.data.message);
            });
    };
    getAllUsers = () => {
        this.signal = axios.CancelToken.source();
        axios
            .get(`${process.env.REACT_APP_ADMIN_URL}/users/pub/new/register`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            })
            .then((response) => {
                let data = response.data.reverse().map((v) => {
                    return {
                        _id: v._id,
                        username: v.username,
                        role: v.role,
                        email: v.email,
                        fullName: v.fullName,
                        created_date: new Date(v.created_date).toLocaleString(),
                        max_daily_conversions: v.max_daily_conversions,
                        category_experience: (
                            <ul className="mb-0 table-ul">
                                {v.category_experience.map((category) => (
                                    <li>{category.label}</li>
                                ))}
                            </ul>
                        ),
                        country_experience: (
                            <ul className="mb-0 table-ul">
                                {v.country_experience.map((country) => (
                                    <li>{country.label}</li>
                                ))}
                            </ul>
                        ),
                        status:
                            v.status === "active" ? (
                                <FontAwesomeIcon
                                    icon={faToggleOn}
                                    size="lg"
                                    className="hover-primary"
                                    onClick={() => this.changeUserStatus("inactive", v._id)}
                                />
                            ) : (
                                <FontAwesomeIcon
                                    icon={faToggleOff}
                                    size="lg"
                                    className="hover-primary"
                                    onClick={() => this.changeUserStatus("active", v._id)}
                                />
                            ),
                        pub_payout: (
                            <Link to={`/users/permissions?id=${v._id}&name=${v.username}`} className="a-link">
                                <FontAwesomeIcon icon={faTasks} size="lg" className="hover-primary" />
                            </Link>
                        ),
                    };
                });
                this.setState(
                    {
                        usersList: data,
                        currentUsersList: data,
                        isLoading: false,
                    },
                    () => {
                        this.filterUsers();
                    }
                );
            })
            .catch((error) => {
                console.log(error);
                //this.props.history.push("/error?message=" + error.message);
            });
    };
    filterUsers = () => {
        this.setState((state, props) => {
            return {
                currentUsersList: state.usersList.filter(
                    (user) =>
                        user.role.indexOf(state.role.value) > -1 &&
                        (user.username.indexOf(state.search) > -1 || user._id.indexOf(state.search) > -1)
                ),
            };
        });
    };
    createUser = () => {
        if (
            this.state.username === "" ||
            this.state.password === "" ||
            this.state.email === "" ||
            this.state.newRole.value === "" ||
            this.state.fullName === ""
        ) {
            alert("Please fill in all fields!");
        } else if (this.state.password.length < 6) {
            alert("Password must have at least 6 characters!");
        } else if (this.state.email.indexOf("@") === -1) {
            alert("Email has wrong format!");
        } else {
            axios
                .post(
                    `${process.env.REACT_APP_ADMIN_URL}/users/pub/new/register`,
                    {
                        username: this.state.username,
                        password: this.state.password,
                        email: this.state.email,
                        role: this.state.newRole.value,
                        fullName: this.state.fullName,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "JWT " + localStorage.getItem("token_admin"),
                        },
                    }
                )
                .then((response) => {
                    this.handleModal1Close();
                    this.getAllUsers();
                })
                .catch((error) => {
                    alert("Something Wrong Happended!");
                    //this.props.history.push("/error?message=" + error.message);
                });
        }
    };
    saveUserChildren = () => {
        axios
            .patch(
                `${process.env.REACT_APP_ADMIN_URL}/users/${this.state.modal2CurrentUser}/childrens`,
                {
                    childrens: this.state.modal2ChildSelect.reduce((a, v) => {
                        a.push(v.value);
                        return a;
                    }, []),
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "JWT " + localStorage.getItem("token_admin"),
                    },
                }
            )
            .then((response) => {
                alert("Saved successfully!");
                this.getAllUsers();
            })
            .catch((error) => {
                alert("Something wrong happended! " + error.response.data.message);
            });
    };
    handleDropDown = (e) => {
        this.setState({ role: e }, () => {
            this.filterUsers();
        });
    };
    handleDropDownNewRole = (e) => {
        this.setState({ newRole: e });
    };
    handleModal1Close = () => {
        this.setState({
            showModal1: false,
            username: "",
            password: "",
            email: "",
            newRole: { value: "", label: "User Role" },
            fullName: "",
        });
    };
    handleModal2Close = () => {
        this.setState({
            showModal2: false,
            modal2ChildSelect: [],
            modal2CurrentUser: "",
        });
    };
    handleModal3Close = () => {
        this.setState({
            modal3RedirectLink: "",
            modal3Role: "",
            modal3Name: "",
            showModal3: false,
            modal3Token: "",
        });
    };
    handleModal1Open = () => {
        this.setState({ showModal1: true });
    };
    handleModal2Open = (id, children, type) => {
        let childDropdownList = this.state.usersList
            .filter((v) => v.role === (type === "superPub" ? "pub" : "adv"))
            .map((v) => {
                return { value: v._id, label: v.username };
            });
        let modal2ChildSelect = children.map((v) => {
            let ans = { value: v };
            this.state.usersList.forEach((user) => {
                if (user._id === v) ans.label = user.username;
            });
            return ans;
        });
        this.setState({ modal2ChildSelect, childDropdownList, showModal2: true, modal2CurrentUser: id });
    };
    handleModal3Open = (id, name, role) => {
        this.signal = axios.CancelToken.source();
        axios
            .get(`${process.env.REACT_APP_ADMIN_URL}/users/${id}/get-token`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            })
            .then((response) => {
                let data = response.data;
                this.setState({
                    modal3Role: role,
                    modal3Name: name,
                    modal3RedirectLink: redirectViewLink[role],
                    showModal3: true,
                    modal3Token: data.token,
                });
            })
            .catch((error) => {
                console.log(error);
                //this.props.history.push("/error?message=" + error.message);
            });
    };
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };
    handleSearch = (e) => {
        this.setState({ search: e.target.value }, () => this.filterUsers());
    };
    handlePubSelectModal2 = (modal2ChildSelect) => {
        this.setState({ modal2ChildSelect });
    };

    render() {
        let { role, username, password, newRole, email, fullName, search } = this.state;
        return (
            <MainAdmin
                history={this.props.history}
                linkTo={["/users", "/users/pub/new-register"]}
                linkName={["Users", "Manage New Pub Users"]}
                selectedSection={3}
            >
                <div className="col-12 col-lg-6 pb-3 py-0 py-lg-3">
                    <div className="row">
                        <div className="col-8 col-lg-7">
                            <div className="bg-menu">
                                <input
                                    type="text"
                                    className="form-control search-input"
                                    name="search"
                                    value={search}
                                    onChange={this.handleSearch}
                                    placeholder="Search Users"
                                />
                            </div>
                        </div>
                        <div className="col-4 col-lg-5">
                            <Dropdown
                                options={[
                                    { value: "", label: "User Role" },
                                    { value: "admin", label: "Admin" },
                                    { value: "pub", label: "Publisher" },
                                    { value: "adv", label: "Advertiser" },
                                    { value: "sale", label: "Sale" },
                                    { value: "partner", label: "Partner" },
                                    { value: "superPub", label: "Super Pub" },
                                    { value: "superAdv", label: "Super Adv" },
                                ]}
                                onChange={this.handleDropDown}
                                value={role}
                                placeholder="User Role"
                            />
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="py-3 bg-menu mb-1">
                        <div className="px-4 d-flex justify-content-between mb-2">
                            <h2 className="menu-h2-title">Manage New Pub Users</h2>
                            <div className="d-flex align-items-center"></div>
                        </div>
                        <DataTable
                            columns={columns}
                            data={this.state.currentUsersList}
                            pagination
                            theme="myTheme"
                            className="table"
                            customStyles={customStyles}
                            noHeader={true}
                            progressPending={this.state.isLoading}
                            highlightOnHover={true}
                            defaultSortField="order"
                            paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 500, 1000]}
                        />
                    </div>
                </div>
                <Modal
                    show={this.state.showModal1}
                    onHide={this.handleModal1Close}
                    className={`${localStorage.getItem("theme") === "light" ? "light-theme" : "dark-theme"} myModal`}
                    centered
                >
                    <Modal.Header closeButton>
                        <h2 className="menu-h2-title modal-title">Create New User</h2>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <span>Username:</span>
                            <input
                                name="username"
                                type="text"
                                value={username}
                                className="form-control search-input w-auto d-inline-block"
                                onChange={this.handleChange}
                                placeholder="Username"
                            />
                        </div>
                        <div className="mt-3">
                            <span className="mr-1">Password:</span>
                            <input
                                name="password"
                                type="text"
                                value={password}
                                className="form-control search-input w-auto d-inline-block"
                                onChange={this.handleChange}
                                placeholder="Password"
                            />
                        </div>
                        <div className="mt-3">
                            <span>Full Name:</span>
                            <input
                                name="fullName"
                                type="text"
                                value={fullName}
                                className="form-control search-input w-auto d-inline-block"
                                onChange={this.handleChange}
                                placeholder="Full Name"
                            />
                        </div>
                        <div className="mt-3">
                            <span>Email:</span>
                            <input
                                name="email"
                                type="text"
                                value={email}
                                className="form-control search-input w-auto d-inline-block"
                                onChange={this.handleChange}
                                placeholder="ex: user@gmail.com"
                            />
                        </div>
                        <div className="mt-3">
                            <span className="mr-1">Role:</span>
                            <Dropdown
                                options={[
                                    { value: "", label: "User Role" },
                                    { value: "admin", label: "Admin" },
                                    { value: "pub", label: "Publisher" },
                                    { value: "adv", label: "Advertiser" },
                                    { value: "partner", label: "Partner" },
                                    { value: "superPub", label: "Super Pub" },
                                    { value: "superAdv", label: "Super Adv" },
                                ]}
                                onChange={this.handleDropDownNewRole}
                                value={newRole}
                                placeholder="User Role"
                                className="d-inline-block"
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn button-devas-gray" onClick={this.handleModal1Close}>
                            Close
                        </button>
                        <button type="button" className="btn button-devas" onClick={this.createUser}>
                            Create User
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={this.state.showModal2}
                    onHide={this.handleModal2Close}
                    className={`${localStorage.getItem("theme") === "light" ? "light-theme" : "dark-theme"} myModal`}
                    centered
                >
                    <Modal.Header closeButton>
                        <h2 className="menu-h2-title modal-title">Edit User Children</h2>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="dropdown-form">
                            <Select
                                isMulti
                                options={this.state.childDropdownList}
                                className="devas-select"
                                classNamePrefix="devas-select"
                                onChange={this.handlePubSelectModal2}
                                theme={selectTheme}
                                value={this.state.modal2ChildSelect}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn button-devas-gray" onClick={this.handleModal2Close}>
                            Close
                        </button>
                        <button type="button" className="btn button-devas" onClick={this.saveUserChildren}>
                            Save
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={this.state.showModal3}
                    onHide={this.handleModal3Close}
                    className={`${localStorage.getItem("theme") === "light" ? "light-theme" : "dark-theme"} myModal`}
                    centered
                >
                    <Modal.Header closeButton>
                        <h2 className="menu-h2-title modal-title">Simualte View</h2>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <ol>
                                <li>Copy Token</li>
                                <li>Click Link (may have to logout if login menu doesn't appear)</li>
                                <li>
                                    Enter login menu username as <span className="color-primary">utoken</span>
                                </li>
                                <li>Paste token</li>
                            </ol>
                        </div>
                        <div className="mt-2">
                            <span>Username: {this.state.modal3Name}</span>
                        </div>
                        <div className="mt-2">
                            <span>Role: {this.state.modal3Role}</span>
                        </div>
                        <div className="mt-2 d-flex align-items-center">
                            <span>Token: </span>
                            <CopyToClipboard
                                text={this.state.modal3Token}
                                onCopy={() =>
                                    this.setState({ copied1: true }, () => {
                                        setTimeout(() => {
                                            this.setState({ copied1: false });
                                        }, 1500);
                                    })
                                }
                            >
                                <button className="btn button-devas d-flex align-items-center ml-1">
                                    Copy <FontAwesomeIcon icon={faCopy} className="ml-2" />
                                </button>
                            </CopyToClipboard>
                            <span
                                className={`medium-text text-green fade-out-transition ml-2 ${
                                    this.state.copied1 ? "opacity-1" : "opacity-0"
                                }`}
                            >
                                Copied.
                            </span>
                        </div>
                        <div className="mt-2">
                            Link:{" "}
                            <a target="_blank" href={this.state.modal3RedirectLink}>
                                Click here
                            </a>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn button-devas-gray" onClick={this.handleModal3Close}>
                            Close
                        </button>
                    </Modal.Footer>
                </Modal>
            </MainAdmin>
        );
    }
}
