import React, {Component} from "react";
import CanvasJS from "../../helper/canvasjs/canvasjs.react";
import "../../style/dashboard.css";

const CanvasJSChart = CanvasJS.CanvasJSChart;
CanvasJS.CanvasJS.addColorSet("devas", [
    //colorSet Array
    "#ff8f39",
    "#ee1280",
    "#e52d27",
    "#ab4199",
    "#2f80ed",
    "#27ae60",
    "#ff0000",
    "#420420",
    "#0000ff",
    "#ffff00",
    "#800000",
    "#660066",
    "#990000",
    "#000080",
    "#8b0000",

]);

export default class ConversionsStatisticsTwoHours extends Component {
    render() {
        const options = {
            backgroundColor: localStorage.getItem("theme") === "dark" ? "#262B4B" : "#ffffff",
            responsive: true,
            colorSet: "devas",
            theme: localStorage.getItem("theme") === "dark" ? "dark1" : "light1",
            animationEnabled: true,
            exportEnabled: true,
            // title: {
            //     text: "Conversions created each 2 hours statistics",
            // },
            axisY:{
                title: "Conversions Count",
                gridThickness: 0,
            },
            toolTip: {
                shared: true,
            },
            data: [
                {
                    type: "column",
                    showInLegend: false,
                    toolTipContent: "<b>{label}h</b>:{y}",
                    indexLabelFormatter: (e) => {
                        return e.dataPoint.y
                    },
                    dataPoints: this.props.synthesizedConversions
                }
            ]
        }
        return (
            <div className="col-12 col-md-6 Statistics mb-3">
                <div className="py-3 bg-menu">
                    <div className="px-4 d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                            <h2 className="menu-h2-title mb-0">Conversions Created Each 2 Hours Statistics</h2>
                        </div>
                    </div>
                    <div className="px-4 pt-3">
                        <CanvasJSChart
                            options={options}
                            /* onRef = {ref => this.chart = ref} */
                        />
                        <div className="block-trial"></div>
                    </div>
                </div>
            </div>
        )
    }
}