import Axios from "axios";
import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { customStyles } from "../../../style/table";

const columns = [
    {
        name: "Publisher",
        selector: "publisher.username",
        sortable: true,
        wrap: true,
    },
    {
        name: "Pub Bizdev",
        selector: "bizdevPub.username",
        sortable: true,
        wrap: true,
    },
    {
        name: "Pub Margin Percentage",
        selector: "pubKpiPercentage",
        sortable: true,
        wrap: true,
    },
    {
        name: "Offer",
        selector: "offer.alias",
        sortable: true,
        wrap: true,
    },
    {
        name: "Offer Bizdev",
        selector: "bizdevOffer.username",
        sortable: true,
        wrap: true,
    },
    {
        name: "Offer Margin Percentage",
        selector: "offerKpiPercentage",
        sortable: true,
        wrap: true,
    },
];

export default class KpiList extends Component {
    state = {
        kpiList: [],
        isLoading: true,
    };
    componentDidMount() {
        this.signal = Axios.CancelToken.source();
        this.getMissingKpi();
    }
    componentWillUnmount() {
        this.signal.cancel("Api is being canceled");
    }
    getMissingKpi = () => {
        Axios.get(`${process.env.REACT_APP_ADMIN_URL}/kpi`, {
            cancelToken: this.signal.token,
            headers: {
                "Content-Type": "application/json",
                Authorization: "JWT " + localStorage.getItem("token_admin"),
            },
        })
            .then((response) => {
                this.setState({ kpiList: response.data.reverse(), isLoading: false });
            })
            .catch((error) => {
                //this.props.history.push("/error?message=" + error.message);
            });
    };
    render() {
        return (
            <div className="col-12 col-lg-6 Statistics">
                <div className="py-3 bg-menu">
                    <div className="px-4 d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                            <h2 className="menu-h2-title mb-0">All Deal</h2>
                        </div>
                    </div>
                </div>
                <DataTable
                    columns={columns}
                    data={this.state.kpiList}
                    pagination
                    theme="myTheme"
                    className="table"
                    customStyles={customStyles}
                    noHeader={true}
                    progressPending={this.state.isLoading}
                    highlightOnHover={true}
                    defaultSortField="order"
                    defaultSortAsc={false}
                    paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 500, 1000]}
                />
            </div>
        );
    }
}
