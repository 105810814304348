import React, { Component } from "react"

import DataTable from "react-data-table-component"
import { ExportExcel } from "../../helper/ExportExcel"
import { customStyles } from "../../style/table"

const columns = [
    {
        name: "Order Id",
        selector: "order_id",
        maxWidth: "70px",
        sortable: true,
        center: true,
    },
    {
        name: "Publisher",
        selector: "pub",
        sortable: true,
        wrap: true,
    },
    {
        name: "Offer Name",
        selector: "offer",
        sortable: true,
        wrap: true,
    },
    {
        name: "Adv",
        selector: "adv",
        sortable: true,
        wrap: true,
    },
    {
        name: "isCombo",
        selector: "isComboText",
        sortable: true,
        wrap: true,
    },
    {
        name: "Combo Alias",
        selector: "comboAlias",
        sortable: true,
        wrap: true,
    },
    {
        name: "Status",
        selector: "status",
        sortable: true,
        wrap: true,
        conditionalCellStyles: [
            {
                when: (row) => row.status === "approved",
                style: {
                    color: "var(--green)",
                },
            },
            {
                when: (row) => row.status === "new" || row.status === "callbacks",
                style: {
                    color: "var(--orange)",
                },
            },
            {
                when: (row) => row.status === "customer_reject",
                style: {
                    color: "var(--red)",
                },
            },
            {
                when: (row) => row.status === "duplicated" || row.status === "trash",
                style: {
                    color: "var(--blue)",
                },
            },
        ],
    },
    {
        name: "Pub Status",
        selector: "pubStatus",
        sortable: true,
        wrap: true,
        conditionalCellStyles: [
            {
                when: (row) => row.pubStatus === "approved",
                style: {
                    color: "var(--green)",
                },
            },
            {
                when: (row) => row.pubStatus === "new" || row.pubStatus === "callbacks",
                style: {
                    color: "var(--orange)",
                },
            },
            {
                when: (row) => row.pubStatus === "customer_reject",
                style: {
                    color: "var(--red)",
                },
            },
            {
                when: (row) => row.pubStatus === "duplicated" || row.pubStatus === "trash",
                style: {
                    color: "var(--blue)",
                },
            },
        ],
    },
    {
        name: "Use Delivery Status",
        selector: "useDeliveryStatus",
        sortable: true,
        wrap: true,
    },
    {
        name: "Delivery Status",
        selector: "delivery_status",
        sortable: true,
        wrap: true,
    },
    {
        name: "Delivery Code",
        selector: "delivery_code",
        sortable: true,
        wrap: true,
    },
    {
        name: "Submit Count",
        selector: "submitCount",
        sortable: true,
        wrap: true,
    },
    {
        name: "Name",
        selector: "name",
        sortable: true,
        wrap: true,
    },
    {
        name: "Phone",
        selector: "phone",
        sortable: true,
        wrap: true,
    },
    {
        name: "Address",
        selector: "address",
        sortable: true,
        wrap: true,
    },
    {
        name: "Adv Payout Detail",
        selector: "incomeDetail",
        sortable: true,
        wrap: true,
    },
    {
        name: "Adv Payout",
        selector: "income",
        sortable: true,
        wrap: true,
    },
    {
        name: "Pub Payout",
        selector: "payoutPub",
        sortable: true,
        wrap: true,
    },
    {
        name: "Extra Fee",
        selector: "extraFee",
        sortable: true,
        wrap: true,
    },
    {
        name: "Is Paid",
        selector: "is_paid",
        sortable: true,
        wrap: true,
    },
    {
        name: "Is Home",
        selector: "is_home",
        sortable: true,
        wrap: true,
    },
    {
        name: "Sale Log",
        selector: "sale_message",
        sortable: true,
        wrap: true,
    },
    {
        name: "Sale Log Pub",
        selector: "sale_message_pub",
        sortable: true,
        wrap: true,
    },
    {
        name: "Rejected Reason",
        selector: "rejectedMessageInfo",
        sortable: true,
        wrap: true,
    },
    {
        name: "Customer Message",
        selector: "message",
        sortable: true,
        wrap: true,
    },
    {
        name: "Bizdev Pub",
        selector: "bizdevPub",
        sortable: true,
        wrap: true,
    },
    {
        name: "Pub KPI",
        selector: "bizdevPubKPI",
        sortable: true,
        wrap: true,
    },
    {
        name: "Bizdev Offer",
        selector: "bizdevOffer",
        sortable: true,
        wrap: true,
    },
    {
        name: "Offer KPI",
        selector: "bizdevOfferKPI",
        sortable: true,
        wrap: true,
    },
    {
        name: "Created Date",
        selector: "created_date",
        sortable: true,
        wrap: true,
    },
    {
        name: "Modified Date",
        selector: "modified_date",
        sortable: true,
        wrap: true,
    },
    {
        name: "Quantity",
        selector: "quantity",
        sortable: true,
        wrap: true,
    },
    {
        name: "aff_sub5",
        selector: "aff_sub5",
        sortable: true,
        wrap: true,
    },
    {
        name: "Is Home",
        selector: "is_home",
        sortable: true,
        wrap: true,
    },
    {
        name: "Is Paid",
        selector: "is_paid",
        sortable: true,
        wrap: true,
    },
    {
        name: "Adv Invoice",
        selector: "adv_invoice",
        sortable: true,
        wrap: true,
    },
    {
        name: "Pub Invoice",
        selector: "pub_invoice",
        sortable: true,
        wrap: true,
    },
]
export default class ConversionList extends Component {
    render() {
        return (
            <>
                <div className="col-12 Statistics">
                    <div className="py-3 bg-menu">
                        <div className="px-4 d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                                <h2 className="menu-h2-title mb-0">Conversion List</h2>
                            </div>
                            <div className="d-flex align-items-center">
                                <button
                                    className="btn button-devas d-inline-block mr-2"
                                    onClick={this.props.submitRejectConversion}
                                >
                                    Submit Rejected Conversion
                                </button>
                                <button
                                    className="btn button-devas d-inline-block mr-2"
                                    onClick={this.props.submitDeliveryStatus}
                                >
                                    Submit Delivery Status
                                </button>
                                <button className="btn button-devas d-inline-block mr-2" onClick={this.props.uploadStatus}>
                                    Status File Upload
                                </button>
                                <button className="btn button-devas d-inline-block mr-2" onClick={this.props.editConversion}>
                                    Edit Conversion
                                </button>
                                <button
                                    className="btn button-devas d-inline-block mr-2"
                                    onClick={this.props.editConversionPayout}
                                >
                                    Edit Conversion Payout
                                </button>
                                <button
                                    className="btn button-devas d-inline-block mr-2"
                                    onClick={this.props.submitNewConversion}
                                >
                                    Submit Conversions
                                </button>
                                <button
                                    className="btn button-devas d-inline-block mr-2"
                                    onClick={this.props.anonymousConversions}
                                >
                                    Submit Anonymous Conversions
                                </button>
                                <button
                                    className="btn button-devas d-inline-block mr-2"
                                    onClick={this.props.search}
                                >
                                    Search Conversion
                                </button>
                                <ExportExcel
                                    csvData={[...this.props.conversions]}
                                    fileName={"AdminConversions_" + new Date().toLocaleDateString()}
                                />
                            </div>
                        </div>
                    </div>
                    <DataTable
                        columns={columns}
                        data={this.props.conversions}
                        pagination
                        theme="myTheme"
                        className="table"
                        customStyles={customStyles}
                        noHeader={true}
                        progressPending={this.props.isLoading}
                        highlightOnHover={true}
                        defaultSortField="order"
                        defaultSortAsc={false}
                        paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 500, 1000]}
                    />
                </div>
            </>
        )
    }
}
