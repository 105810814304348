import React, { Component } from "react";
import moment from "moment";
import CanvasJS from "../../helper/canvasjs/canvasjs.react";
import parseDate from "../../helper/parseDate";
import "../../style/dashboard.css";

const CanvasJSChart = CanvasJS.CanvasJSChart;
CanvasJS.CanvasJS.addColorSet("devas", [
    //colorSet Array
    "#ff8f39",
    "#ee1280",
    "#e52d27",
    "#ab4199",
    "#2f80ed",
    "#27ae60",
]);
export default class RejectedChart extends Component {
    render() {
        const options = {
            backgroundColor: localStorage.getItem("theme") === "dark" ? "#262B4B" : "#ffffff",
            responsive: true,
            colorSet: "devas",
            theme: localStorage.getItem("theme") === "dark" ? "dark1" : "light1",
            animationEnabled: true,
            exportEnabled: true,
            title: {
                text: "",
            },
            axisX: {
                valueFormatString: "DD MMM",
            },
            axisY: {
                title: "",
            },
            toolTip: {
                shared: true,
            },
            legend: {
                verticalAlign: "top",
                horizontalAlign: "center",
                reversed: true,
                cursor: "pointer",
            },
            data: [
                {
                    type: "pie",
                    dataPoints: [
                        { indexLabel: "Cancel", y: this.props.synthesizedConversions.cancel },
                        { indexLabel: "Can't Connect", y: this.props.synthesizedConversions.cannot_connect },
                        { indexLabel: "Can't delivery", y: this.props.synthesizedConversions.cannot_delivery },
                        { indexLabel: "Just asking", y: this.props.synthesizedConversions.just_asking },
                        { indexLabel: "Price", y: this.props.synthesizedConversions.price },
                        { indexLabel: "Wrong Order", y: this.props.synthesizedConversions.wrong_order },
                        { indexLabel: "Others", y: this.props.synthesizedConversions.others },
                    ],
                },
            ],
        };
        return (
            <div className="col-12 col-md-6 Statistics mb-3">
                <div className="py-3 bg-menu">
                    <div className="px-4 d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                            <h2 className="menu-h2-title mb-0">Rejected Conversions</h2>
                        </div>
                    </div>
                    <div className="px-4 pt-3">
                        <CanvasJSChart
                            options={options}
                            /* onRef = {ref => this.chart = ref} */
                        />
                        <div className="block-trial"></div>
                    </div>
                </div>
            </div>
        );
    }
}
