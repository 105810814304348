import React, {Component} from "react";
import axios from "axios";
import Select from "react-select";
import DataTable from "react-data-table-component";
import "../style/dropdown.css";
import "../style/modal.css";
import {customStyles} from "../style/table";
import {selectTheme} from "../style/reactselect";
import MainAdmin from "./adminbase/MainAdmin";
import {Link} from "react-router-dom";
import {moneyFormat} from "../helper/helper";

const columns = [
    {
        name: "Offer Id",
        selector: "_id",
        minWidth: "210px",
        sortable: true,
        wrap: true,
    },
    {
        name: "Offer Name",
        selector: "name",
        sortable: true,
        center: true,
    },
    {
        name: "Offer Alias",
        selector: "alias",
        center: true,
        sortable: true,
    },
    {
        name: "Is Combo",
        selector: "isCombo",
        center: true,
        sortable: true,
    },
    {
        name: "Delete",
        selector: "delete",
        center: true,
        minWidth: "150px",
    },
];
export default class UserDetail extends Component {
    constructor(props) {
        super();
        this.state = {
            user: {},
            usersList: [],
            offerList: [],
            offer: {value: "", label: "Select an offer..."},
            invalidMsg: "",
            validMsg: "",
            pubOffer: [],
            isLoading: true
        };
        this.userId = props.match.params.id;
    }

    componentDidMount() {
        this.getAllUsers();
        this.getUserDetail();
        this.getAllOffer();
        this.getPubOffer();
    }

    getAllUsers = () => {
        this.signal = axios.CancelToken.source();
        axios
            .get(`${process.env.REACT_APP_ADMIN_URL}/users/all`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            })
            .then((response) => {
                let data = response.data.reverse();
                this.setState({
                    usersList: data,
                });
            })
            .catch((error) => {
                console.log(error);
                //this.props.history.push("/error?message=" + error.message);
            });
    };

    getUserDetail() {
        this.signal = axios.CancelToken.source();
        axios
            .get(`${process.env.REACT_APP_ADMIN_URL}/users/${this.userId}/detail`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            })
            .then((response) => {
                let user = response.data;
                this.setState({user});
            })
            .catch((error) => {
                this.props.history.push("/users");
            });
    }

    componentWillUnmount() {
        this.signal.cancel("Api is being canceled");
    }

    getAllOffer = async () => {
        this.signal = axios.CancelToken.source();
        axios
            .get(`${process.env.REACT_APP_ADMIN_URL}/offers`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            })
            .then((response) => {
                this.setState({
                    offerList: response.data.reverse().map((v) => {
                        return {label: v.alias, value: v._id};
                    }),
                });
            })
            .catch((error) => {
                let response = error;
                if (error.hasOwnProperty("response") && error.response.hasOwnProperty("data")) {
                    response = error.response.data.message;
                }
                this.setState({invalidMsg: response}, () => {
                    setTimeout(() => {
                        this.setState({invalidMsg: ""});
                    }, 1500);
                });
            });
    };
    handleChangeSelectOffer = (e) => {
        this.setState({offer: e});
    };
    getPubOffer = async () => {
        this.setState({offer: {value: "", label: "Select an offer..."}, pubOffer: [], isLoading: true});
        try {
            // fetch data from a url endpoint
            this.signal = axios.CancelToken.source();
            let res = await axios.get(`${process.env.REACT_APP_ADMIN_URL}/superpub/offers/${this.userId}`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            });
            this.setState({
                pubOffer: res.data[0].offers.reverse().map((v) => {
                    return {
                        _id: v._id,
                        name: v.name,
                        alias: v.alias,
                        delete: (
                            <button className="btn button-devas-medium" onClick={() => this.deletePubOffer(v._id)}>
                                Delete
                            </button>
                        ),
                        isCombo: v.isCombo ? "Yes" : "No",
                    };
                }),
                isLoading: false,
            });
        } catch (error) {
            console.log(error);
            let response = "There is an error!";
            if (error.hasOwnProperty("response") && error.response.hasOwnProperty("data")) {
                response = error.response.data.message;
            }
            this.setState({invalidMsg: response, isLoading: false}, () => {
                setTimeout(() => {
                    this.setState({invalidMsg: ""});
                }, 1500);
            });
            // appropriately handle the error
        }
    };
    createPubOffer = async () => {
        if (this.state.offer.value === "") {
            this.setState({invalidMsg: "Please select an offer!"}, () => {
                setTimeout(() => {
                    this.setState({invalidMsg: ""});
                }, 1500);
            });
        } else {
            try {
                this.signal = axios.CancelToken.source();
                let res = await axios.post(
                    `${process.env.REACT_APP_ADMIN_URL}/superpub/offers/${this.userId}`,
                    {
                        offer_id: this.state.offer.value,
                    },
                    {
                        cancelToken: this.signal.token,
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "JWT " + localStorage.getItem("token_admin"),
                        },
                    }
                );
                this.setState({validMsg: "Add Offer Successfully!", isLoading: false}, () => {
                    this.getPubOffer();
                    setTimeout(() => {
                        this.setState({validMsg: ""});
                    }, 1500);
                });
            } catch (error) {
                let response = "There is an error!";
                if (error.hasOwnProperty("response") && error.response.hasOwnProperty("data")) {
                    response = error.response.data.message;
                }
                this.setState({invalidMsg: response, isLoading: false}, () => {
                    setTimeout(() => {
                        this.setState({invalidMsg: ""});
                    }, 1500);
                });
                // appropriately handle the error
            }
        }
    };
    deletePubOffer = async (id) => {
        try {
            this.signal = axios.CancelToken.source();
            let res = await axios.delete(`${process.env.REACT_APP_ADMIN_URL}/superpub/offers/${this.userId}`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
                data: {
                    offer_id: id,
                },
            });
            this.setState({validMsg: "Delete Offer Successfully!", isLoading: false}, () => {
                this.getPubOffer();
                setTimeout(() => {
                    this.setState({validMsg: ""});
                }, 1500);
            });
        } catch (error) {
            let response = "There is an error!";
            if (error.hasOwnProperty("response") && error.response.hasOwnProperty("data")) {
                response = error.response.data.message;
            }
            this.setState({invalidMsg: response, isLoading: false}, () => {
                setTimeout(() => {
                    this.setState({invalidMsg: ""});
                }, 1500);
            });
            // appropriately handle the error
        }
    };

    render() {
        let {username, role, fullName, status, userChildren, _id} = this.state.user;
        return (
            <MainAdmin
                history={this.props.history}
                linkTo={["/users", `/users/${this.userId}/detail`]}
                linkName={["Users", username ? username : ""]}
                selectedSection={3}
            >
                <div className="col-12">
                    <div className="py-3 bg-menu mb-1">
                        <div className="px-4 d-flex justify-content-between mb-2">
                            <h2 className="menu-h2-title">User Detail</h2>
                        </div>
                        <div className="px-4">
                            <div>
                                <p>
                                    Username: <b>{username}</b>
                                </p>
                                <p>
                                    fullName: <b>{fullName}</b>
                                </p>
                                <p>
                                    Role: <b>{role}</b>
                                </p>
                                {role === "pub" ? (
                                    <p>
                                        Manage User Permissions:{" "}
                                        <Link to={`/users/permissions?id=${_id}&name=${username}`}
                                              className="hover-primary">
                                            Click here
                                        </Link>
                                    </p>
                                ) : (
                                    ""
                                )}
                                <p>
                                    status: <b>{status}</b>
                                </p>
                                {userChildren && userChildren.length != 0 ? (
                                    <>
                                        <p>User Children:</p>
                                        <ul>
                                            {userChildren.map((v, i) => {
                                                let user = this.state.usersList.filter((v1) => v1._id === v)[0];
                                                if (user) {
                                                    return (
                                                        <li key={i} className="mt-2">
                                                            <Link
                                                                to={`/users/permissions?id=${user._id}&name=${user.username}`}
                                                                className="a-link"
                                                            >
                                                                {user.username}
                                                            </Link>
                                                        </li>
                                                    );
                                                }
                                            })}
                                        </ul>
                                    </>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {(role === "superPub") ? (
                    <div className="col-12">
                        <div className="py-3 bg-menu mb-1">
                            <div className="px-4 d-flex justify-content-between mb-2">
                                <h2 className="menu-h2-title">User Permission</h2>
                            </div>
                            <div className="row px-4 pt-3">
                                <div className="col-9 px-1 px-sm-3 col-xl-6 dropdown-form">
                                    <Select
                                        name="offerId"
                                        options={this.state.offerList}
                                        className="devas-select"
                                        classNamePrefix="devas-select"
                                        onChange={this.handleChangeSelectOffer}
                                        theme={selectTheme}
                                        placeholder="Select an offer..."
                                        value={this.state.offer}
                                    />
                                    <div
                                        className={`text-red mt-2 fade-out-transition ${
                                            this.state.invalidMsg !== "" ? "opacity-1" : "opacity-0"
                                        }`}
                                    >
                                        {this.state.invalidMsg}
                                    </div>
                                    <div
                                        className={`text-green mt-2 fade-out-transition ${
                                            this.state.validMsg !== "" ? "opacity-1" : "opacity-0"
                                        }`}
                                    >
                                        {this.state.validMsg}
                                    </div>
                                </div>
                                <div className="col-3 py-1 py-sm-0 px-2 px-sm-0">
                                    <button className="btn button-devas-large" onClick={this.createPubOffer}>
                                        Add
                                    </button>
                                </div>
                            </div>
                            <div className="row px-0 pt-3">
                                <div className="col-12">
                                    <DataTable
                                        columns={columns}
                                        data={this.state.pubOffer}
                                        pagination
                                        theme="myTheme"
                                        className="table"
                                        customStyles={customStyles}
                                        noHeader={true}
                                        progressPending={this.state.isLoading}
                                        highlightOnHover={true}
                                        paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 500, 1000]}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                ) : ""}

            </MainAdmin>
        );
    }
}
