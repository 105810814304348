import React, {Component} from "react";
import DatePicker from "react-date-picker";

import MainAdmin from "./MainAdmin";
import ApprovedStatisticsChart from "./ApprovedStatisticsChart";
import "../../style/datepicker.css";
import Select from "react-select";
import LoadingScreen from "react-loading-screen";
import axios from "axios";
import moment from "moment";
import {selectTheme} from "../../style/reactselect";

import {off} from "codemirror/src/util/event";

export default class ApprovedConversionsStatistics extends Component {
    state = {
        date: new Date(),
        dataForChart: {},
        offersList: [],
        conversionsList: [],
        offers: [],
        conversions: [],
        isLoading: false,
    };

    componentDidMount() {
        this.getConversionList();
    }

    handleDateOnChange = (date) => {
        setTimeout(() => {
            if (date === null) {
                this.setState({date: new Date()}, () => this.getConversionList());
            } else {
                this.setState({date}, () => this.getConversionList());
            }
        }, 500);
    };
    getConversionList = () => {
        this.setState({isLoading: true, offers: []}, () => {
            this.signal = axios.CancelToken.source();
            let from_date = moment(this.state.date).startOf("date").unix() * 1000;
            let to_date = moment(this.state.date).endOf("date").unix() * 1000 + 999;
            axios
                .get(
                    `${
                        process.env.REACT_APP_ADMIN_URL
                    }/conversions?from_date=${from_date}&to_date=${to_date}&modified_date=${false}`,
                    {
                        cancelToken: this.signal.token,
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "JWT " + localStorage.getItem("token_admin"),
                        },
                    }
                )
                .then((res) => {
                    let offersList = res.data.map((offer) => {
                        return offer.offer.alias;
                    });
                    offersList = [...new Set(offersList)].map((v) => {
                        return {
                            value: v,
                            label: v,
                        };
                    });
                    this.setState({conversionsList: res.data, offersList, isLoading: false}, () => {
                        this.filterConversions();
                    });
                })
                .catch((err) => {
                    this.setState({isLoading: false});
                    console.log(err);
                });
        });
    };
    dataProcessingForChart = async () => {
        let conversions = [...this.state.conversions];
        let dataForChart = {};
        let groupConversionsByOfferAlias = await conversions.reduce((currRes, currVal) => {
            currRes[currVal.offer.alias] = currRes[currVal.offer.alias] || [];
            currRes[currVal.offer.alias].push(currVal);
            return currRes;
        }, Object.create(null));
        for (const [key, value] of Object.entries(groupConversionsByOfferAlias)) {
            dataForChart[key] = [];
            let approvedRateByDate = await this.approvedRateByDate(value);
            dataForChart[key].push(approvedRateByDate);
        }
        this.setState({dataForChart});
    };
    approvedRateByDate = async (conversions) => {
        let returnData = [];
        let d = new Date(this.state.date.getTime() + 1);
        let e = new Date();
        e.setHours(23, 59, 59, 999);
        const conversionsCount = conversions.length;
        let approvedCount,
            trashCount,
            dupCount = 0;
        for (d; d <= e; await d.setDate(d.getDate() + 1)) {
            let endOfDay = d.setHours(23, 59, 59, 999);
            if (conversionsCount > 0) {
                approvedCount = await conversions.filter((conversion) => {
                    return Date.parse(conversion.modified_date) <= endOfDay && conversion.status === "approved";
                }).length;
                trashCount = await conversions.filter((conversion) => {
                    return Date.parse(conversion.modified_date) <= endOfDay && conversion.status === "trash";
                }).length;
                dupCount = await conversions.filter((conversion) => {
                    return Date.parse(conversion.modified_date) <= endOfDay && conversion.status === "duplicated";
                }).length;
                await returnData.push({
                    x: new Date(d.getTime()),
                    y: approvedCount > 0 ? (approvedCount / (conversionsCount - trashCount - dupCount)) * 100 : 0,
                });
            } else {
                await returnData.push({
                    x: new Date(d.getTime()),
                    y: 0,
                });
            }
        }
        return returnData;
    };
    offerSelectOnChange = (offers) => {
        this.setState({offers}, () => this.filterConversions());
    };
    filterConversions = () => {
        let conversions = this.state.conversionsList.filter((conversion) => {
            return this.state.offers === null || this.state.offers.length === 0 || this.checkOfferInList(conversion.offer);
        });
        this.setState({conversions}, async () => {
            await this.dataProcessingForChart();
        });
    };
    checkOfferInList = (offer) => {
        return this.state.offers.some((v) => {
            return offer.alias === v.value;
        });
    };

    render() {
        return (
            <div className={`${localStorage.getItem("theme") === "light" ? "light-theme" : "dark-theme"}`}>
                <LoadingScreen
                    loading={this.state.isLoading}
                    bgColor="var(--bg-auth)"
                    spinnerColor="var(--primary)"
                    textColor="var(--text)"
                    logoSrc={process.env.PUBLIC_URL + "/devaslogo.png"}
                    text="Data is processing. This can take a while!"
                >
                    <MainAdmin
                        history={this.props.history}
                        linkTo={["/approved_conversions_statistic"]}
                        linkName={["Approved Conversions Statistics"]}
                        selectedSection={7}
                    >
                        <div className="col-12 container-fluid">
                            <div className="row pl-3">
                                <div className="col-6 col-md-3 bg-datepicker">
                                    <DatePicker value={this.state.date} onChange={this.handleDateOnChange}/>
                                </div>
                                <div className="col-6 col-md-3">
                                    <Select
                                        isMulti={true}
                                        options={this.state.offersList}
                                        placeholder="Offer Select"
                                        className="devas-select"
                                        classNamePrefix="devas-select"
                                        theme={selectTheme}
                                        value={this.state.offers}
                                        onChange={this.offerSelectOnChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="Dashboard">
                                <ApprovedStatisticsChart chartData={this.state.dataForChart}/>
                            </div>
                        </div>
                    </MainAdmin>
                </LoadingScreen>
            </div>
        );
    }
}
