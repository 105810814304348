import React, { Component } from "react";
import Dropdown from "react-dropdown";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import axios from "axios";
import MainAdmin from "./MainAdmin";
import { selectTheme } from "../../style/reactselect";
import "react-dropdown/style.css";
import "../../style/dropdown.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faToggleOff, faToggleOn } from "@fortawesome/free-solid-svg-icons";
import { TextField } from "@material-ui/core";

export default class OfferForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            category: [],
            adv: [],
            isLoadingCategory: false,
        };
    }

    componentDidMount() {
        this.getCategory();
        this.getAdvUsers();
    }

    getCategory = () => {
        this.signal = axios.CancelToken.source();
        axios
            .get(`${process.env.REACT_APP_ADMIN_URL}/categories`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            })
            .then((response) => {
                this.setState({
                    category: response.data.map((v) => {
                        return { value: v._id, label: v.name };
                    }),
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };
    getAdvUsers = () => {
        this.signal = axios.CancelToken.source();
        axios
            .get(`${process.env.REACT_APP_ADMIN_URL}/users/adv`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            })
            .then((response) => {
                this.setState({
                    adv: response.data.map((v) => {
                        return { value: v._id, label: v.username };
                    }),
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    componentWillUnmount() {
        this.signal.cancel("Api is being canceled");
    }

    handleCategory = (e) => {
        if (e.hasOwnProperty("__isNew__") && e.__isNew__) {
            this.setState({ isLoadingCategory: true }, () => {
                axios
                    .post(
                        `${process.env.REACT_APP_ADMIN_URL}/categories`,
                        { name: e.value },
                        {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: "JWT " + localStorage.getItem("token_admin"),
                            },
                        }
                    )
                    .then((response) => {
                        let data = response.data;
                        this.setState({ isLoadingCategory: false });
                        this.props.handleChangeName("category", { value: data._id, label: data.name });
                    })
                    .catch((error) => {
                        alert("There was a problem creating a new category!");
                        //this.props.history.push("/error?message=" + error.message);
                    });
            });
        } else {
            this.props.handleChangeName("category", e);
        }
    };
    handleAdv = (e) => {
        this.props.handleChangeName("adv", e);
    };
    handleStatus = (e) => {
        this.props.handleChangeName("status", e);
    };
    handleDeliveryStatus = (e) => {
        this.props.handleChangeName("useDeliveryStatus", e);
    };
    handleIsNewProcess = (e) => {
        this.props.handleChangeName("isNewProcess", e);
    };
    handleUseTelegramNoti = (e) => {
        this.props.handleChangeName("useTelegramNoti", e);
    }
    handleSubmit = (e) => {
        e.preventDefault();
        let {
            handleChangeName,
            name,
            alias,
            category,
            adv,
            price,
            payoutPub,
            income,
            geo,
            imgLink,
            status,
            approved_conversion_change_duration,
            combosList,
            currency,
            systemCurrency,
            extraFee,
        } = this.props;
        let temp1 = [name, alias, category, adv, geo, imgLink, status, currency, systemCurrency];
        let temp2 = [price, payoutPub, income, approved_conversion_change_duration, extraFee];
        if (this.hasEmptyValue(temp1) || this.hasEmptyValue(temp2)) {
            handleChangeName("invalidMsg", "Please fill in all required (*) fields!");
            return false;
        }
        if (!this.isAllNumber(temp2)) {
            handleChangeName("invalidMsg", "Some fields only allow number input!");
            return false;
        }
        if (this.props.isCombo) {
            if (!this.validateComboList(combosList)) {
                handleChangeName("invalidMsg", "Check all combo fields, combo alias can't be the same");
                return false;
            }
        }
        this.props.submitAction();
    };
    hasEmptyValue = (arr) => {
        return arr.some((v) => v === "");
    };
    isAllNumber = (arr) => {
        let reg = /^-?\d+\.?\d*$/;
        return arr.every((v) => v.match(reg));
    };
    comboHasEmptyValue = (combosList) => {
        return combosList.every((v) => {
            return (
                v.hasOwnProperty("name") &&
                v.hasOwnProperty("description") &&
                v.hasOwnProperty("payoutPub") &&
                v.hasOwnProperty("income") &&
                !isNaN(parseInt(v.payoutPub)) &&
                !isNaN(parseInt(v.income))
            );
        });
    };
    validateComboList = (comboList) => {
        let checkAlias = new Set();
        let hasAliasDup = comboList.some((obj) => {
            return checkAlias.size === checkAlias.add(obj.alias).size;
        });
        return !(comboList.length < this.props.numberOfCombos || !this.comboHasEmptyValue(comboList) || hasAliasDup);
    };

    render() {
        let {
            history,
            invalidMsg,
            validMsg,
            isNewOffer,
            handleChange,
            name,
            alias,
            category,
            adv,
            price,
            payoutPub,
            income,
            geo,
            imgLink,
            status,
            approved_conversion_change_duration,
            networkId,
            networkName,
            resourcesLink,
            details,
            extraFee,
            useDeliveryStatus,
            isNewProcess,
            useTelegramNoti,
        } = this.props;
        let inputClass = "";
        if (invalidMsg !== "") {
            inputClass = "is-invalid";
        }
        if (validMsg !== "") {
            inputClass = "is-valid";
        }
        let linkTo = isNewOffer
            ? ["/offers", "/offers/new"]
            : ["/offers", "/offers/" + this.props.match.params.alias, "/offers/" + this.props.match.params.alias + "/edit"];
        let linkName = isNewOffer ? ["Offers", "New Offer"] : ["Offers", this.props.match.params.alias, "Edit"];
        let numberOfComboInput = "";
        let comboList = [];
        if (this.props.isCombo) {
            numberOfComboInput = (
                <div className="mb-2">
                    <label>Number of Combo*</label>
                    <input
                        type="number"
                        className="form-control"
                        name="comboNumber"
                        aria-describedby="comboNumber"
                        value={this.props.numberOfCombos}
                        placeholder="Enter a value..."
                        onChange={(event) => this.props.numberOfComboOnChange(event.target.value)}
                    />
                </div>
            );
            if (this.props.numberOfCombos > 0) {
                for (let i = 0; i < this.props.numberOfCombos; i++) {
                    comboList.push(
                        <div className="mt-3">
                            <h4>Combo {i + 1}</h4>
                            <div className="mb-2">
                                <label>Name</label>
                                <input
                                    required
                                    className="form-control"
                                    type="text"
                                    placeholder={"Combo " + (i + 1) + " Name"}
                                    value={
                                        this.props.combosList[i] && this.props.combosList[i].hasOwnProperty("name")
                                            ? this.props.combosList[i].name
                                            : ""
                                    }
                                    onChange={(event) => this.props.onComboDetailChange(i, "name", event.target.value)}
                                />
                                <label>Alias</label>
                                <input
                                    required
                                    className="form-control"
                                    placeholder={"Combo " + (i + 1) + " Alias"}
                                    type="text"
                                    value={
                                        this.props.combosList[i] && this.props.combosList[i].hasOwnProperty("alias")
                                            ? this.props.combosList[i].alias
                                            : ""
                                    }
                                    onChange={(event) => this.props.onComboDetailChange(i, "alias", event.target.value)}
                                />

                                <label>Description</label>
                                <textarea
                                    required
                                    className="form-control"
                                    placeholder={"Combo " + (i + 1) + " Description"}
                                    value={
                                        this.props.combosList[i] && this.props.combosList[i].hasOwnProperty("description")
                                            ? this.props.combosList[i].description
                                            : ""
                                    }
                                    onChange={(event) => this.props.onComboDetailChange(i, "description", event.target.value)}
                                />
                                <label>Price</label>
                                <input
                                    required
                                    className="form-control"
                                    placeholder={"Combo " + (i + 1) + " Price"}
                                    type="number"
                                    value={
                                        this.props.combosList[i] && this.props.combosList[i].hasOwnProperty("price")
                                            ? this.props.combosList[i].price
                                            : ""
                                    }
                                    onChange={(event) => this.props.onComboDetailChange(i, "price", event.target.value)}
                                />
                                <label>Payout Pub</label>
                                <input
                                    required
                                    className="form-control"
                                    placeholder={"Combo " + (i + 1) + " Payout Pub"}
                                    type="number"
                                    value={
                                        this.props.combosList[i] && this.props.combosList[i].hasOwnProperty("payoutPub")
                                            ? this.props.combosList[i].payoutPub
                                            : ""
                                    }
                                    onChange={(event) => this.props.onComboDetailChange(i, "payoutPub", event.target.value)}
                                />
                                <label>Income</label>
                                <input
                                    required
                                    className="form-control"
                                    placeholder={"Combo " + (i + 1) + " Income"}
                                    type="number"
                                    value={
                                        this.props.combosList[i] && this.props.combosList[i].hasOwnProperty("income")
                                            ? this.props.combosList[i].income
                                            : ""
                                    }
                                    onChange={(event) => this.props.onComboDetailChange(i, "income", event.target.value)}
                                />
                            </div>
                        </div>
                    );
                }
            }
        }
        return (
            <MainAdmin history={history} linkTo={linkTo} linkName={linkName} selectedSection={2}>
                <div className="col-12 Postback">
                    <div className="py-3 bg-menu mb-1">
                        <div className="px-4 d-flex justify-content-between">
                            <h2 className="menu-h2-title">{isNewOffer ? "Create Offer" : "Edit Offer"}</h2>
                        </div>
                        <form onSubmit={this.handleSubmit} className="normal-form">
                            <div className="px-4 row mt-2">
                                <div className="col-12 col-md-9 col-xl-6">
                                    <div className="form-group">
                                        <div className={"mb-2 " + (this.props.disableChangeIsCombo ? "d-none" : "")}>
                                            <label className="mr-1">Is this offer is Combo Offer?</label>
                                            {this.props.isCombo ? (
                                                <FontAwesomeIcon
                                                    icon={faToggleOn}
                                                    size="lg"
                                                    className="hover-primary"
                                                    onClick={() => this.props.changeIsComboStatus(false)}
                                                />
                                            ) : (
                                                <FontAwesomeIcon
                                                    icon={faToggleOff}
                                                    size="lg"
                                                    className="hover-primary"
                                                    onClick={() => this.props.changeIsComboStatus(true)}
                                                />
                                            )}
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="url">Offer Name*</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="name"
                                                aria-describedby="name"
                                                value={name}
                                                onChange={handleChange}
                                                placeholder="Offer Name"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="url">Offer Alias*</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="alias"
                                                aria-describedby="alias"
                                                value={alias}
                                                onChange={handleChange}
                                                placeholder="Offer Alias"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="url">Currency*</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="currency"
                                                aria-describedby="currency"
                                                value={this.props.currency}
                                                onChange={handleChange}
                                                placeholder="Currency"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="url">Offer System Currency*</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="systemCurrency"
                                                aria-describedby="systemCurrency"
                                                value={this.props.systemCurrency}
                                                onChange={handleChange}
                                                placeholder="System currency for payout such as VND, USD, USDT,..."
                                            />
                                        </div>
                                        <div className="mb-2 dropdown-form">
                                            <label htmlFor="url">Offer Category*</label>
                                            <CreatableSelect
                                                defaultValue={""}
                                                isMulti={false}
                                                name="category"
                                                options={this.state.category}
                                                className="devas-select"
                                                classNamePrefix="devas-select"
                                                onChange={this.handleCategory}
                                                theme={selectTheme}
                                                value={category}
                                                isLoading={this.state.isLoadingCategory}
                                            />
                                        </div>
                                        <div className="mb-2 dropdown-form">
                                            <label htmlFor="url">ADV User*</label>
                                            <Select
                                                defaultValue="one"
                                                isMulti={false}
                                                name="offerId"
                                                options={this.state.adv}
                                                className="devas-select"
                                                classNamePrefix="devas-select"
                                                onChange={this.handleAdv}
                                                theme={selectTheme}
                                                value={adv}
                                            />
                                        </div>
                                        {!this.props.isCombo ? (
                                            <div className="mb-2">
                                                <label htmlFor="url">Offer Price*</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="price"
                                                    aria-describedby="price"
                                                    value={price}
                                                    onChange={handleChange}
                                                    placeholder="Offer Price"
                                                />
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        {!this.props.isCombo ? (
                                            <div className="mb-2">
                                                <label htmlFor="url">Offer Income*</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="income"
                                                    aria-describedby="income"
                                                    value={income}
                                                    onChange={handleChange}
                                                    placeholder="Offer Income"
                                                />
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        {!this.props.isCombo ? (
                                            <div className="mb-2">
                                                <label htmlFor="url">Pub Payout*</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="payoutPub"
                                                    aria-describedby="payoutPub"
                                                    value={payoutPub}
                                                    onChange={handleChange}
                                                    placeholder="Pub Payout"
                                                />
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        <div className="mb-2">
                                            <label htmlFor="url">Extra Fee*</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="extraFee"
                                                aria-describedby="extraFee"
                                                value={extraFee}
                                                onChange={handleChange}
                                                placeholder="Extra Fee"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="url">Geolocation*</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="geo"
                                                aria-describedby="geo"
                                                value={geo}
                                                onChange={handleChange}
                                                placeholder="ex: Hanoi - Vietnam"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="url">Img Link*</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="imgLink"
                                                aria-describedby="imgLink"
                                                value={imgLink}
                                                onChange={handleChange}
                                                placeholder="ex: https://example.com/image.png"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="url">Approved Conversion Change Duration*</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="approved_conversion_change_duration"
                                                aria-describedby="approved_conversion_change_duration"
                                                value={approved_conversion_change_duration}
                                                onChange={handleChange}
                                                placeholder="Number in Days"
                                            />
                                        </div>
                                        <div className="mb-2 dropdown-form">
                                            <label htmlFor="url">Status*</label>
                                            <Dropdown
                                                options={[
                                                    { value: "active", label: "active" },
                                                    { value: "inactive", label: "inactive" },
                                                ]}
                                                onChange={this.handleStatus}
                                                value={status}
                                                placeholder="Select..."
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="url">Offer Details (CPO, CPS, Sales working hours,...)</label>
                                            <textarea
                                                rows="5"
                                                className="form-control"
                                                name="details"
                                                aria-describedby="details"
                                                value={details}
                                                onChange={handleChange}
                                                placeholder="Offer Details..."
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="url">Resources Link</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="resourcesLink"
                                                aria-describedby="resourcesLink"
                                                value={resourcesLink}
                                                onChange={handleChange}
                                                placeholder="E.g: https://google.com"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="url">Network Id</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="networkId"
                                                aria-describedby="networkId"
                                                value={networkId}
                                                onChange={handleChange}
                                                placeholder="Network Id"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="url">Network Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="networkName"
                                                aria-describedby="networkName"
                                                value={networkName}
                                                onChange={handleChange}
                                                placeholder="Network Name"
                                            />
                                        </div>
                                        {numberOfComboInput}
                                        {comboList}
                                        {!isNewOffer && (
                                            <div className="mb-2 dropdown-form">
                                                <label htmlFor="url">Assign New Payout to All Publisher? *</label>
                                                <Dropdown
                                                    options={[
                                                        { value: false, label: "No" },
                                                        { value: true, label: "Yes" },
                                                    ]}
                                                    onChange={this.props.handleAssignPayout}
                                                    value={this.props.assignPayout}
                                                    placeholder="Select..."
                                                />
                                            </div>
                                        )}
                                        <div className="mb-2 dropdown-form">
                                            <label htmlFor="url">Use Delivery Status? *</label>
                                            <Select
                                                // defaultValue="one"
                                                isMulti={false}
                                                options={[
                                                    { label: "Yes", value: true },
                                                    { label: "No", value: false },
                                                ]}
                                                className="devas-select"
                                                classNamePrefix="devas-select"
                                                onChange={this.handleDeliveryStatus}
                                                theme={selectTheme}
                                                value={useDeliveryStatus}
                                            />
                                        </div>
                                        <div className="mb-2 dropdown-form">
                                            <label htmlFor="url">Is New CPO Process? *</label>
                                            <Select
                                                // defaultValue="one"
                                                isMulti={false}
                                                options={[
                                                    { label: "Yes", value: true },
                                                    { label: "No", value: false },
                                                ]}
                                                className="devas-select"
                                                classNamePrefix="devas-select"
                                                onChange={this.handleIsNewProcess}
                                                theme={selectTheme}
                                                value={isNewProcess}
                                            />
                                        </div>
                                        <div className="mb-2 dropdown-form">
                                            <label htmlFor="url">Use Telegram Notification? *</label>
                                            <Select
                                                // defaultValue="one"
                                                isMulti={false}
                                                options={[
                                                    { label: "Yes", value: true },
                                                    { label: "No", value: false },
                                                ]}
                                                className="devas-select"
                                                classNamePrefix="devas-select"
                                                onChange={this.handleUseTelegramNoti}
                                                theme={selectTheme}
                                                value={useTelegramNoti}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 form-group">
                                    <div className="d-flex align-items-center justify-content-center mt-4">
                                        <button
                                            type="button"
                                            className="btn button-devas-gray-large mr-4"
                                            onClick={() => history.push("/offers")}
                                        >
                                            Cancel
                                        </button>
                                        <button type="submit" className="btn button-devas-large">
                                            {isNewOffer ? "Create" : "Edit"}
                                        </button>
                                    </div>
                                    <input type="hidden" className={inputClass} />
                                    <div className="invalid-feedback mt-3 text-center">{invalidMsg}</div>
                                    <div className="valid-feedback mt-3 text-center">{validMsg}</div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </MainAdmin>
        );
    }
}
