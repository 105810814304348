import React, { Component } from "react";

export default class inputAssign extends Component {
    handleChange = (e) => {
        this.props.runFunction(e.target.value, this.props.index);
    };
    render() {
        return (
            <>
                <div className="mb-2">
                    <label htmlFor="url">{this.props.text}</label>
                    <input
                        type="text"
                        className="form-control"
                        value={this.props.value ? this.props.value : ""}
                        onChange={this.handleChange}
                        placeholder={this.props.placeholder}
                    />
                </div>
            </>
        );
    }
}
