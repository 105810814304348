import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import devaslogo from "../../img/devaslogo.svg";
import "../../style/NavbarBottomNav.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import {faTools, faBullseye} from "@fortawesome/free-solid-svg-icons";

const styles = {
    root: {
        zIndex: "1000000",
    },
    rootNav: {
        backgroundColor: "var(--bg-menu)",
    },
    rootBotNav: {
        width: "100%",
        position: "fixed",
        bottom: 0,
        backgroundColor: "var(--bg-menu)",
        boxShadow: "0px -2px 4px -1px rgba(0,0,0,0.2),0px -4px 5px 0px rgba(0,0,0,0.14),0px -1px 10px 0px rgba(0,0,0,0.12)",
    },
    menuButton: {
        marginRight: "2rem",
    },
    title: {
        flexGrow: 1,
    },
    menuItem: {
        "& div": {
            "& ul": {
                backgroundColor: !localStorage.getItem("theme")
                    ? window.innerWidth < 576
                        ? "#262b4b"
                        : "#ffffff"
                    : localStorage.getItem("theme") === "dark"
                    ? "#262b4b"
                    : "#ffffff",
                color: !localStorage.getItem("theme")
                    ? window.innerWidth < 576
                        ? "#dbd8e3"
                        : "#2f4858"
                    : localStorage.getItem("theme") === "dark"
                    ? "#dbd8e3"
                    : "#2f4858",
                fontFamily: '"Open Sans", sans-serif',
            },
        },
    },
    paper: {
        border: "1px solid black",
    },
};
class NavbarBottomNav extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: "",
            anchorEl: null,
            anchorEl1: null,
        };
    }
    componentDidMount() {
        this.valueTimeout = setTimeout(() => {
            this.setState({ value: this.props.selectedSection.toString() });
        }, 10);
    }
    handleChange = (e, v) => {
        console.log(v);
    };
    handleMenu = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };
    handleMenu1 = (event) => {
        this.setState({ anchorEl1: event.currentTarget });
    };
    handleClose = () => {
        this.setState({ anchorEl: null });
    };
    handleClose1 = () => {
        this.setState({ anchorEl1: null });
    };
    setTheme = (theme) => {
        localStorage.setItem("theme", theme);
        window.location.reload();
    };
    componentWillUnmount() {
        clearTimeout(this.valueTimeout);
    }
    render() {
        var { anchorEl, anchorEl1 } = this.state;
        const open = Boolean(anchorEl);
        const open1 = Boolean(anchorEl1);
        return (
            <div className=" d-lg-none navbar-mobile">
                <AppBar position="fixed" className={this.props.classes.rootNav}>
                    <Toolbar>
                        <div className="navbar-header d-flex align-items-center flex-grow-1">
                            <img src={devaslogo} className="header-logo" alt="Devas" onClick={this.goHome} />
                            <h1 className="header-text" onClick={this.goHome}>
                                Devas Manager<span className="version-text">{process.env.REACT_APP_VERSION}</span>
                            </h1>
                        </div>

                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={this.handleMenu}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            open={open}
                            onClose={this.handleClose}
                            className={this.props.classes.menuItem}
                        >
                            <MenuItem onClick={() => this.setTheme("light")}>Light Theme</MenuItem>
                            <MenuItem onClick={() => this.setTheme("dark")}>Dark Theme</MenuItem>
                            <MenuItem onClick={() => this.props.history.push("/logout")}>Logout</MenuItem>
                        </Menu>
                    </Toolbar>
                </AppBar>

                <BottomNavigation
                    showLabels={true}
                    value={this.state.value}
                    onChange={this.handleChange}
                    className={this.props.classes.rootBotNav}
                >
                    <BottomNavigationAction
                        onClick={() => this.props.history.push("/dashboard")}
                        label="Dashboard"
                        value="1"
                        icon={<i className={`sidebar-icon icon-home`}></i>}
                    />
                    <BottomNavigationAction
                        onClick={() => this.props.history.push("/approved_conversions_statistic")}
                        label="Approved Statistics"
                        value="7"
                        icon={
                            <FontAwesomeIcon
                                icon={faChartLine}
                                className={``}
                                data-toggle="tooltip"
                                data-placement="right"
                                title="Tooltip on right"
                                size="1x"
                            />
                        }
                    />
                    <BottomNavigationAction
                        onClick={() => this.props.history.push("/offers")}
                        label="Offers"
                        value="2"
                        icon={<i className={`sidebar-icon icon-offers`}></i>}
                    />
                    <BottomNavigationAction
                        onClick={() => this.props.history.push("/users")}
                        label="Manage Users"
                        value="3"
                        icon={<i className={`sidebar-icon icon-postback`}></i>}
                    />
                    <BottomNavigationAction
                        onClick={() => this.props.history.push("/users/permissions")}
                        label="User Permissions"
                        value="4"
                        icon={<i className={`sidebar-icon icon-tools`}></i>}
                    />
                    <BottomNavigationAction
                        onClick={() => this.props.history.push("/invoices")}
                        label="Invoices"
                        value="5"
                        icon={<i className={`sidebar-icon icon-invoice`}></i>}
                    />
                    <BottomNavigationAction
                        onClick={() => this.props.history.push("/banner_tool")}
                        label="Banner Tool"
                        value="8"
                        icon={
                            <FontAwesomeIcon
                                icon={faTools}
                                className={``}
                                data-toggle="tooltip"
                                data-placement="right"
                                title="Tooltip on right"
                                size="1x"
                            />
                        }
                    />
                    <BottomNavigationAction
                        onClick={() => this.props.history.push("/kpi")}
                        label="Bizdev KPI"
                        value="9"
                        icon={
                            <FontAwesomeIcon
                                icon={faBullseye}
                                className={``}
                                data-toggle="tooltip"
                                data-placement="right"
                                title="Tooltip on right"
                                size="1x"
                            />
                        }
                    />
                </BottomNavigation>
            </div>
        );
    }
}

export default withStyles(styles)(NavbarBottomNav);
