import React, { Component } from "react";
import Axios from "axios";
import DataTable from "react-data-table-component";
import { customStyles } from "../../../style/table";
import moment from "moment";
import DatePicker from "react-datepicker";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "react-datepicker/dist/react-datepicker.css";
import "../../../style/react-datepicker.css";
import "../../../style/daterangepicker.css";
import { moneyFormat } from "../../../helper/helper";
import Modal from "react-bootstrap/Modal";
import "../../../style/modal.css";

const columns = [
    {
        name: "User",
        selector: "kpiGoal.bizdev.username",
        sortable: true,
        wrap: true,
    },
    {
        name: "Actual",
        selector: "total",
        sortable: true,
        wrap: true,
    },
    {
        name: "Target",
        selector: "kpiGoal.goal",
        sortable: true,
        wrap: true,
    },
    {
        name: "Target %",
        selector: "kpiPercentage",
        sortable: true,
        wrap: true,
    },
    {
        name: "Approved",
        selector: "conversion_statistics.approved",
        sortable: true,
        wrap: true,
    },
    {
        name: "Callbacks",
        selector: "conversion_statistics.callbacks",
        sortable: true,
        wrap: true,
    },
    {
        name: "Customer Reject",
        selector: "conversion_statistics.customer_reject",
        sortable: true,
        wrap: true,
    },
    {
        name: "New",
        selector: "conversion_statistics.new",
        sortable: true,
        wrap: true,
    },
    {
        name: "Duplicated",
        selector: "conversion_statistics.duplicated",
        sortable: true,
        wrap: true,
    },
    {
        name: "trash",
        selector: "conversion_statistics.trash",
        sortable: true,
        wrap: true,
    },
];

const ExampleCustomInput = ({ value, onClick }) => (
    <button className="btn button-devas" onClick={onClick}>
        {"Current month/year: " + value}
    </button>
);

export default class KpiStatistics extends Component {
    state = {
        startDate: new Date(),
        showModal1: false,
        isLoading: true,
        date: [new Date(), new Date()],
        date1: [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date()],
    };
    componentDidMount() {
        this.signal = Axios.CancelToken.source();
        this.getData();
    }
    componentWillUnmount() {
        this.signal.cancel("Api is being canceled");
    }
    getData = () => {
        Axios.get(`${process.env.REACT_APP_ADMIN_URL}/kpi-goal/statistics`, {
            cancelToken: this.signal.token,
            headers: {
                "Content-Type": "application/json",
                Authorization: "JWT " + localStorage.getItem("token_admin"),
            },
            params: {
                month: moment(this.state.startDate).month() + 1,
                year: moment(this.state.startDate).year(),
                from_date: moment(this.state.date1[0]).startOf("date").unix() * 1000,
                to_date: moment(this.state.date1[1]).endOf("date").unix() * 1000 + 999,
            },
        })
            .then((response) => {
                let data = response.data;
                data = data.map((v) => {
                    v.total = moneyFormat(parseFloat(v.total), 0, 3) + "₫";
                    v.kpiGoal.goal = moneyFormat(parseFloat(v.kpiGoal.goal), 0, 3) + "₫";
                    v.kpiPercentage = v.kpiPercentage + "%";
                    return v;
                });
                this.setState({ data, isLoading: false });
            })
            .catch((error) => {
                //this.props.history.push("/error?message=" + error.message);
            });
    };
    handleModal1Close = () => {
        this.setState({ showModal1: false });
    };
    onChange = (date) => this.setState({ date });
    onChangeDate1 = (date1) =>
        this.setState({ date1, isLoading: true }, () => {
            this.getData();
        });
    handleReassign = () => {
        Axios.get(`${process.env.REACT_APP_ADMIN_URL}/kpi/reassign`, {
            cancelToken: this.signal.token,
            headers: {
                "Content-Type": "application/json",
                Authorization: "JWT " + localStorage.getItem("token_admin"),
            },
            params: {
                from_date: moment(this.state.date[0]).startOf("date").unix() * 1000,
                to_date: moment(this.state.date[1]).endOf("date").unix() * 1000 + 999,
            },
        })
            .then((response) => {
                alert("Reassigning! This might take a while!");
                this.setState({ showModal1: false, date: [new Date(), new Date()] });
            })
            .catch((error) => {
                console.log(error.response.data);
                alert("Some mistakes occured!");
                //this.props.history.push("/error?message=" + error.message);
            });
    };
    render() {
        return (
            <div className="col-12 Statistics mb-3">
                <div className="py-3 bg-menu">
                    <div className="px-4 d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                            <h2 className="menu-h2-title mb-0">Bizdev KPI</h2>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                            <button
                                className="btn button-devas mr-2"
                                onClick={() => {
                                    this.setState({ showModal1: true });
                                }}
                            >
                                Reassign KPI Conversions
                            </button>
                            <DatePicker
                                selected={this.state.startDate}
                                onChange={(date) => {
                                    this.setState({ startDate: date, isLoading: true }, () => {
                                        this.getData();
                                    });
                                }}
                                customInput={<ExampleCustomInput />}
                                dateFormat="MM/yyyy"
                                showMonthYearPicker
                            />
                            <div className="d-flex align-items-center bg-daterangepicker">
                                <DateRangePicker onChange={this.onChangeDate1} value={this.state.date1} />
                            </div>
                        </div>
                    </div>
                </div>
                <DataTable
                    columns={columns}
                    data={this.state.data}
                    pagination
                    theme="myTheme"
                    className="table"
                    customStyles={customStyles}
                    noHeader={true}
                    progressPending={this.state.isLoading}
                    highlightOnHover={true}
                    defaultSortField="order"
                    defaultSortAsc={false}
                    paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 500, 1000]}
                />
                <Modal
                    show={this.state.showModal1}
                    onHide={this.handleModal1Close}
                    className={`${localStorage.getItem("theme") === "light" ? "light-theme" : "dark-theme"} myModal`}
                    centered
                >
                    <Modal.Header closeButton>
                        <h2 className="menu-h2-title modal-title">Reassign KPI Conversions</h2>
                    </Modal.Header>
                    <Modal.Body>
                        <div>Created Date: </div>
                        <div className="d-flex align-items-center bg-daterangepicker">
                            <DateRangePicker onChange={this.onChange} value={this.state.date} />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn button-devas-gray" onClick={this.handleModal1Close}>
                            Cancel
                        </button>
                        <button type="button" className="btn button-devas" onClick={this.handleReassign}>
                            Reassign
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
