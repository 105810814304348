import React, { Component } from "react";
import Select from "react-select";
import axios from "axios";
import DataTable from "react-data-table-component";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import queryString from "query-string";
import { moneyFormat } from "../helper/helper";

import Main from "./adminbase/MainAdmin";
import redirectNoToken from "../helper/redirectNoToken";
import { selectTheme } from "../style/reactselect";
import { customStyles } from "../style/table";
import "../style/dropdown.css";
import "../style/modal.css";
import { cssNumber } from "jquery";

const columns = [
    {
        name: "Offer Id",
        selector: "_id",
        minWidth: "210px",
        sortable: true,
        wrap: true,
    },
    {
        name: "Offer Name",
        selector: "name",
        sortable: true,
        center: true,
    },
    {
        name: "Offer Alias",
        selector: "alias",
        center: true,
        sortable: true,
    },
    {
        name: "Pub Payout",
        selector: "payoutPub",
        center: true,
        sortable: true,
        wrap: true,
    },
    {
        name: "Is Combo",
        selector: "isCombo",
        center: true,
        sortable: true,
    },
    {
        name: "Edit Pub Payout",
        selector: "edit",
        center: true,
        sortable: true,
    },
    {
        name: "Delete",
        selector: "delete",
        center: true,
        minWidth: "150px",
    },
];
export default class UserPermissionsAdmin extends Component {
    constructor(props) {
        super();
        this.state = {
            pub: { value: "", label: "Select a publisher..." },
            offer: { value: "", label: "Select an offer..." },
            pubUsersList: [],
            isLoading: true,
            offerList: [],
            pubOffer: [],
            invalidMsg: "",
            validMsg: "",
            invalidMsg1: "",
            validMsg1: "",
            currentPayout: {},
            currentOffer: {},
            newPayout: "",
            showModal1: false,
            template: { value: "", label: "Select a template..." },
            templateList: [],
        };
    }

    componentDidMount() {
        document.title = "Manage Users";
        this.getAllPubUsers();
        this.getAllOffer();
        this.getOfferTemplate();
    }

    componentDidUpdate(preProps, preState) {
        if (this.state.pub.value === "") {
            let query = queryString.parse(this.props.location.search);
            if (query.name && query.id && this.state.pubUsersList.length != 0 && this.state.offerList.length != 0) {
                this.setState({ pub: { value: query.id, label: query.name } }, () => this.getPubOffer());
            }
        }
    }

    componentWillUnmount() {
        this.signal.cancel("Api is being canceled");
    }

    getAllPubUsers = () => {
        this.signal = axios.CancelToken.source();
        axios
            .get(`${process.env.REACT_APP_ADMIN_URL}/users/pub`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            })
            .then((response) => {
                console.log(response.data);
                let data = response.data.reverse().map((v) => {
                    return {
                        label: v.username,
                        value: v._id,
                    };
                });
                this.setState({
                    pubUsersList: data,
                });
            })
            .catch((error) => {
                console.log(error);
                //this.props.history.push("/error?message=" + error.message);
            });
    };
    getAllOffer = async () => {
        this.signal = axios.CancelToken.source();
        axios
            .get(`${process.env.REACT_APP_ADMIN_URL}/offers`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            })
            .then((response) => {
                this.setState({
                    offerList: response.data.reverse().map((v) => {
                        return { label: v.alias, value: v._id };
                    }),
                });
            })
            .catch((error) => {
                let response = error;
                if (error.hasOwnProperty("response") && error.response.hasOwnProperty("data")) {
                    response = error.response.data.message;
                }
                this.setState({ invalidMsg: response }, () => {
                    setTimeout(() => {
                        this.setState({ invalidMsg: "" });
                    }, 1500);
                });
            });
    };
    getOfferTemplate = () => {
        this.signal = axios.CancelToken.source();
        axios
            .get(`${process.env.REACT_APP_ADMIN_URL}/templates`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            })
            .then((response) => {
                let templateList = response.data.reverse().map((v) => {
                    return { label: v.name, value: v._id };
                });
                console.log(templateList);
                this.setState({ templateList });
            })
            .catch((error) => {
                console.log(error);
            });
    };
    handleApplyTemplate = () => {
        let templateId = this.state.template.value;
        let pubId = this.state.pub.value;
        if (!templateId) {
            this.setState({ invalidMsg1: "Please select a template" }, () => {
                setTimeout(() => {
                    this.setState({ invalidMsg1: "" });
                }, 1500);
            });
        } else {
            axios
                .post(
                    `${process.env.REACT_APP_ADMIN_URL}/templates/apply`,
                    {
                        pubId,
                        templateId,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "JWT " + localStorage.getItem("token_admin"),
                        },
                    }
                )
                .then((response) => {
                    if (response.data.message === "Properties are invalid !") {
                        this.setState({ invalidMsg1: "Apply Template Failed!", isLoading: false }, () => {
                            this.getPubOffer();
                            setTimeout(() => {
                                this.setState({ invalidMsg1: "", template: { label: "Select a template...", value: "" } });
                            }, 1500);
                        });
                    } else {
                        this.setState({ validMsg1: "Apply Template Successfully!", isLoading: false }, () => {
                            this.getPubOffer();
                            setTimeout(() => {
                                this.setState({ validMsg1: "", template: { label: "Select a template...", value: "" } });
                            }, 1500);
                        });
                    }
                })
                .catch((error) => {
                    this.setState({ invalidMsg1: "Apply Template Failed!", isLoading: false }, () => {
                        this.getPubOffer();
                        setTimeout(() => {
                            this.setState({ invalidMsg1: "", template: { label: "Select a template...", value: "" } });
                        }, 1500);
                    });
                    //this.props.history.push("/error?message=" + error.message);
                });
        }
    };
    getPubOffer = async () => {
        this.setState({ offer: { value: "", label: "Select an offer..." }, pubOffer: [], isLoading: true });
        try {
            // fetch data from a url endpoint
            this.signal = axios.CancelToken.source();
            let res = await axios.get(`${process.env.REACT_APP_ADMIN_URL}/pub/offers/${this.state.pub.value}`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            });
            let res1 = await axios.get(`${process.env.REACT_APP_ADMIN_URL}/pub/offer/price/${this.state.pub.value}`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            });
            this.setState({
                pubOffer: res.data[0].offers.reverse().map((v) => {
                    return {
                        _id: v._id,
                        name: v.name,
                        alias: v.alias,
                        payoutPub: !v.isCombo ? (
                            moneyFormat(parseFloat(res1.data.filter((value) => value.offerId === v._id)[0].price), 0, 3) + "₫"
                        ) : (
                            <ul className="mb-0 table-ul">
                                {res1.data
                                    .filter((value) => value.offerId === v._id)[0]
                                    .price.map((value, i) => (
                                        <li key={i}>
                                            {value.alias} - {moneyFormat(parseFloat(value.payoutPub), 0, 3) + "₫"}
                                        </li>
                                    ))}
                            </ul>
                        ),
                        edit: (
                            <button
                                className="btn button-devas-medium"
                                onClick={() => {
                                    let newPayout = "";
                                    if (v.isCombo) {
                                        newPayout = [];
                                    }
                                    this.setState({
                                        showModal1: true,
                                        currentPayout: res1.data.filter((value) => value.offerId === v._id)[0],
                                        currentOffer: v,
                                        newPayout,
                                    });
                                }}
                            >
                                Edit
                            </button>
                        ),
                        delete: (
                            <button className="btn button-devas-medium" onClick={() => this.deletePubOffer(v._id)}>
                                Delete
                            </button>
                        ),
                        isCombo: v.isCombo ? "Yes" : "No",
                    };
                }),
                isLoading: false,
            });
        } catch (error) {
            console.log(error);
            let response = "There is an error!";
            if (error.hasOwnProperty("response") && error.response.hasOwnProperty("data")) {
                response = error.response.data.message;
            }
            this.setState({ invalidMsg: response, isLoading: false }, () => {
                setTimeout(() => {
                    this.setState({ invalidMsg: "" });
                }, 1500);
            });
            // appropriately handle the error
        }
    };
    createPubOffer = async () => {
        if (this.state.offer.value === "") {
            this.setState({ invalidMsg: "Please select an offer!" }, () => {
                setTimeout(() => {
                    this.setState({ invalidMsg: "" });
                }, 1500);
            });
        } else {
            try {
                this.signal = axios.CancelToken.source();
                let res = await axios.post(
                    `${process.env.REACT_APP_ADMIN_URL}/pub/offers/${this.state.pub.value}`,
                    {
                        offerID: this.state.offer.value,
                    },
                    {
                        cancelToken: this.signal.token,
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "JWT " + localStorage.getItem("token_admin"),
                        },
                    }
                );
                this.setState({ validMsg: "Add Offer Successfully!", isLoading: false }, () => {
                    this.getPubOffer();
                    setTimeout(() => {
                        this.setState({ validMsg: "" });
                    }, 1500);
                });
            } catch (error) {
                let response = "There is an error!";
                if (error.hasOwnProperty("response") && error.response.hasOwnProperty("data")) {
                    response = error.response.data.message;
                }
                this.setState({ invalidMsg: response, isLoading: false }, () => {
                    setTimeout(() => {
                        this.setState({ invalidMsg: "" });
                    }, 1500);
                });
                // appropriately handle the error
            }
        }
    };
    deletePubOffer = async (id) => {
        try {
            this.signal = axios.CancelToken.source();
            let res = await axios.delete(`${process.env.REACT_APP_ADMIN_URL}/pub/offers/${this.state.pub.value}`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
                data: {
                    offerID: id,
                },
            });
            this.setState({ validMsg: "Delete Offer Successfully!", isLoading: false }, () => {
                this.getPubOffer();
                setTimeout(() => {
                    this.setState({ validMsg: "" });
                }, 1500);
            });
        } catch (error) {
            let response = "There is an error!";
            if (error.hasOwnProperty("response") && error.response.hasOwnProperty("data")) {
                response = error.response.data.message;
            }
            this.setState({ invalidMsg: response, isLoading: false }, () => {
                setTimeout(() => {
                    this.setState({ invalidMsg: "" });
                }, 1500);
            });
            // appropriately handle the error
        }
    };
    editPubPayout = (isCombo) => {
        axios
            .put(
                `${process.env.REACT_APP_ADMIN_URL}/pub/offer/price/${this.state.currentPayout._id}`,
                {
                    isCombo: isCombo,
                    price: this.state.newPayout,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "JWT " + localStorage.getItem("token_admin"),
                    },
                }
            )
            .then((response) => {
                let data = response.data;
                this.handleModal1Close();
                this.getPubOffer();
            })
            .catch((error) => {
                console.log(error);
                alert("Something went wrong! " + error.response.data.message);
                //this.props.history.push("/error?message=" + error.message);
            });
    };
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };
    handleModal1Close = () => {
        this.setState({
            showModal1: false,
            currentPayout: {},
            currentOffer: {},
            newPayout: "",
        });
    };
    handleChangeSelectPub = (e) => {
        this.setState({ pub: e }, () => {
            this.getPubOffer();
        });
    };
    handleChangeSelectOffer = (e) => {
        this.setState({ offer: e });
    };
    handleChangeSelectTemplate = (e) => {
        this.setState({ template: e });
    };
    handleComboPubPayoutChange = (index, value, payoutObj) => {
        payoutObj.payoutPub = value;
        let newPayout = [...this.state.newPayout];
        newPayout[index] = payoutObj;
        this.setState({ newPayout });
    };
    checkNewPayout = () => {
        const isCombo = this.state.currentOffer.isCombo;
        if (isCombo) {
            let newPayoutClone = [...this.state.newPayout];
            let newPayoutFromCurrentPayout = [...this.state.currentPayout.price];
            let check1 = true;
            let check2 = true;
            newPayoutClone.map((item, index) => {
                console.log(item);
                if (!item.payoutPub || item.payoutPub === "") check1 = false;
                else if (!item.payoutPub.match(/^-?\d+\.?\d*$/)) check2 = false;
                newPayoutFromCurrentPayout[index] = item;
            });
            if (!check1) {
                alert("Please fill in all text fields!");
            } else if (!check2) {
                alert("Pub Payout must be a number!");
            } else {
                this.setState({ newPayout: newPayoutFromCurrentPayout }, () => this.editPubPayout(isCombo));
            }
        } else {
            if (this.state.newPayout === "") {
                alert("Please fill in all text fields!");
            } else if (!this.state.newPayout.trim().match(/^-?\d+\.?\d*$/)) {
                alert("Pub Payout must be a number!");
            } else this.editPubPayout(isCombo);
        }
    };

    render() {
        let {
            pub,
            pubUsersList,
            invalidMsg,
            validMsg,
            offerList,
            offer,
            pubOffer,
            invalidMsg1,
            validMsg1,
            template,
            templateList,
        } = this.state;
        return (
            <Main
                history={this.props.history}
                linkTo={["/users", "/users/permissions"]}
                linkName={["Users", "Permissions"]}
                selectedSection={4}
            >
                <div className="col-6 col-lg-4 py-3">
                    <Select
                        name="offerId"
                        options={pubUsersList}
                        className="devas-select"
                        classNamePrefix="devas-select"
                        onChange={this.handleChangeSelectPub}
                        theme={selectTheme}
                        placeholder="Select a publisher..."
                        value={pub}
                    />
                </div>
                {this.state.pub.value !== "" && (
                    <div className="col-12">
                        <div className="py-3 bg-menu mb-1">
                            <div className="px-4 d-flex justify-content-between mb-2">
                                <h2 className="menu-h2-title">User Permissions</h2>
                            </div>
                            <div className="row px-4 pt-3">
                                <div className="col-9 px-1 px-sm-3 col-xl-6 dropdown-form">
                                    <Select
                                        name="offerId"
                                        options={offerList}
                                        className="devas-select"
                                        classNamePrefix="devas-select"
                                        onChange={this.handleChangeSelectOffer}
                                        theme={selectTheme}
                                        placeholder="Select an offer..."
                                        value={offer}
                                    />
                                    <div
                                        className={`text-red mt-2 fade-out-transition ${
                                            invalidMsg !== "" ? "opacity-1" : "opacity-0"
                                        }`}
                                    >
                                        {invalidMsg}
                                    </div>
                                    <div
                                        className={`text-green mt-2 fade-out-transition ${
                                            validMsg !== "" ? "opacity-1" : "opacity-0"
                                        }`}
                                    >
                                        {validMsg}
                                    </div>
                                </div>
                                <div className="col-3 py-1 py-sm-0 px-2 px-sm-0">
                                    <button className="btn button-devas-large" onClick={this.createPubOffer}>
                                        Add
                                    </button>
                                </div>
                            </div>
                            <div className="row px-4 pt-3">
                                <div className="col-9 px-1 px-sm-3 col-xl-6 dropdown-form">
                                    <Select
                                        name="template"
                                        options={templateList}
                                        className="devas-select"
                                        classNamePrefix="devas-select"
                                        onChange={this.handleChangeSelectTemplate}
                                        theme={selectTheme}
                                        placeholder="Select a template..."
                                        value={template}
                                    />
                                    <div
                                        className={`text-red mt-2 fade-out-transition ${
                                            invalidMsg1 !== "" ? "opacity-1" : "opacity-0"
                                        }`}
                                    >
                                        {invalidMsg1}
                                    </div>
                                    <div
                                        className={`text-green mt-2 fade-out-transition ${
                                            validMsg1 !== "" ? "opacity-1" : "opacity-0"
                                        }`}
                                    >
                                        {validMsg1}
                                    </div>
                                </div>
                                <div className="col-3 py-1 py-sm-0 px-2 px-sm-0">
                                    <button className="btn button-devas-large" onClick={this.handleApplyTemplate}>
                                        Apply
                                    </button>
                                </div>
                            </div>
                            <div className="row px-0 pt-3">
                                <div className="col-12">
                                    <DataTable
                                        columns={columns}
                                        data={pubOffer}
                                        pagination
                                        theme="myTheme"
                                        className="table"
                                        customStyles={customStyles}
                                        noHeader={true}
                                        progressPending={this.state.isLoading}
                                        highlightOnHover={true}
                                        paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 500, 1000]}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <Modal
                    show={this.state.showModal1}
                    onHide={this.handleModal1Close}
                    className={`${localStorage.getItem("theme") === "light" ? "light-theme" : "dark-theme"} myModal`}
                    centered
                >
                    <Modal.Header closeButton>
                        <h2 className="menu-h2-title modal-title">Edit Pub Payout</h2>
                    </Modal.Header>
                    <Modal.Body>
                        <div>Offer name: {this.state.currentOffer.alias}</div>
                        {!this.state.currentOffer.isCombo ? (
                            <div>
                                <div className="mt-3">Current Payout: {this.state.currentPayout.price}</div>
                                <div className="mt-3">
                                    <span>Pub Payout:</span>
                                    <input
                                        name="newPayout"
                                        type="text"
                                        value={this.state.newPayout}
                                        className="form-control search-input w-auto d-inline-block"
                                        onChange={this.handleChange}
                                        placeholder="New Pub Payout..."
                                    />
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div className="mt-3">
                                    Current Payout:{" "}
                                    {this.state.currentPayout.price.map((obj, index) => {
                                        return (
                                            <div key={index}>
                                                <div className="mt-3">
                                                    Name: {obj.alias} - {obj.name}
                                                </div>
                                                <div className="mt-3 mb-2">
                                                    <span>Payout Pub: {obj.payoutPub}</span>
                                                    <input
                                                        name="newPubPayoutCombo"
                                                        type="text"
                                                        value={
                                                            this.state.newPayout[index] &&
                                                            this.state.newPayout[index].hasOwnProperty("payoutPub")
                                                                ? this.state.newPayout[index].payoutPub
                                                                : ""
                                                        }
                                                        placeholder="New Pub Payout ..."
                                                        onChange={(event) =>
                                                            this.handleComboPubPayoutChange(index, event.target.value, { ...obj })
                                                        }
                                                        className="form-control search-input w-auto d-inline-block"
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn button-devas-gray" onClick={this.handleModal1Close}>
                            Close
                        </button>
                        <button type="button" className="btn button-devas" onClick={this.checkNewPayout}>
                            Edit
                        </button>
                    </Modal.Footer>
                </Modal>
            </Main>
        );
    }
}
