import React, { Component } from "react";
import Select from "react-select";
import axios from "axios";
import moment from "moment";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import Modal from "react-bootstrap/Modal";
import DataTable from "react-data-table-component";
import GetAppIcon from "@material-ui/icons/GetApp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

import { ExportExcel } from "../../helper/ExportDataToExcel";

import { selectTheme } from "../../style/reactselect";
import "../../style/dropdown.css";
import "../../style/daterangepicker.css";
import "../../style/modal.css";
import { customStyles } from "../../style/table";
import AdvInvoicesStatistics from "./AdvInvoicesStatistics";
export default class AdvInvoice extends Component {
    constructor(props) {
        super(props);

        this.state = {
            advInvoiceList: [],
            advInvoices: [],
            offer: { value: "", label: "Select..." },
            offerList: [],
            date: null,
            filterDate: [moment(new Date("2020-05-15T00:00:00")).startOf("date").unix() * 1000, new Date()],
            newAdvConversions: [],
            message: "",
            offerInvoice: { value: "", label: "Select Offer..." },
            showModal1: false,
            showModal2: false,
            selectedModalInvoiceId: "",
            isLoading: true,
            editStatus: { value: true, label: "true" },
            real_revenue: "",
            invoice_log: "",
            totalInvoices: 0,
            totalEstimateRevenue: 0,
            totalRealRevenue: 0,
            totalHome: 0,
            dateType: { label: "Created Date", value: "created_date" },
            displayOfferListCreate: false,
        };
    }
    componentDidMount() {
        document.title = "Adv Invoices";
        // this.getOfferList();
        this.getAdvInvoiceList();
    }
    componentWillUnmount() {
        this.signal.cancel("Api is being canceled");
    }
    getAdvInvoiceList = () => {
        this.setState({ offerInvoice: { value: "", label: "Select Offer..." } });
        this.signal = axios.CancelToken.source();
        let from_date = moment(this.state.filterDate[0]).startOf("date").unix() * 1000;
        let to_date = moment(this.state.filterDate[1]).endOf("date").unix() * 1000 + 999;
        axios
            .get(
                `${process.env.REACT_APP_ADMIN_URL}/adv/invoices/?from_date=${from_date}&to_date=${to_date}&modified_date=${this.state.dateType.value}`,
                {
                    cancelToken: this.signal.token,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "JWT " + localStorage.getItem("token_admin"),
                    },
                }
            )
            .then((response) => {
                var data = response.data.reverse().map((v) => {
                    if (!v.is_home) {
                        v.edit = (
                            <button
                                className="btn button-devas-medium"
                                onClick={() =>
                                    this.setState({
                                        selectedModalInvoiceId: v._id,
                                        showModal2: true,
                                        real_revenue: "",
                                        invoice_log: "",
                                    })
                                }
                            >
                                Edit
                            </button>
                        );
                    }
                    v.is_home = v.is_home ? "true" : "false";
                    v.download = (
                        <button className="btn button-devas-medium" onClick={() => this.exportData(v._id, v.name)}>
                            <GetAppIcon />
                        </button>
                    );
                    v.created_date = new Date(v.created_date).toLocaleString();
                    return v;
                });
                this.calculateSummary(response.data);
                this.setState({ advInvoiceList: data, advInvoices: data, isLoading: false });
            })
            .catch((error) => {
                console.log(error);
            });
    };
    calculateSummary = (data) => {
        let totalInvoices = 0;
        let totalEstimateRevenue = 0;
        let totalRealRevenue = 0;
        let totalHome = 0;
        data.forEach((e) => {
            totalInvoices++;
            totalEstimateRevenue += e.revenue ? e.revenue : 0;
            totalRealRevenue += e.real_revenue ? e.real_revenue : 0;
            if (e.is_home === "true") {
                totalHome++;
            }
        });
        this.setState({ totalInvoices, totalRealRevenue, totalEstimateRevenue, totalHome });
    };
    filterAdvInvoice = () => {
        this.setState((state, props) => {
            return { advInvoices: state.advInvoiceList.filter((v) => v.offer.includes(state.offerInvoice.value)) };
        });
    };
    // getOfferList = () => {
    //     this.signal = axios.CancelToken.source();
    //     axios
    //         .get(`${process.env.REACT_APP_ADMIN_URL}/offers`, {
    //             cancelToken: this.signal.token,
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 Authorization: "JWT " + localStorage.getItem("token_admin"),
    //             },
    //         })
    //         .then((response) => {
    //             var offerList = response.data.reverse().map((v) => {
    //                 return { value: v._id, label: v.alias };
    //             });
    //             this.setState({ offerList });
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // };
    handleGenerateAdvInvoiceDetails = (e) => {
        e.preventDefault();
        this.signal = axios.CancelToken.source();
        if (this.state.offer.value === "") {
            return alert("Please select an offer!");
        }
        let from_date = moment(this.state.date[0]).startOf("date").unix() * 1000;
        let to_date = moment(this.state.date[1]).endOf("date").unix() * 1000 + 999;
        axios
            .get(
                `${process.env.REACT_APP_ADMIN_URL}/conversions/approved/offer/${this.state.offer.value}?from_date=${from_date}&to_date=${to_date}`,
                {
                    cancelToken: this.signal.token,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "JWT " + localStorage.getItem("token_admin"),
                    },
                }
            )
            .then((response) => {
                let newAdvConversions = response.data;
                if (newAdvConversions.length == 0) {
                    return alert("No approved conversion found!");
                }
                let message = `Offer ${this.state.offer.label} have ${newAdvConversions.length} conversions in current date range. Do you want to create this invoice?`;
                this.setState({ newAdvConversions, message, showModal1: true });
            })
            .catch((error) => {
                alert("Something went wrong! " + error.response.data.message);
            });
    };
    createAdvInvoice = () => {
        axios
            .post(`${process.env.REACT_APP_ADMIN_URL}/adv/invoices`, this.state.newAdvConversions, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            })
            .then((response) => {
                let data = response.data;
                this.getAdvInvoiceList();
                this.setState({ showModal1: false }, () =>
                    setTimeout(() => {
                        alert(`Invoice created successfully! 1 Adv Invoice and ${data.pubInvoices.length} Pub Invoices`);
                    }, 150)
                );
            })
            .catch((error) => {
                alert("Something went wrong! " + error.response.data.message);
            });
    };
    EditAdvInvoice = () => {
        axios
            .put(
                `${process.env.REACT_APP_ADMIN_URL}/adv/invoices/${this.state.selectedModalInvoiceId}`,
                {
                    is_home: this.state.editStatus.value,
                    real_revenue: this.state.real_revenue.trim(),
                    invoice_log: this.state.invoice_log,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "JWT " + localStorage.getItem("token_admin"),
                    },
                }
            )
            .then((response) => {
                let data = response.data;
                this.getAdvInvoiceList();
                this.setState({ showModal2: false }, () =>
                    setTimeout(() => {
                        alert(`Edit Invoice Successfully!`);
                    }, 150)
                );
            })
            .catch((error) => {
                alert("Something went wrong! " + error.response.data.message);
            });
    };
    exportData = (invoiceId, fileName) => {
        this.signal = axios.CancelToken.source();
        axios
            .get(`${process.env.REACT_APP_ADMIN_URL}/adv/invoices/${invoiceId}`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            })
            .then((response) => {
                ExportExcel(response.data.conversions, fileName);
            })
            .catch((error) => {
                alert("Something went wrong!");
            });
    };
    getOfferListCreateInvoice = () => {
        this.signal = axios.CancelToken.source();
        if(!this.state.date) 
            return;
        let from_date = moment(this.state.date[0]).startOf("date").unix() * 1000;
        let to_date = moment(this.state.date[1]).endOf("date").unix() * 1000 + 999;
        axios
            .get(`${process.env.REACT_APP_ADMIN_URL}/conversions/approved/offer-list?from_date=${from_date}&to_date=${to_date}`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            })
            .then((response) => {
                let data = response.data;
                data = data.map(v => {
                    return {label: `${v.alias} (${v.count} conversions)`, value: v._id} 
                })
                this.setState({offerList: data, displayOfferListCreate: true})
            })
            .catch((error) => {
                alert("Something went wrong!");
            });
    }
    onChangeDate = (date) => this.setState({ date, displayOfferListCreate: false, offerList: [], offer: {label: "Select offer...", value: ""} }, () => this.getOfferListCreateInvoice());
    onChangeOffer = (offer) => this.setState({ offer });
    onChangeOfferInvoice = (offerInvoice) => this.setState({ offerInvoice }, () => this.filterAdvInvoice());
    handleModal1Close = () => {
        this.setState({ showModal1: false });
    };
    handleModal2Close = () => {
        this.setState({ showModal2: false });
    };
    onChangeEditStatus = (editStatus) => {
        this.setState({ editStatus });
    };
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };
    handleChangeDateType = (dateType) => {
        this.setState({ dateType }, () => this.getAdvInvoiceList());
    };
    handleChangeDate = (filterDate) => {
        if (filterDate == null) {
            filterDate = [new Date(), new Date()];
        }
        this.setState({ filterDate }, () => this.getAdvInvoiceList());
    };
    render() {
        let {
            offer,
            offerList,
            date,
            message,
            showModal1,
            showModal2,
            offerInvoice,
            isLoading,
            advInvoices,
            editStatus,
            invoice_log,
            real_revenue,
            dateType,
            filterDate,
            displayOfferListCreate,
        } = this.state;
        return (
            <>
                <div className="col-12">
                    <div id="accordion">
                        <div className="card bg-menu">
                            <div className="card-header p-2" id="headingOne">
                                <h5 className="mb-0">
                                    <button
                                        className="btn btn-link collapse-link"
                                        data-toggle="collapse"
                                        data-target="#collapseOne"
                                        aria-expanded="true"
                                        aria-controls="collapseOne"
                                    >
                                        Create Adv Invoice <FontAwesomeIcon icon={faCaretDown} />
                                    </button>
                                </h5>
                            </div>

                            <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                <div className="card-body">
                                    <div className="px-2 row mt-2">
                                        <div className="col-12 col-md-9 col-xl-6">
                                            <form onSubmit={this.handleGenerateAdvInvoiceDetails} className="normal-form">
                                                <div className="form-group">
                                                    <div className="mb-2 daterange-form">
                                                        <label htmlFor="url">Date Range</label>
                                                        <div className="d-flex align-items-center bg-daterangepicker">
                                                            <DateRangePicker onChange={this.onChangeDate} value={date} />
                                                        </div>
                                                    </div>
                                                    {displayOfferListCreate && (
                                                        <div className="mb-2 dropdown-form">
                                                            <label htmlFor="url">Offer Alias</label>
                                                            <Select
                                                                defaultValue="one"
                                                                isMulti={false}
                                                                name="offerId"
                                                                options={offerList}
                                                                className="devas-select"
                                                                classNamePrefix="devas-select"
                                                                onChange={this.onChangeOffer}
                                                                theme={selectTheme}
                                                                value={offer}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="form-group">
                                                    {displayOfferListCreate && (
                                                        <div className="d-flex align-items-center justify-content-center mt-4">
                                                            <button type="submit" className="btn button-devas-large">
                                                                Details
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-3">
                    <div className="bg-menu py-3 mb-1">
                        <div className="px-4 d-flex justify-content-between align-items-center pb-3">
                            <h2 className="menu-h2-title mb-0">Adv Invoices</h2>
                            <div className="dropdown-form w-25">
                                <Select
                                    defaultValue="one"
                                    isMulti={false}
                                    name="offerId"
                                    options={[...offerList, { value: "", label: "Select Offer..." }].reverse()}
                                    className="devas-select"
                                    classNamePrefix="devas-select"
                                    onChange={this.onChangeOfferInvoice}
                                    theme={selectTheme}
                                    value={offerInvoice}
                                />
                            </div>
                        </div>
                        <div className="row px-4">
                            <div className="col-12 col-md-6 pb-3">
                                <div className="dropdown-form">
                                    <Select
                                        isMulti={false}
                                        options={[
                                            { label: "Created Date", value: "false" },
                                            { label: "Modified Date", value: "true" },
                                        ]}
                                        className="devas-select"
                                        classNamePrefix="devas-select"
                                        onChange={this.handleChangeDateType}
                                        theme={selectTheme}
                                        value={dateType}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-6 pb-3">
                                <div className="d-flex align-items-center bg-daterangepicker border-grey">
                                    <DateRangePicker onChange={this.handleChangeDate} value={filterDate} />
                                </div>
                            </div>
                        </div>
                        <AdvInvoicesStatistics
                            key={new Date()}
                            totalInvoices={this.state.totalInvoices}
                            totalEstimateRevenue={this.state.totalEstimateRevenue}
                            totalRealRevenue={this.state.totalRealRevenue}
                            totalHome={this.state.totalHome}
                        />
                        <div className="px-4 d-flex justify-content-between align-items-center">
                            <DataTable
                                columns={columns}
                                data={advInvoices}
                                pagination
                                theme="myTheme"
                                className="table"
                                customStyles={customStyles}
                                noHeader={true}
                                progressPending={isLoading}
                                highlightOnHover={true}
                                defaultSortField="order"
                                defaultSortAsc={false}
                                paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 500, 1000]}
                            />
                        </div>
                    </div>
                </div>
                <Modal
                    show={showModal1}
                    onHide={this.handleModal1Close}
                    className={`${localStorage.getItem("theme") === "light" ? "light-theme" : "dark-theme"} myModal`}
                    centered
                >
                    <Modal.Header closeButton>
                        <h2 className="menu-h2-title modal-title">Create Adv Invoice</h2>
                    </Modal.Header>
                    <Modal.Body>
                        <div>{message}</div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn button-devas-gray" onClick={this.handleModal1Close}>
                            Close
                        </button>
                        <button type="button" className="btn button-devas" onClick={this.createAdvInvoice}>
                            Create
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={showModal2}
                    onHide={this.handleModal2Close}
                    className={`${localStorage.getItem("theme") === "light" ? "light-theme" : "dark-theme"} myModal`}
                    centered
                >
                    <Modal.Header closeButton>
                        <h2 className="menu-h2-title modal-title">Edit Adv Invoice</h2>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="dropdown-form">
                            <span className="mr-1">Is Home:</span>
                            <Select
                                isMulti={false}
                                name="offerId"
                                options={[
                                    { value: true, label: "true" },
                                    { value: false, label: "false" },
                                ]}
                                className="devas-select d-inline-block w-25"
                                classNamePrefix="devas-select"
                                onChange={this.onChangeEditStatus}
                                theme={selectTheme}
                                value={editStatus}
                            />
                        </div>
                        <div className="mt-2">
                            <span>Real Revenue:</span>
                            <input
                                name="real_revenue"
                                type="text"
                                value={real_revenue}
                                className="form-control search-input w-auto d-inline-block"
                                onChange={this.handleChange}
                                placeholder="Revenue in VND"
                            />
                        </div>
                        <div className="mt-2">
                            <span>Invoice Log:</span>
                            <input
                                name="invoice_log"
                                type="text"
                                value={invoice_log}
                                className="form-control search-input w-auto d-inline-block"
                                onChange={this.handleChange}
                                placeholder="Input Text Here"
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn button-devas-gray" onClick={this.handleModal2Close}>
                            Close
                        </button>
                        <button type="button" className="btn button-devas" onClick={this.EditAdvInvoice}>
                            Edit
                        </button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

const columns = [
    {
        name: "",
        selector: "edit",
        wrap: true,
        minWidth: "110px",
    },
    {
        name: "Id",
        selector: "_id",
        wrap: true,
        sortable: true,
    },
    {
        name: "Order",
        selector: "order_id",
        sortable: true,
        wrap: true,
    },
    {
        name: "Name",
        selector: "name",
        wrap: true,
    },
    {
        name: "Is Home",
        selector: "is_home",
        sortable: true,
    },
    {
        name: "Conversion Count",
        selector: "conversion_count",
        sortable: true,
    },
    {
        name: "Adv ID",
        selector: "adv.username",
        wrap: true,
    },
    {
        name: "Created Date",
        selector: "created_date",
        wrap: true,
    },
    {
        name: "Estimate Revenue",
        selector: "revenue",
        sortable: true,
        wrap: true,
    },
    {
        name: "Real Revenue",
        selector: "real_revenue",
        sortable: true,
        wrap: true,
    },
    {
        name: "Invoice Log",
        selector: "invoice_log",
        sortable: true,
        wrap: true,
    },
    {
        name: "Download",
        selector: "download",
        wrap: true,
    },
];
