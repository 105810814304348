import React, {Component} from "react";

import Select from "react-select";
import axios from "axios";
import {Editor} from "@tinymce/tinymce-react/lib/cjs/main/ts";
import Modal from "react-bootstrap/Modal";
import ReactHtmlParser from 'react-html-parser';


import {selectTheme} from "../../style/reactselect";

import MainAdmin from "./MainAdmin";

import "../../style/tiny_editor.css";

export default class BannerTool extends Component {
    state = {
        tool: {label: "Send banner for all Publishers", value: "banner_for_all"},
        pubList: [],
        superPubList: [],
        publishers: "",
        superPub: "",
        news_banner: "",
        showDemo: false,
        managePub: "",
        allPub: [],
        pubHasBannerList: [],
    }
    toolOptions = [
        {label: "Send banner for all Publishers", value: "banner_for_all"},
        {label: "Send banner for selected Publishers", value: "banner_for_selected_pub"},
        {label: "Send banner for Super Pub's children", value: "banner_super_pub_children"},
        {label: "Manage existing banner for pub", value: "manage_existing_banner_pub"},
    ]

    componentDidMount() {
        this.getPubUsers();
        this.getSuperPub();
    }

    toolSelectOnChange = (tool) => {
        this.setState({tool});
    };
    getPubUsers = () => {
        this.signal = axios.CancelToken.source();
        axios
            .get(`${process.env.REACT_APP_ADMIN_URL}/users/pub`,
                {
                    cancelToken: this.signal.token,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "JWT " + localStorage.getItem("token_admin"),
                    },
                })
            .then((res) => {
                let pubList = res.data.reverse().map((pub) => {
                    return {label: pub.username, value: pub._id}
                });
                let pubHasBannerList = res.data.filter((pub) => {
                    return pub.news_banner
                }).map((pub) => {
                    return {label: pub.username, value: pub._id}
                });
                this.setState({pubList, allPub: res.data, pubHasBannerList});
            })
            .catch((err) => {
                console.log(err);
            })
    }

    getSuperPub = () => {
        this.signal = axios.CancelToken.source();
        axios
            .get(`${process.env.REACT_APP_ADMIN_URL}/users/super_pub`,
                {
                    cancelToken: this.signal.token,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "JWT " + localStorage.getItem("token_admin"),
                    },
                })
            .then((res) => {
                let superPubList = res.data.reverse().map((pub) => {
                    return {label: pub.username, value: pub._id}
                });
                this.setState({superPubList, allSuperPub: res.data});
            })
            .catch((err) => {
                console.log(err);
            })
    }
    handleEditorChange = (news_banner, editor) => {
        this.setState({news_banner});
    }
    pubSelectOnChange = (publishers) => {
        this.setState({publishers});
    };
    superPubSelectOnChange = (superPub) => {
        this.setState({superPub});
    }
    saveOnClick = () => {
        switch (this.state.tool.value) {
            case "banner_for_all":
                this.sendBannerToAllPub();
                break;
            case "banner_for_selected_pub":
                this.sendBannerToSelectedPub();
                break;
            case "banner_super_pub_children":
                this.sendBannerToSuperPub();
                break;
            case "manage_existing_banner_pub":
                this.updatePubBanner();
                break;
            default:
                break;
        }
    }
    updatePubBanner = () => {
        if (Object.keys(this.state.managePub).length === 0) {
            alert("You must select a publisher");
            return false;
        }
        let publishers = [];
        publishers.push(this.state.managePub.value);
        axios
            .patch(`${process.env.REACT_APP_ADMIN_URL}/users/news_banner`, {
                publishers,
                news_banner: this.state.news_banner
            }, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            })
            .then((res) => {
                this.getPubUsers();
                alert("Update banner successfully");
            })
            .catch((err) => {
                alert("Failed");
            })
    }
    sendBannerToAllPub = () => {
        if (!this.state.news_banner) {
            alert("You must enter some content");
            return false;
        }
        axios
            .put(`${process.env.REACT_APP_ADMIN_URL}/users/pub/news_banner`, {
                news_banner: this.state.news_banner
            }, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            })
            .then((res) => {
                this.getPubUsers();
                alert("Set banner for all publishers successfully");
            })
            .catch((err) => {
                alert("Failed!");
            })
    }
    sendBannerToSelectedPub = () => {
        if (!this.state.news_banner) {
            alert("You must enter some content");
            return false;
        }
        if (!this.state.publishers || this.state.publishers.length === 0) {
            alert("Please select at least 1 publisher");
            return false;
        }
        let publishers = this.state.publishers.map((pub) => {
            return pub.value;
        });
        axios
            .patch(`${process.env.REACT_APP_ADMIN_URL}/users/news_banner`, {
                publishers,
                news_banner: this.state.news_banner
            }, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            })
            .then((res) => {
                this.getPubUsers();
                alert("Ser banner for selected publishers successfully");
            })
            .catch((err) => {
                alert("Failed");
            })
    }
    sendBannerToSuperPub = () => {
        if (!this.state.news_banner) {
            alert("You must enter some content");
            return false;
        }
        if (!this.state.superPub || Object.keys(this.state.superPub).length === 0) {
            alert("Please select a super pub");
            return false;
        }
        axios
            .put(`${process.env.REACT_APP_ADMIN_URL}/users/super-pub/children/news_banner`, {
                super_pub_id: this.state.superPub.value,
                news_banner: this.state.news_banner
            }, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            })
            .then((res) => {
                this.getPubUsers();
                alert("Set banner for children of super pub successfully");
            })
            .catch((err) => {
                alert("Failed");
            })
    }
    handleManagePubOnChange = (managePub) => {
        const pubList = [...this.state.allPub];
        const pub = pubList.filter((pub) => {
            return pub._id === managePub.value && pub.news_banner
        })[0];
        this.setState({managePub, news_banner: pub.news_banner});
    }

    render() {
        let pubSelectField = (
            <Select
                isMulti={true}
                options={this.state.pubList}
                placeholder="Select Pubs"
                className="devas-select"
                classNamePrefix="devas-select"
                theme={selectTheme}
                value={this.state.publishers}
                onChange={this.pubSelectOnChange}
            />
        );
        let superPubSelectField = (
            <Select
                isMulti={true}
                options={this.state.superPubList}
                placeholder="Select Super Pub"
                className="devas-select"
                classNamePrefix="devas-select"
                theme={selectTheme}
                value={this.state.superPub}
                onChange={this.superPubSelectOnChange}
            />
        );
        let managePubSelectField = (
            <Select
                isMulti={false}
                options={this.state.pubHasBannerList}
                placeholder="Select Pub"
                className="devas-select"
                classNamePrefix="devas-select"
                theme={selectTheme}
                value={this.state.managePub}
                onChange={this.handleManagePubOnChange}
            />
        );
        return (
            <MainAdmin history={this.props.history} linkTo={["/banner_tool"]} linkName={["Banner Tool"]}
                       selectedSection={8}>
                <div className="col-5">

                </div>
                <div className="col-6 col-md-3 py-3">
                    <Select
                        isMulti={false}
                        options={this.toolOptions}
                        placeholder="Select Tool"
                        className="devas-select"
                        classNamePrefix="devas-select"
                        theme={selectTheme}
                        value={this.state.tool}
                        onChange={this.toolSelectOnChange}
                    />
                </div>

                <div className="col-12">
                    <div className="col-6 col-md-3 py-3 pl-0">
                        {this.state.tool.value === "banner_for_selected_pub" ? pubSelectField : this.state.tool.value === "banner_super_pub_children" ? superPubSelectField : this.state.tool.value === "manage_existing_banner_pub" ? managePubSelectField : ""}
                    </div>
                    <div className="bg-menu">
                        <div className="row">
                            <div className="col-12">
                                <Editor
                                    apiKey='gzdtc587ie2l7j5am07pxmi0scby88vncsmex0vkx5u6fbiw'
                                    init={{
                                        selector: 'textarea#full-featured-non-premium',
                                        menubar: 'image file edit view insert format tools table help',
                                        plugins: [
                                            'advlist autolink lists link image charmap print preview anchor',
                                            'searchreplace visualblocks code fullscreen',
                                            'insertdatetime media table paste code help wordcount'
                                        ],
                                        toolbar: 'undo redo | formatselect | ' +
                                            'bold italic backcolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat | help',
                                        height: 600,
                                        noneditable_noneditable_class: 'mceNonEditable',
                                        toolbar_mode: 'sliding',
                                    }}
                                    onEditorChange={this.handleEditorChange}
                                    value={this.state.news_banner}
                                />
                            </div>

                        </div>
                    </div>
                    <div className="col-12 text-center mt-3">
                        <button type="button" className="btn button-devas-gray-large mx-2" onClick={() => {
                            this.setState({showDemo: true})
                        }}>
                            Show Demo
                        </button>
                        <button type="button" className="btn button-devas-large mx-2" onClick={this.saveOnClick}>
                            Save
                        </button>
                    </div>
                </div>
                <Modal
                    size="lg"
                    show={this.state.showDemo}
                    centered
                >
                    <Modal.Header>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            Publisher will see the content below
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {ReactHtmlParser(this.state.news_banner)}
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn button-devas" onClick={() => {
                            this.setState({showDemo: false})
                        }}>
                            Understood
                        </button>
                    </Modal.Footer>
                </Modal>
            </MainAdmin>
        )
    }
}