import React, { Component } from "react";
import Main from "./adminbase/MainAdmin";
import DataTable from "react-data-table-component";
import axios from "axios";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";

import redirectNoToken from "../helper/redirectNoToken";
import { customStyles } from "../style/table";
import { moneyFormat } from "../helper/helper";
import { selectTheme } from "../style/reactselect";
import "../style/modal.css";
import "../style/dropdown.css";

const columns = [
    {
        name: "Order",
        selector: "order_id",
        sortable: true,
        wrap: true,
        center: true,
        maxWidth: "100px",
    },
    {
        name: "Template Name",
        selector: "name",
        sortable: true,
        wrap: true,
        maxWidth: "500px",
    },
    {
        name: "Details",
        selector: "details",
        sortable: true,
        wrap: true,
        minWidth: "150px",
    },
    {
        name: "Action",
        selector: "apply",
        minWidth: "130px",
    },
];

export default class OfferTemplateAdmin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            offerTemplates: [],
            currentTemplate: {},
            showModal1: false,
            publisher: [],
            currentPublisher: "",
        };
    }
    componentDidMount() {
        this.getOfferTemplate();
        this.getPublisher();
    }
    componentWillUnmount() {
        this.signal.cancel("Api is being canceled");
    }
    getPublisher = () => {
        this.signal = axios.CancelToken.source();
        axios
            .get(`${process.env.REACT_APP_ADMIN_URL}/users/pub`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            })
            .then((response) => {
                let publisher = response.data.reverse().map((v) => {
                    return { value: v._id, label: v.username };
                });
                this.setState({ publisher });
            })
            .catch((error) => {
                console.log(error);
            });
    };
    getOfferTemplate = () => {
        this.signal = axios.CancelToken.source();
        axios
            .get(`${process.env.REACT_APP_ADMIN_URL}/templates`, {
                cancelToken: this.signal.token,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "JWT " + localStorage.getItem("token_admin"),
                },
            })
            .then((response) => {
                let offerTemplates = response.data.reverse().map((v, i) => {
                    return {
                        order_id: i + 1,
                        name: (
                            <Link to={`/offers/templates/${v._id}/edit`} className="offerlist-link">
                                {v.name}
                            </Link>
                        ),
                        details: (
                            <ul className="mb-0 table-ul">
                                {v.pubPayout.map((offer, i) => {
                                    return (
                                        <li key={i}>
                                            {offer.offer.alias} - {moneyFormat(parseFloat(offer.price), 0, 3)}₫
                                        </li>
                                    );
                                })}
                            </ul>
                        ),
                        apply: (
                            <button
                                className="btn button-devas"
                                onClick={() => this.setState({ showModal1: true, currentTemplate: v })}
                            >
                                Apply Template
                            </button>
                        ),
                    };
                });
                this.setState({ offerTemplates });
            })
            .catch((error) => {
                console.log(error);
            });
    };
    handleApplyTemplate = () => {
        if (this.state.currentPublisher === "") {
            alert("Please select a publisher!");
        } else {
            let templateId = this.state.currentTemplate._id;
            let pubId = this.state.currentPublisher.value;
            axios
                .post(
                    `${process.env.REACT_APP_ADMIN_URL}/templates/apply`,
                    {
                        pubId,
                        templateId,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "JWT " + localStorage.getItem("token_admin"),
                        },
                    }
                )
                .then((response) => {
                    alert("Apply successfully!");
                    this.handleModal1Close();
                })
                .catch((error) => {
                    alert("Something wrong happened!");
                    //this.props.history.push("/error?message=" + error.message);
                });
        }
    };
    handleModal1Close = () => {
        this.setState({
            showModal1: false,
        });
    };
    handleModal1Open = () => {
        this.setState({ showModal1: true });
    };
    handleChangePublisher = (e) => {
        this.setState({ currentPublisher: e });
    };
    render() {
        return (
            <Main
                history={this.props.history}
                linkTo={["/offers", "/offers/templates"]}
                linkName={["Offers", "Templates"]}
                selectedSection={2}
            >
                <div className="col-12">
                    <div className="py-3 bg-menu mb-1">
                        <div className="px-4 d-flex justify-content-between">
                            <h2 className="menu-h2-title">Offer Templates</h2>
                            <button
                                className="btn button-devas ml-1"
                                onClick={() => this.props.history.push("/offers/templates/new")}
                            >
                                Create Template
                            </button>
                        </div>
                        <div className="mt-3">
                            <DataTable
                                columns={columns}
                                data={this.state.offerTemplates}
                                pagination
                                theme="myTheme"
                                className="table"
                                customStyles={customStyles}
                                noHeader={true}
                                progressPending={this.state.isLoading}
                                highlightOnHover={true}
                                defaultSortField="order"
                                paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 500, 1000]}
                            />
                        </div>
                    </div>
                </div>
                <Modal
                    show={this.state.showModal1}
                    onHide={this.handleModal1Close}
                    className={`${localStorage.getItem("theme") === "light" ? "light-theme" : "dark-theme"} myModal`}
                    centered
                >
                    <Modal.Header closeButton>
                        <h2 className="menu-h2-title modal-title">Apply Template</h2>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mt3">Template name: {this.state.currentTemplate.name}</div>
                        <div className="mt-3 dropdown-form">
                            <span className="mr-1">Publisher:</span>
                            <Select
                                name="offerId"
                                options={this.state.publisher}
                                className="devas-select mt-2"
                                classNamePrefix="devas-select"
                                onChange={this.handleChangePublisher}
                                theme={selectTheme}
                                placeholder="Select a publisher..."
                                value={this.state.currentPublisher}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn button-devas-gray" onClick={this.handleModal1Close}>
                            Close
                        </button>
                        <button type="button" className="btn button-devas" onClick={this.handleApplyTemplate}>
                            Apply
                        </button>
                    </Modal.Footer>
                </Modal>
            </Main>
        );
    }
}
