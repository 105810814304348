import React, {Component} from "react";
import {moneyFormat} from "../../helper/helper";
import "../../style/daterangepicker.css";
import "../../style/statistics.css";

export default class InvoicesStatistics extends Component {
    render() {
        return (
            <div className="SummaryStatistics">
                <div className="row px-4 pb-3">
                    <div className="col-12 col-md-4 px-3 py-3">
                        <div className="py-2 bg-menu">
                            <div className="row">
                                <div className="col-6">
                                    <p className="text-center font-weight-bold">Total Invoices</p>
                                    <p className="text-center text-blue font-weight-bold">{this.props.totalInvoices}</p>
                                </div>
                                <div className="col-6">
                                    <p className="text-center font-weight-bold">Paid Invoices</p>
                                    <p className="text-center text-blue font-weight-bold">{this.props.totalPaid}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 px-3 py-3">
                        <div className="py-2 bg-menu">
                            <p className="text-center font-weight-bold">Total Estimate Payout</p>
                            <p className="text-center text-blue font-weight-bold">{moneyFormat(parseFloat(this.props.totalEstimatePayout), 0, 3) + "₫"}</p>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 px-3 py-3">
                        <div className="py-2 bg-menu">
                            <p className="text-center font-weight-bold">Total Real Payout</p>
                            <p className="text-center text-blue font-weight-bold">{moneyFormat(parseFloat(this.props.totalRealPayout), 0, 3) + "₫"}</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}